<template>
  <div class="agenda appnavbar-body">
    <div class="note-container">
      <div class="card mb-2">
        <div class="topico" :class="!!stateLocal.showEvento ? 'open' : ''">
          <header
            class="card-header py-2 m-0 titulo"
            @click="stateLocal.showEvento = !stateLocal.showEvento"
          >
            <span class="mb-0 font-weight-bold">{{ tm("msgAgenda02") }}</span>
            <i class="fas fa-chevron-circle-down"></i>
          </header>
          <div class="conteudo">
            <div class="card-body p-1 card-body-agenda" style="overflow-x: hidden">
              <div class="row">
                <div class="col-12 form-group mb-1">
                  <label class="mb-1">{{ tm("titulo") }} *</label>
                  <input
                    type="text"
                    id="title"
                    class="form-control p-1"
                    v-model="stateLocal.title"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group mb-1">
                  <label class="mb-1">{{ tm("agenda") }} *</label>
                  <select
                    id="agendaId"
                    :disabled="stateLocal.id"
                    class="custom-select font-size-sm shadow-none p-1"
                    v-model="agendaId"
                  >
                    <option :value="0">{{ tm("todas") }}</option>
                    <option
                      v-for="selAgenda in agendaList"
                      :key="selAgenda.id"
                      :value="selAgenda.id"
                    >
                      {{ selAgenda.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group mb-1">
                  <label class="mb-1">{{ tm("servico") }} *</label>
                  <select
                    id="serviceId"
                    class="custom-select font-size-sm shadow-none p-1"
                    v-model="serviceId"
                  >
                    <option :value="0">{{ tm("todos") }}</option>
                    <option
                      v-for="selAgenda in servicoList"
                      :key="selAgenda.id"
                      :value="selAgenda.id"
                    >
                      {{ selAgenda.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group mb-0">
                  <label class="mb-1">{{ tm("data") }} *</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7 col-12 mb-2 mr-0">
                  <div class="d-flex">
                    <datepicker
                      inputFormat="dd/MM/yyyy"
                      :locale="langData"
                      id="dataInicio"
                      class="form-control pl-2 pr-0 mr-0"
                      v-model="dataInicio"
                    />
                    <span class="input-group-text transparent-bg border-left-0">
                      <!-- Default :: Inline SVG -->
                      <svg
                        class="hw-20 text-muted"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="text-center col-md-5 col-12 form-group mb-2 pl-0 mr-0">
                  <label class="mb-2"></label>
                  <span
                    v-if="!stateLocal.horaIni"
                    class="badge badge-rounded badge-primary pl-1 pr-1 pt-2 pb-2 mr-0 ml-0"
                    >{{ tm("msgAgenda03") }}</span
                  >
                  <span
                    v-if="stateLocal.horaIni"
                    class="badge badge-rounded badge-primary pl-2 pr-2 pt-2 pb-2"
                    :title="stateLocal.nomeAgenda + ' - ' + stateLocal.nomeServico"
                    >{{ stateLocal.horaIni }}</span
                  >
                  <a
                    v-if="stateLocal.horaIni"
                    class="py-0 px-1"
                    @click="clearHorario"
                    href="#"
                    :title="tm('msgAgenda07')"
                    ><i class="fa fa-times fa-lg"></i
                  ></a>
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group mb-1">
                  <div class="card mb-2">
                    <header class="card-header py-2 m-0 titulo">
                      <span class="mb-0 font-weight-bold"
                        ><i class="far fa-clock mr-2"></i
                        >{{ tm("horariosDisponiveis") }}</span
                      >
                    </header>
                    <div class="conteudo">
                      <div
                        class="card-body p-1"
                        style="
                          overflow-y: auto;
                          overflow-x: hidden;
                          width: 100%;
                          height: 15vh;
                        "
                      >
                        <div class="row">
                          <div class="col-12 form-group mb-1">
                            <div
                              class="text-center text-primary my-2"
                              v-if="stateLocal.isLoadingHorarios"
                            >
                              <div class="spinner-border spinner-border-sm mt-3"></div>
                              <br />
                              <strong>{{ tm("carregando") }}...</strong>
                            </div>
                            <div
                              class="text-center text-primary my-2"
                              v-if="
                                !stateLocal.isLoadingHorarios &&
                                horariosDisponiveis.length == 0 &&
                                (agendaId != 0 || serviceId != 0)
                              "
                            >
                              <strong>{{ tm("semHorariosDisponiveis") }}</strong>
                            </div>
                            <div
                              class="text-center text-primary my-2"
                              v-if="
                                !stateLocal.isLoadingHorarios &&
                                agendaId == 0 &&
                                serviceId == 0
                              "
                            >
                              <strong>{{ tm("msgAgenda04") }}</strong>
                            </div>
                            <span
                              v-for="(horarioItem, index) in horariosDisponiveis"
                              :key="index"
                              :value="horarioItem"
                            >
                              <button
                                style="border-radius: 25px"
                                :class="
                                  !!stateLocal.horaIni &&
                                  stateLocal.horaIni == horarioItem.timeSpan &&
                                  stateLocal.agendaId == horarioItem.agendaId &&
                                  stateLocal.serviceId == horarioItem.serviceId
                                    ? 'btn-primary'
                                    : 'btn-outline-primary'
                                "
                                class="btn mr-2 mb-2 pl-2 pr-2"
                                :title="
                                  horarioItem.agendaName + ' - ' + horarioItem.serviceName
                                "
                                @click="setHorario(horarioItem)"
                              >
                                {{ horarioItem.timeSpan }}
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-7 form-group mb-1 pr-0">
                  <label class="mb-1">{{ tm("nomeCliente") }} *</label>
                  <div class="autocomplete">
                    <input
                      class="form-control"
                      id="search"
                      autocomplete="off"
                      :disabled="stateLocal.id"
                      type="text"
                      v-model="stateLocal.search"
                      :placeholder="tm('msgAgenda01')"
                      @input="onChange"
                    />
                    <ul v-show="stateLocal.isOpen" class="autocomplete-results">
                      <li
                        v-for="(result, i) in stateLocal.results"
                        :key="i"
                        @click="setResult(result)"
                        class="autocomplete-result"
                      >
                        <small>{{ result.nome + " CPF: " + result.cpf }}</small>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-5 form-group mb-1 pl-1">
                  <label class="mb-1">{{ tm("cpfCliente") }}</label>
                  <input
                    :disabled="true"
                    id="cpf"
                    class="form-control p-1"
                    v-model="stateLocal.cpf"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group">
                  <label class="mb-1">{{ tm("canaisCliente") }}</label>
                  <table class="table w-100">
                    <tr
                      v-for="(canal, index) in stateLocal.selectedCliente.crmCanalCliente"
                      :key="index"
                      style="height: 38px"
                    >
                      <td style="width: 35px" class="align-middle p-1">
                        <img
                          :src="require(`@/assets/images/${canal.tipo}.png`)"
                          alt=""
                          style="max-height: 25px !important; max-width: 25px !important"
                        />
                      </td>
                      <td class="align-middle p-1">
                        <p class="text-truncate mb-0 align-middle">{{ canal.valor }}</p>
                      </td>
                      <td class="text-right w-25 text-nowrap">
                        <a
                          v-if="
                            (canal.tipo == constants.TipoCanal.indexOf('WHATSAPP') ||
                              canal.tipo ==
                                constants.TipoCanal.indexOf('WHATSAPPBUSINESS')) &&
                            (!selectedChatQueue ||
                              selectedChatQueue.chatId != canal.valor)
                          "
                          class="py-0 px-1"
                          href="#"
                          :title="tm('gerarAtendimento')"
                          role="button"
                          @click="gerarAtendimento(canal)"
                        >
                          <svg
                            class="hw-20 mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            width="24"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                            />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row" v-if="!stateLocal.id">
                <div class="col-12 form-group mb-1 mt-2">
                  <a href="#" @click="criarCliente" style="line-height: 14px"
                    ><h6>{{ tm("msgAgenda05") }}</h6></a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group mb-1">
                  <label class="mb-1">{{ tm("observacao") }}</label>
                  <textarea
                    v-model="stateLocal.observacao"
                    class="form-control p-1 scrolling"
                    id="messageInput"
                    rows="2"
                  >
                  </textarea>
                </div>
              </div>
              <div class="d-flex p-2 mb-1 card-footer justify-content-between">
                <button
                  type="button"
                  :disabled="!stateLocal.id"
                  @click="stateLocal.showModalExcluir = true"
                  class="btn btn-primary"
                >
                  <i class="fas fa-trash-alt fa-lg mx-1"></i>{{ tm("excluir") }}
                </button>
                <button type="button" @click="salvar" class="btn btn-primary">
                  <i class="fa fa-save fa-lg mx-1"></i>{{ tm("salvar") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="conteudo" style="overflow-x: hidden">
          <header class="card-header py-2 m-0 titulo">
            <span class="mb-0 font-weight-bold">{{ tm("calendario") }}</span>
          </header>
          <div class="card-body p-1" style="overflow-x: hidden">
            <div class="row">
              <div class="col-md-9 col-12">
                <div class="d-flex p-1 mb-2">
                  Dia:

                  <datepicker
                    inputFormat="dd/MM/yyyy"
                    typeable
                    :locale="langData"
                    class="form-control ml-2 mr-0"
                    v-model="selectedDate"
                  />
                  <span class="input-group-text transparent-bg border-left-0">
                    <!-- Default :: Inline SVG -->
                    <svg
                      class="hw-20 text-muted"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12 mb-2">
                <div class="input-group">
                  <div class="dropdown col-12 px-1">
                    <button
                      class="btn btn-outline-default dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {{ tm("minhasAgendas") }}
                    </button>
                    <form
                      class="dropdown-menu w-auto mx-1"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        v-for="agendaItem in store.state.minhasAgendas"
                        :key="agendaItem.id"
                      >
                        <label class="dropdown-item px-2 text-nowrap" title="">
                          <div class="custom-control custom-switch">
                            <input
                              :id="'chekagenda' + agendaItem.id"
                              type="checkbox"
                              v-model="agendaFilter"
                              class="custom-control-input mr-1"
                              :value="agendaItem"
                            />
                            <label
                              class="custom-control-label mb-0"
                              :for="'chekagenda' + agendaItem.id"
                            ></label>
                          </div>
                          {{ agendaItem.name }}
                          <button
                            type="button"
                            class="button-agenda font-weight-bold ml-1 vuecal__event"
                            :class="agendaItem.color"
                            aria-pressed="false"
                            autocomplete="off"
                          ></button>
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 mb-2 pl-0 text-right">
                <button type="button" @click="criarEvento" class="btn btn-primary">
                  <i class="far fa-calendar-plus fa-lg mx-1"></i>Incluir
                </button>
              </div>
            </div>
            <div class="row">
              <div class="card-body p-1 agenda-calendario" style="overflow-x: hidden">
                <vue-cal
                  v-if="!stateLocal.isloading"
                  ref="calendario"
                  id="calendario"
                  :time-from="stateLocal.timeFrom"
                  :time-to="stateLocal.timeTo"
                  :locale="langCal"
                  :disable-views="['week', 'month', 'years', 'year']"
                  :events="events"
                  :time-step="stateLocal.intervalo"
                  events-on-month-view="short"
                  :on-event-click="onEventClick"
                  :startWeekOnSunday="stateLocal.startWeekOnSunday"
                  active-view="day"
                  :selected-date="selectedDate"
                  @view-change="fetchEvents"
                >
                </vue-cal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="stateLocal.showModalExcluir"
      :title="tm('excluirEvento')"
      @closeModal="stateLocal.showModalExcluir = false"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <h6 class="text-truncate">
              {{ tm("msgAgenda06") + stateLocal.title + "?" }}
            </h6>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          @click="stateLocal.showModalExcluir = false"
        >
          {{ tm("nao") }}
        </button>
        <button type="button" class="btn btn-primary js-btn-step" @click="excluir">
          {{ tm("sim") }}
        </button>
      </template>
    </modal>
    <modal
      v-if="stateLocal.modalCliente"
      :title="tm('msgCliente07')"
      @closeModal="stateLocal.modalCliente = false"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-7 form-group mb-1 pr-0">
            <label class="mb-1">{{ tm("nome") }} *</label>
            <input
              type="text"
              id="nomeCliente"
              class="form-control p-1"
              v-model="cliente.nome"
            />
          </div>
          <div class="col-5 form-group mb-1">
            <label class="mb-1">{{ tm("sobrenome") }} *</label>
            <input
              type="text"
              id="sobrenome"
              class="form-control p-1"
              v-model="cliente.sobrenome"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 form-group mb-1 pr-0">
            <label class="mb-1">WhatsApp {{ autocadastrozappag ? " *" : "" }}</label>
            <input
              type="text"
              id="celularCliente"
              placeholder="+99 (99) 99999-9999"
              v-maska="['+## (##) #####-####', '+## (##) ####-####']"
              class="form-control p-1"
              v-model="cliente.celular"
            />
          </div>
          <div class="col-6 form-group mb-1">
            <label class="mb-1">{{ tm("cpf") }}</label>
            <input
              id="cpfCliente"
              placeholder="999.999.999-99"
              v-maska="'###.###.###-##'"
              class="form-control p-1"
              v-model="cliente.cpf"
            />
          </div>
        </div>
        <!-- <div class="row">
                <div class="col-12 form-group mb-1">
                  <label class="mb-1">Email</label>
                  <input type="email"  id="emailCliente" class="form-control p-1" v-model="cliente.email">
                </div>
              </div> -->
        <div class="row">
          <div class="col-6 form-group mb-1">
            <label class="mb-1">{{ tm("cep") + (autocadastrozappag ? " *" : "") }}</label>
            <div class="d-flex">
              <input
                v-maska="'#####-###'"
                placeholder="99999-999"
                id="inputCep"
                class="form-control p-1 mr-2"
                v-model="cliente.crmEnderecoCliente.cep"
              />
              <a
                href="#"
                class="mt-1"
                v-if="!stateLocal.isLoadingCEP"
                :title="tm('msgCliente10')"
                @click="buscarCep"
              >
                <i class="fas fa-search"></i>
              </a>
              <div
                v-if="stateLocal.isLoadingCEP"
                class="spinner-border spinner-border-sm mt-1"
                role="status"
              ></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5 form-group mb-1 pr-0">
            <label class="mb-1">{{
              tm("estado") + (autocadastrozappag ? " *" : "")
            }}</label>
            <select
              id="inputEstado"
              class="custom-select font-size-sm shadow-none p-1"
              v-model="estadoSelect"
            >
              <option :value="null">{{ tm("erroCliente07") }}</option>
              <option
                v-for="estadoItem in estadoList"
                :key="estadoItem.codigo"
                :value="estadoItem.codigo"
              >
                {{ estadoItem.nome + "-" + estadoItem.uf }}
              </option>
            </select>
          </div>

          <div class="col-7 form-group mb-1">
            <label class="mb-1"
              >{{ tm("cidade") + (autocadastrozappag ? " *" : "") }}
            </label>
            <select
              id="inputCidade"
              class="custom-select font-size-sm shadow-none p-1"
              v-model="cidadeSelect"
            >
              <option :value="null">{{ tm("erroCliente08") }}</option>
              <option
                v-for="cidadeItem in cidadeList"
                :key="cidadeItem.codigo"
                :value="cidadeItem.codigo"
              >
                {{ cidadeItem.nome }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group mb-1">
            <label class="mb-1">{{ tm("endereco") }}</label>
            <input
              type="text"
              class="form-control p-1"
              v-model="cliente.crmEnderecoCliente.endereco"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group mb-1">
            <label class="mb-1">{{ tm("complemento") }}</label>
            <input
              type="text"
              class="form-control p-1"
              v-model="cliente.crmEnderecoCliente.complemento"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-8 form-group mb-1 pr-0">
            <label class="mb-1">{{ tm("bairro") }}</label>
            <input
              type="text"
              class="form-control p-1"
              v-model="cliente.crmEnderecoCliente.bairro"
            />
          </div>
          <div class="col-4 form-group mb-1">
            <label class="mb-1">{{ tm("numero") }}</label>
            <input
              type="text"
              class="form-control p-1"
              v-model="cliente.crmEnderecoCliente.numero"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          :disabled="stateLocal.isLoadingCEP || stateLocal.isLoadingCidade"
          @click="stateLocal.modalCliente = false"
        >
          {{ tm("cancelar") }}
        </button>
        <button
          type="button"
          class="btn btn-primary js-btn-step"
          :disabled="stateLocal.isLoadingCEP || stateLocal.isLoadingCidade"
          @click="salvarCliente"
        >
          {{ tm("salvar") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import moment from "moment";
import { computed, reactive, ref, watch } from "vue";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import Validar from "../../util/Validadores";
import constants from "../../util/constants";
import ClienteService from "../../services/ClienteService";
import CalendarioService from "../../services/CalendarioService";
import NumeroEnterpriseService from "../../services/NumeroEnterpriseService";
import ChatService from "../../services/ChatService";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/pt-br.es.js";
import "vue-cal/dist/i18n/es.es.js";
import Datepicker from "vue3-datepicker";
import { pt, es, enUS } from "date-fns/locale";
import Modal from "../../components/Modal";
import EnderecoService from "../../services/EnderecoService";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";

const toast = useToast();
export default {
  props: {
    timezoneDefault: String,
  },
  components: { VueCal, Datepicker, Modal },
  setup(props, context) {
    const { tm, locale } = useI18n();
    const selectedDate = ref(new Date());
    const store = useStore();
    const stateLocal = reactive({
      title: "",
      id: null,
      observacao: "",
      clientId: null,
      dataInicio: new Date(),
      horaIni: "",
      nomeAgenda: "",
      nomeServico: "",
      eventsList: [],
      intervaloList: [],
      intervalo: 15,
      startWeekOnSunday: false,
      start: "",
      end: "",
      serviceId: 0,
      agendaId: 0,
      isLoading: false,
      isLoadingHorarios: false,
      timeFrom: 360,
      timeTo: 1380,
      selectedCliente: {},
      searchText: "",
      search: "",
      results: [],
      isOpen: false,
      showEvento: false,
      showHorario: false,
      showModalExcluir: false,
      modalCliente: false,
      cpf: "",
      isLoadingCEP: false,
      isLoadingCidade: false,
      isLoadingHorarios: false,
    });
    const agendaFilter = ref([]);
    const selectedChatQueue = computed(() => {
      return store.state.selectedChatQueue;
    });
    const cliente = reactive({
      id: 0,
      nome: "",
      sobrenome: "",
      rg: "",
      cpf: "",
      nomeMae: "",
      email: "",
      celular: "",
      empresaId: 0,
      crmEnderecoCliente: {},
      crmCanalCliente: [],
    });
    const paisList = ref([]);
    const paisSelect = ref(1);
    const estadoList = ref([]);
    const estadoSelect = ref(null);
    const cidadeList = ref([]);
    const cidadeSelect = ref(null);
    const autocadastrozappag = ref(false);
    const numeroEnterprise = ref(null);
    const agendaList = ref([]);
    const servicoList = ref([]);
    const agendaId = ref(0);
    const serviceId = ref(0);
    const horariosDisponiveis = ref([]);
    const dataInicio = ref(new Date());

    const events = computed(() => {
      return stateLocal.eventsList.filter(filterByAgenda);
    });
    const langCal = computed(() => {
      return locale.value == "pt" ? "pt-br" : locale.value;
    });
    const langData = computed(() => {
      switch (locale.value) {
        case "en":
          return enUS;
        case "es":
          return es;
        case "pt":
          return pt;
        default:
          return pt;
      }
    });

    //Watch
    watch(estadoSelect, (newVal) => {
      stateLocal.isLoadingCidade = true;
      if (newVal) {
        EnderecoService.GetCidades(newVal)
          .then((response) => {
            cidadeList.value = response.data;
          })
          .catch((error) => {
            //console.log(error);
          })
          .finally(() => {
            stateLocal.isLoadingCidade = false;
          });
      } else {
        cidadeList.value = [];
        cidadeSelect.value = null;
        stateLocal.isLoadingCidade = false;
      }
    });

    watch(serviceId, (newVal) => {
      if (newVal != 0) {
        getAgenda(newVal);
      } else {
        getAgendaAll();
      }
      getHorariosDisponiveis();
    });

    watch(agendaId, (newVal) => {
      if (newVal != 0) {
        getServico(newVal);
      } else {
        getServicoAll();
      }
      getHorariosDisponiveis();
    });

    watch(dataInicio, (newVal) => {
      getHorariosDisponiveis();
    });

    async function getAgendaAll() {
      try {
        agendaList.value = [];
        const response = await CalendarioService.GetAgendaByAgent(store.state.usuario.id);
        agendaList.value = response.data;
      } catch (error) {
        //console.log(error);
      }
    }

    async function getAgenda(servico) {
      try {
        agendaList.value = [];
        const response = await CalendarioService.GetAgendaListByService(
          servico,
          store.state.usuario.id
        );
        agendaList.value = response.data;
      } catch (error) {
        //console.log(error);
      }
    }

    async function getServicoAll() {
      try {
        const response = await CalendarioService.GetServicoByEmpresa(
          store.state.usuario.empresaId
        );
        servicoList.value = response.data;
      } catch (error) {
        //console.log(error);
        servicoList.value = [];
      }
    }

    async function getServico(agenda) {
      try {
        const response = await CalendarioService.GetServiceListByAgenda(agenda);
        servicoList.value = response.data;
      } catch (error) {
        //console.log(error);
        servicoList.value = [];
      }
    }

    async function getHorariosDisponiveis() {
      if (
        !stateLocal.id &&
        (serviceId.value != stateLocal.serviceId || agendaId.value != stateLocal.agendaId)
      ) {
        clearHorario();
      }
      horariosDisponiveis.value = [];
      if (dataInicio.value && (agendaId.value != 0 || serviceId.value != 0)) {
        try {
          stateLocal.isLoadingHorarios = true;
          const response = await CalendarioService.GetHorariosDisponiveis({
            empresaId: store.state.usuario.empresaId,
            agendaId: agendaId.value,
            servicoId: serviceId.value,
            date: moment(dataInicio.value).format("YYYY-MM-DD"),
            singleDay: true,
          });
          horariosDisponiveis.value = response.data;
        } catch (e) {
          //console.log(e);
        } finally {
          stateLocal.isLoadingHorarios = false;
        }
      }
    }

    function setHorario(val) {
      stateLocal.start = val.start;
      stateLocal.end = val.end;
      stateLocal.horaIni = val.timeSpan;
      agendaId.value = val.agendaId;
      serviceId.value = val.serviceId;
      stateLocal.serviceId = val.serviceId;
      stateLocal.agendaId = val.agendaId;
      stateLocal.nomeAgenda = val.agendaName;
      stateLocal.nomeServico = val.serviceName;
    }

    function clearHorario() {
      stateLocal.start = "";
      stateLocal.end = "";
      stateLocal.horaIni = "";
      stateLocal.nomeAgenda = "";
      stateLocal.nomeServico = "";
      stateLocal.serviceId = 0;
      stateLocal.agendaId = 0;
    }

    function filterByAgenda(obj) {
      if (agendaFilter.value.find((element) => element.id === obj.agendaId)) {
        return true;
      } else {
        return false;
      }
    }

    function setAgendaFilter(value) {
      agendaFilter.value = value;
      limparDados();
    }

    async function fetchEvents({ view, startDate, endDate, week }) {
      selectedDate.value = new Date(startDate);
      try {
        stateLocal.isLoading = true;
        const response = await CalendarioService.GetEventoListByAgent(
          store.state.usuario.id,
          startDate,
          endDate
        );
        stateLocal.eventsList = response.data.events;
        stateLocal.intervalo =
          response.data.timeResolution != 0 ? response.data.timeResolution : 30;
        stateLocal.timeFrom =
          response.data.calendarTimeFrom && response.data.calendarTimeFrom != 0
            ? response.data.calendarTimeFrom
            : 360;
        stateLocal.timeTo =
          response.data.calendarTimeTo && response.data.calendarTimeTo != 0
            ? response.data.calendarTimeTo
            : 1380;
        stateLocal.startWeekOnSunday = response.data.weekStartDay === 7;
      } catch (error) {
        //console.log(error);
      } finally {
        stateLocal.isLoading = false;
      }
    }
    function getNumeroEnterprise() {
      NumeroEnterpriseService.GetNumberByEmpresa({
        empresaId: store.state.usuario.empresaId,
        noSmbFilter: true,
      })
        .then((response) => {
          if (response.data && response.data.length >= 0) {
            numeroEnterprise.value = response.data[0];
          }
        })
        .catch((error) => {});
    }

    async function criarEvento() {
      limparDados();
      stateLocal.showEvento = true;
    }

    function limparDados() {
      stateLocal.id = null;
      stateLocal.title = "";
      stateLocal.start = "";
      stateLocal.end = "";
      agendaId.value = 0;
      stateLocal.serviceId = 0;
      stateLocal.agendaId = 0;
      serviceId.value = 0;
      stateLocal.observacao = "";
      dataInicio.value = new Date();
      stateLocal.horaIni = "";
      stateLocal.nomeAgenda = "";
      stateLocal.nomeServico = "";
      stateLocal.results = [];
      stateLocal.search = "";
      stateLocal.isOpen = false;
      stateLocal.clientId = null;
      stateLocal.selectedCliente = {};
      stateLocal.cpf = "";
      if (
        store.state.selectedChatQueue &&
        store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
      ) {
        stateLocal.clientId =
          store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId].id;
        stateLocal.selectedCliente =
          store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId];
        setResult(store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]);
        stateLocal.results.push(
          store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
        );
        stateLocal.cpf = stateLocal.selectedCliente.cpf
          ? Validar.formatCnpjCpf(stateLocal.selectedCliente.cpf)
          : "";
      }
    }

    function onEventClick(event, e) {
      stateLocal.id = event.id;
      stateLocal.title = event.title;
      agendaId.value = event.agendaId;
      stateLocal.nomeAgenda = event.agenda ? event.agenda.name : "";
      serviceId.value = event.serviceId ? event.serviceId : 0;
      stateLocal.nomeServico = event.service ? event.service.name : "";
      stateLocal.observacao = event.observation;
      dataInicio.value = event.start;
      stateLocal.horaIni =
        moment(event.start).format("HH:mm") + "/" + moment(event.end).format("HH:mm");
      stateLocal.clientId = event.clientId;
      stateLocal.selectedCliente = {};
      stateLocal.results = [];
      stateLocal.search = "";
      stateLocal.isOpen = false;
      if (stateLocal.clientId) {
        getCliente();
      }
      stateLocal.start = moment(event.start).format("YYYY-MM-DD HH:mm");
      stateLocal.end = moment(event.end).format("YYYY-MM-DD HH:mm");
      stateLocal.showEvento = true;
      toast.info(tm("msgAgenda08"));
      e.stopPropagation();
    }

    function getCliente() {
      ClienteService.GetClienteById(stateLocal.clientId)
        .then((response) => {
          setResult(response.data);
          stateLocal.results.push(response.data);
        })
        .catch((error) => {});
    }
    function onChange() {
      searchClientes();
      stateLocal.isOpen = true;
    }

    function searchClientes() {
      stateLocal.selectedCliente = {};
      if (stateLocal.search && stateLocal.search.trim().length > 0) {
        ClienteService.GetClienteByNomeOrCpf(
          store.state.usuario.empresaId,
          stateLocal.search
        )
          .then((response) => {
            stateLocal.results = response.data;
          })
          .catch((error) => {
            stateLocal.results = [];
          });
      } else {
        stateLocal.results = [];
      }
    }

    function setResult(result) {
      stateLocal.selectedCliente = result;
      stateLocal.search = result.nome;
      stateLocal.cpf = result.cpf ? Validar.formatCnpjCpf(result.cpf) : "";
      stateLocal.isOpen = false;
    }

    async function salvar() {
      if (!dadosEvento()) {
        return false;
      }

      let args = {
        title: stateLocal.title,
        startDate: stateLocal.start,
        endDate: stateLocal.end,
        color: null,
        observation: stateLocal.observacao,
        extraData: null,
        crmClientId: stateLocal.selectedCliente.id ? stateLocal.selectedCliente.id : null,
        serviceId: serviceId.value,
      };
      try {
        if (!stateLocal.id) {
          const res = await CalendarioService.AddEvento(agendaId.value, args);
          toast.info(tm("msgAgenda09"));
        } else {
          const resp = await CalendarioService.UpdateEvento(stateLocal.id, args);
          toast.info(tm("msgAgenda10"));
        }
      } catch (err) {
        //console.log(err);
        toast.warning(tm("erroAgenda01"));
      } finally {
        await fetchEvents({
          view: "day",
          startDate: moment(selectedDate.value).format("YYYY-MM-DD") + " 00:00",
          endDate: moment(selectedDate.value).format("YYYY-MM-DD") + " 23:59",
          week: "",
        });
        limparDados();
        stateLocal.showEvento = false;
      }
    }

    function dadosEvento() {
      if (!stateLocal.title) {
        document.getElementById("title").focus();
        toast.warning(tm("erroAgenda02"));
        return false;
      }
      if (!agendaId.value || agendaId.value == 0) {
        document.getElementById("agendaId").focus();
        toast.warning(tm("erroAgenda03"));
        return false;
      }
      if (!serviceId.value || serviceId.value == 0) {
        document.getElementById("serviceId").focus();
        toast.warning(tm("erroAgenda04"));
        return false;
      }
      if (!dataInicio.value) {
        toast.warning(tm("erroAgenda05"));
        return false;
      }
      if (!stateLocal.start) {
        toast.warning(tm("erroAgenda06"));
        return false;
      }
      if (!stateLocal.search) {
        document.getElementById("search").focus();
        toast.warning(tm("erroAgenda07"));
        return false;
      }
      return true;
    }

    async function excluir() {
      if (!stateLocal.id) {
        return false;
      }
      try {
        const res = await CalendarioService.DeleteEvento(stateLocal.id);
        toast.info(tm("msgAgenda11"));
      } catch (err) {
        //console.log(err);
        toast.warning(tm("erroAgenda08"));
      } finally {
        await fetchEvents({
          view: "day",
          startDate: moment(selectedDate.value).format("YYYY-MM-DD") + " 00:00",
          endDate: moment(selectedDate).format("YYYY-MM-DD") + " 23:59",
          week: "",
        });
        limparDados();
        stateLocal.showEvento = false;
        stateLocal.showModalExcluir = false;
      }
    }

    function criarCliente() {
      cliente.id = 0;
      cliente.nome = "";
      cliente.sobrenome = "";
      cliente.rg = "";
      cliente.cpf = "";
      cliente.nomeMae = "";
      cliente.email = "";
      cliente.celular = "";
      cliente.empresaId = store.state.usuario.empresaId;
      cliente.crmEnderecoCliente = {};
      cliente.crmCanalCliente = [];
      paisSelect.value = getCodigoPais("brasil");
      estadoSelect.value = null;
      cidadeSelect.value = null;
      stateLocal.modalCliente = true;
    }

    async function salvarCliente() {
      const dadosOk = await dadosCliente();
      if (!dadosOk) {
        return false;
      }
      let canais = [];
      if (cliente.email) {
        canais.push({ tipo: constants.TipoCanal.indexOf("EMAIL"), valor: cliente.email });
      }
      if (cliente.celular) {
        let tipo =
          numeroEnterprise.value && numeroEnterprise.value.usuario != "smbdummy"
            ? constants.TipoCanal.indexOf("WHATSAPP")
            : constants.TipoCanal.indexOf("WHATSAPPBUSINESS");
        canais.push({
          tipo: tipo,
          valor: cliente.celular
            .replaceAll("+", "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("-", "")
            .replaceAll(" ", ""),
        });
      }
      await verificaCEP();
      cliente.crmEnderecoCliente.pais = paisSelect.value
        ? getNomePais(paisSelect.value)
        : " ";
      cliente.crmEnderecoCliente.estado = estadoSelect.value
        ? getNomeEstado(estadoSelect.value)
        : " ";
      cliente.crmEnderecoCliente.cidade = cidadeSelect.value
        ? getNomeCidade(cidadeSelect.value)
        : " ";
      cliente.crmEnderecoCliente.codPais = paisSelect.value;
      cliente.crmEnderecoCliente.codEstado = estadoSelect.value;
      cliente.crmEnderecoCliente.codCidade = cidadeSelect.value;
      let endereco = { ...cliente.crmEnderecoCliente };
      endereco.cep = endereco.cep ? endereco.cep.replaceAll("-", "") : "";
      ClienteService.AddCliente({
        nome: cliente.nome,
        nomeMae: cliente.nomeMae,
        sobrenome: cliente.sobrenome,
        cpf: cliente.cpf ? cliente.cpf.replaceAll(".", "").replaceAll("-", "") : "",
        rg: cliente.rg,
        empresaId: cliente.empresaId,
        crmEnderecoCliente: [endereco],
        crmCanalCliente: canais,
      })
        .then((res) => {
          toast.info(tm("msgCliente01"));
          setResult(res.data);
          stateLocal.results.push(res.data);
          stateLocal.modalCliente = false;
        })
        .catch((error) => {
          //console.log(error);
          toast.warning(tm("erroCliente01"));
        });
    }

    async function dadosCliente() {
      if (!cliente.nome) {
        document.getElementById("nomeCliente").focus();
        toast.warning(tm("erroCliente03"));
        return false;
      }
      if (!cliente.sobrenome) {
        document.getElementById("sobrenome").focus();
        toast.warning(tm("erroCliente04"));
        return false;
      }
      if (autocadastrozappag && !cliente.celular) {
        document.getElementById("celularCliente").focus();
        toast.warning(tm("erroCliente09"));
        return false;
      }
      if (autocadastrozappag && !cliente.crmEnderecoCliente.cep) {
        document.getElementById("inputCep").focus();
        toast.warning(tm("erroCliente06") + "!");
        return false;
      }
      if (autocadastrozappag && !estadoSelect.value) {
        document.getElementById("inputEstado").focus();
        toast.warning(tm("erroCliente07"));
        return false;
      }
      if (autocadastrozappag && !cidadeSelect.value) {
        document.getElementById("inputCidade").focus();
        toast.warning(tm("erroCliente08"));
        return false;
      }
      if (cliente.email) {
        try {
          const resEmail = await ClienteService.getClienteByChannel(
            store.state.usuario.empresaId,
            cliente.email,
            constants.TipoCanal.indexOf("EMAIL")
          );
          if (resEmail.data) {
            document.getElementById("emailCliente").focus();
            toast.warning(
              tm("erroCliente10") + resEmail.data.nome + " CPF: " + resEmail.data.cpf
            );
            return false;
          }
        } catch (error) {
          //console.log(error);
          toast.warning(tm("erroCliente11"));
          return false;
        }
      }
      if (cliente.celular) {
        try {
          const resCelular = await ClienteService.getClienteByChannel(
            store.state.usuario.empresaId,
            cliente.celular
              .replaceAll("+", "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll("-", "")
              .replaceAll(" ", ""),
            constants.TipoCanal.indexOf("WHATSAPP")
          );
          if (resCelular.data) {
            document.getElementById("celularCliente").focus();
            toast.warning(
              tm("erroCliente12") + resCelular.data.nome + " CPF: " + resCelular.data.cpf
            );
            return false;
          }
          const resCelularB = await ClienteService.getClienteByChannel(
            store.state.usuario.empresaId,
            cliente.celular
              .replaceAll("+", "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll("-", "")
              .replaceAll(" ", ""),
            constants.TipoCanal.indexOf("WHATSAPPBUSINESS")
          );
          if (resCelularB.data) {
            document.getElementById("celularCliente").focus();
            toast.warning(
              tm("erroCliente12") +
                resCelularB.data.nome +
                " CPF: " +
                resCelularB.data.cpf
            );
            return false;
          }
        } catch (error) {
          console.debug(error);
          toast.warning(tm("erroCliente11"));
          return false;
        }
      }
      return true;
    }

    function setaAtocadastrozappag(value) {
      autocadastrozappag.value = value;
    }

    async function buscarCep() {
      if (cliente.crmEnderecoCliente.cep) {
        try {
          stateLocal.isLoadingCEP = true;
          const res = await EnderecoService.ConsultaEndereco(
            cliente.crmEnderecoCliente.cep
          );
          paisSelect.value = res.data.codPais;
          estadoSelect.value = res.data.codEstado;
          cidadeSelect.value = res.data.codCidade;
          cliente.crmEnderecoCliente.endereco = res.data.viaCep.logradouro;
          cliente.crmEnderecoCliente.bairro = res.data.viaCep.bairro;
          cliente.crmEnderecoCliente.cidade = res.data.viaCep.localidade;
          cliente.crmEnderecoCliente.estado = res.data.viaCep.uf;
        } catch (err) {
          //console.log(err);
          estadoSelect.value = null;
          cidadeSelect.value = null;
          toast.warning(tm("erroCLiente13"));
        } finally {
          stateLocal.isLoadingCEP = false;
        }
      }
    }

    function getPaises() {
      EnderecoService.GetPaises()
        .then((response) => {
          paisList.value = response.data;
          paisSelect.value = getCodigoPais("brasil");
          getEstados();
        })
        .catch((error) => {
          //console.log(error);
        });
    }

    function getEstados() {
      EnderecoService.GetEstados(paisSelect.value)
        .then((response) => {
          estadoList.value = response.data;
        })
        .catch((error) => {
          //console.log(error);
        });
    }

    function getNomePais(value) {
      let pais = paisList.value.find((x) => x.codigo === value);
      return pais ? pais.nome : "";
    }
    function getCodigoPais(value) {
      let pais = paisList.value.find((x) => x.nome.toLowerCase() === value.toLowerCase());
      return pais ? pais.codigo : 1;
    }
    function getNomeEstado(value) {
      let estado = estadoList.value.find((x) => x.codigo === value);
      return estado ? estado.uf : "";
    }

    function getNomeCidade(value) {
      let cidade = cidadeList.value.find((x) => x.codigo === value);
      return cidade ? cidade.nome : "";
    }

    async function verificaCEP() {
      if (
        cliente.crmEnderecoCliente.cep &&
        (!paisSelect.value || !estadoSelect.value || !cidadeSelect.value)
      ) {
        try {
          const res = await EnderecoService.ConsultaEndereco(
            cliente.crmEnderecoCliente.cep
          );
          paisSelect.value = res.data.codPais;
          estadoSelect.value = res.data.codEstado;
          cidadeSelect.value = res.data.codCidade;
          cliente.crmEnderecoCliente.cidade = res.data.viaCep.localidade;
          cliente.crmEnderecoCliente.estado = res.data.viaCep.uf;
        } catch (err) {
          //console.log(err);
        }
      }
    }

    async function gerarAtendimento(obj) {
      if (!obj.valor) {
        toast.warning(tm("erroGerarAth02"));
        return false;
      }
      for (const chat of store.state.chatsTableData) {
        if (chat.chatId === obj.valor) {
          toast.warning(tm("erroGerarAth03"));
          return false;
        }
      }
      let myuuid = uuidv4();
      let mediaId = localStorage.getItem("mediaId");
      let custom = {
        "@idLoja": store.state.usuario.empresa.idLojaManager
          ? store.state.usuario.empresa.idLojaManager
          : "",
        MediaId: numeroEnterprise.value
          ? numeroEnterprise.value.mediaId
          : mediaId
          ? mediaId
          : "",
      };
      let param = {
        empresaId: store.state.usuario.empresaId,
        flowUUID: numeroEnterprise.value ? numeroEnterprise.value.roboFlowId : "ATH Chat",
        chatUUID: myuuid,
        flowName: "ATH Chat",
        channel: obj.tipo,
        priority: 50,
        GoalPoint: false,
        queueId: "ATH" + store.state.usuario.empresaId,
        websocket: "",
        chatId: obj.valor,
        agentId: store.state.usuario.id,
        instanceId: "SMB",
      };
      ChatService.GerarAtendimento(custom, param)
        .then((response) => {
          toast.info(tm("msgGerarAth"));
        })
        .catch((error) => {
          console.debug(error);
        })
        .finally(() => {
          context.emit("getChats");
        });
    }

    //created
    getPaises();
    getNumeroEnterprise();
    getAgendaAll();
    getServicoAll();

    return {
      moment,
      props,
      store,
      stateLocal,
      selectedChatQueue,
      events,
      fetchEvents,
      onEventClick,
      criarEvento,
      selectedDate,
      pt,
      es,
      enUS,
      agendaFilter,
      onChange,
      setAgendaFilter,
      setResult,
      salvar,
      excluir,
      criarCliente,
      salvarCliente,
      cliente,
      paisSelect,
      estadoList,
      estadoSelect,
      cidadeList,
      cidadeSelect,
      buscarCep,
      setaAtocadastrozappag,
      autocadastrozappag,
      gerarAtendimento,
      constants,
      tm,
      agendaId,
      serviceId,
      agendaList,
      servicoList,
      setHorario,
      horariosDisponiveis,
      dataInicio,
      clearHorario,
      langCal,
      langData,
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

body {
  background-color: #eee;
  font-family: sans-serif;
}
.topico {
  display: block;
  width: 100%;
}

.topico .titulo {
  cursor: pointer;
  position: relative;
  transition: all 0.2s linear;
}

.topico .titulo > svg {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 20px;
  height: 20px;
  transform: translateY(-50%) rotate(0deg);
  transition: all 0.2s linear;
}

.topico .conteudo {
  color: #3c3c3c;
  opacity: 0;
  max-height: 0px;
  transition: all 0.4s ease-out;
}

.topico .conteudo div.card-body-agenda {
  transition: all 0.4s ease-out;
  max-height: 0px;
  overflow-y: auto;
}

.topico.open .titulo > svg {
  transform: translateY(-50%) rotate(180deg);
}

.topico.open .conteudo {
  overflow: hidden;
  opacity: 1;
  max-height: 90vh;
}

.topico.open .conteudo div.card-body-agenda {
  min-height: calc(88vh - 74px);
}

.topico.open .conteudo div.card-footer {
  max-height: 74px;
}

.agenda-calendario {
  min-height: calc(70vh - 74px);
  transition: all 0.4s ease-out;
  max-height: 0px;
  overflow-y: auto;
}

.button-agenda {
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
}

.vuecal__header .vuecal__view-btn {
  font-size: 1em;
}

.vuecal__header .vuecal__title-bar {
  font-size: 0.9em;
}

.vuecal__time-column .vuecal__time-cell {
  font-size: 0.8em;
}
.vuecal__no-event {
  border: 1px solid #fff;
  font-size: 0.8em;
}

.vuecal__event {
  border: 1px solid #fff;
  font-size: 0.8em;
  cursor: pointer;
}
.vuecal__event.gradient-black-blue {
  background-color: #004e92;
  background-image: -o-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  background: linear-gradient(-154deg, #004e92 0%, #000428 100%);
  color: #fff;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}

.vuecal__event.gradient-purple-indigo {
  background-color: #a855f7;
  background-image: -o-linear-gradient(-154deg, #a855f7 0%, #33214b 100%);
  background: linear-gradient(-154deg, #a855f7 0%, #33214b 100%);
  color: #fff;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}

.vuecal__event.blue {
  background: #3b82f6;
  color: #fff;
}

.vuecal__event.indigo {
  background: #6366f1;
  color: #fff;
}

.vuecal__event.purple {
  background: #a855f7;
  color: #fff;
}

.vuecal__event.pink {
  background: #ec4899;
  color: #fff;
}

.vuecal__event.red {
  background: #ef4444;
  color: #fff;
}

.vuecal__event.orange {
  background: #f97316;
  color: #fff;
}

.vuecal__event.green {
  background-color: #4caf50;
  color: #fff;
}

.vuecal__event.yellow {
  background: #f59e0b;
  color: #000;
}

.vuecal__event.gray-100 {
  background-color: #b4bbc6;
  color: #000;
}
.vuecal__event.teal {
  background: #14b8a6;
  color: #fff;
}

.vuecal__event.cyan {
  background: #20c9dc;
  color: #fff;
}

.vuecal__event.gray-900 {
  background: #111827;
  color: #fff;
}
.vuecal__event.btn-youtube {
  background: #c4302b;
  color: #fff;
}
.vuecal__event.btn-dribble {
  background: #ea4c89;
  color: #fff;
}
.vuecal__event.btn-instagram {
  background: rgb(193, 53, 132);
  color: #fff;
}
.vuecal__event.btn-twitter {
  background: rgb(3, 159, 245);
  color: #fff;
}
.vuecal__event.btn-linkedin {
  background: rgb(0, 119, 181);
  color: #fff;
}
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  min-height: 1em;
  max-height: 6em;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>
