import crypto from "crypto";

export default {

  getAlgorithm(keyBase64) {
    var key = Buffer.from(keyBase64, 'base64');
    switch (key.length) {
      case 16:
        return 'aes-128-cbc';
      case 32:
        return 'aes-256-cbc';
    }
    throw new Error('Invalid key length: ' + key.length);
  },

  encrypt(plainText) {
    const key = Buffer.from(process.env.VUE_APP_KEY_BASE64, 'base64');
    const iv = Buffer.from(process.env.VUE_APP_IV_BASE64, 'base64');

    const cipher = crypto.createCipheriv(this.getAlgorithm(process.env.VUE_APP_KEY_BASE64), key, iv.slice(0, 16));
    let encrypted = cipher.update(plainText, 'utf8', 'base64');
    encrypted += cipher.final('base64');
    return encrypted;
  },

  decrypt(messagebase64) {
    const key = Buffer.from(process.env.VUE_APP_KEY_BASE64, 'base64');
    const iv = Buffer.from(process.env.VUE_APP_IV_BASE64, 'base64');

    const decipher = crypto.createDecipheriv(this.getAlgorithm(process.env.VUE_APP_KEY_BASE64), key, iv.slice(0, 16));
    let decrypted = decipher.update(messagebase64, 'base64');
    decrypted += decipher.final();
    return decrypted;
  }

}