<template>
  <div class="produtos">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10 mt-2">
        <div class="input-group rounded">
          <div v-if="!stateLocal.isEmbedded" class="input-group rounded">
            <input type="checkbox" v-model="stateLocal.semEstoque" id="chkSemestoque" />
            <p>{{ tm("semEstoque") }}</p>
          </div>
          <br />
          <input
            type="search"
            class="form-control rounded"
            :placeholder="tm('buscar')"
            aria-label="Search"
            aria-describedby="busca-addon"
            @keyup.enter="buscarProdutoNovo"
            :disabled="stateLocal.isLoading"
            v-model="stateLocal.termoBusca"
          />&nbsp;
          <button
            type="button"
            class="btn btn-primary js-btn-step"
            :disabled="stateLocal.isLoading"
            @click="buscarProdutoNovo"
          >
            <div
              v-if="stateLocal.isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
            ></div>
            <div v-else><i class="fas fa-search"></i></div>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!stateLocal.isEmbedded || (sortimentoBusca && sortimentoBusca.length > 0)"
      class="row"
      style="margin-top: 3px"
    >
      <div class="col-1"></div>
      <div class="col-10">
        <select
          v-model="stateLocal.sortimentoSelecionado"
          class="form-control form-select"
          @change="handleFiltrarSortimento($event.target.value)"
        >
          <option value="-1" selected>-- {{ tm("msgAth20") }} --</option>
          <option
            v-for="sortimento in sortimentoBusca"
            :value="sortimento"
            :key="sortimento"
          >
            {{ sortimento }}
          </option>
        </select>
      </div>
    </div>

    <!--div v-if="stateLocal.isLoading" class="spinner-border spinner-border-sm " role="status" /-->
    <div style="width: 65%; float: right" class="row">
      <!--primeiro-->
      <button
        v-if="stateLocal.paginaBusca > 1"
        type="button"
        class="btn btn-primary js-btn-step"
        @click="buscarItensAdicionaisVoltaPrimeiro"
      >
        <div><i class="fas fa-angle-double-left"></i></div>
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary js-btn-step"
        @click="buscarItensAdicionaisVoltaPrimeiro"
        disabled
      >
        <div><i class="fas fa-angle-double-left"></i></div>
      </button>
      <!--primeiro-->
      <!--1 anterior-->
      <button
        v-if="stateLocal.paginaBusca > 1"
        type="button"
        class="btn btn-primary js-btn-step"
        @click="buscarItensAdicionaisVolta"
      >
        <div>{{ stateLocal.paginaBusca - 1 }}</div>
      </button>
      <button
        v-else-if="stateLocal.paginaBusca > 0"
        type="button"
        class="btn btn-primary js-btn-step"
        @click="buscarItensAdicionaisVolta"
        disabled
      >
        <div>{{ stateLocal.paginaBusca - 1 }}</div>
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary js-btn-step"
        @click="buscarItensAdicionaisVolta"
        disabled
      >
        <div>-</div>
      </button>
      <!--1 anterior-->
      <!---->
      <button type="button" class="btn btn-primary js-btn-step" disabled>
        <div>{{ stateLocal.paginaBusca }}</div>
      </button>
      <!---->
      <!--1 depois-->
      <button
        v-if="stateLocal.paginaBusca < stateLocal.paginaFinal"
        type="button"
        class="btn btn-primary js-btn-step"
        @click="buscarItensAdicionaisFrente"
      >
        <div>{{ stateLocal.paginaBusca + 1 }}</div>
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary js-btn-step"
        @click="buscarItensAdicionaisFrente"
        disabled
      >
        <div>-</div>
      </button>
      <!--1 depois-->
      <!-- ultimo-->
      <button
        v-if="stateLocal.paginaBusca < stateLocal.paginaFinal"
        type="button"
        class="btn btn-primary js-btn-step"
        @click="buscarItensAdicionaisFrenteUltimo"
      >
        <i class="fas fa-angle-double-right"></i>
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary js-btn-step"
        @click="buscarItensAdicionaisFrenteUltimo"
        disabled
      >
        <i class="fas fa-angle-double-right"></i>
      </button>
      <!-- ultimo-->
    </div>
    <br />
    <div
      id="agrupadorRetorno"
      class="scrolling"
      style="overflow: auto; height: 40vh; width: 100%; margin-top: 0px"
    >
      <table class="table table-sm">
        <thead class="thead-light">
          <tr>
            <th colspan="4" style="text-align: center">
              <p class="text-truncate mb-0">Produto</p>
            </th>
            <th style="text-align: center"><p class="text-truncate mb-0">Ações</p></th>
          </tr>
        </thead>
        <template
          v-for="produto in stateLocal.produtosBusca"
          :key="produto.key"
          :value="produto.key"
        >
          <tr v-if="produto.visivel">
            <td>
              <p class="text-truncate mb-0">{{ produto.productCode }}</p>
            </td>
            <td>
              <p class="text-truncate mb-0">
                {{ produto.nomeProduto }}
                {{ produto.sortimento != null ? "- " + produto.sortimento : "" }}
              </p>
            </td>
            <td>
              <p class="text-truncate mb-0">
                {{
                  produto.precoProduto.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })
                }}
              </p>
            </td>
            <td>
              <p class="text-truncate mb-0">
                {{
                  produto.quantityProductStock > 0
                    ? tm("emEstoque") + produto.quantityProductStock
                    : tm("semEstoque")
                }}
              </p>
            </td>
            <td style="text-align: center">
              <p class="text-truncate mb-0">
                <a
                  class="pr-1 pl-1"
                  href="#"
                  :title="tm('compartilhar')"
                  role="button"
                  @click.once="handleCompartilharDescricao(produto)"
                >
                  <i class="fas fa-share-square fa-lg"></i>
                </a>
                <a
                  class="pr-1 pl-1"
                  href="#"
                  :title="tm('adicionarCarrinho')"
                  role="button"
                  @click="handleCarregarModalAdicionarItemCarrinho(produto)"
                >
                  <i class="fas fa-cart-plus fa-lg"></i>
                </a>
                <a
                  class="pr-1 pl-1"
                  href="#"
                  :title="tm('verEstoque')"
                  role="button"
                  v-if="!stateLocal.isEmbedded"
                  @click="handleCarregarModalEstoque(produto)"
                >
                  <i class="fas fa-warehouse fa-lg"></i>
                </a>
                <a
                  class="pr-1 pl-1"
                  href="#"
                  :title="tm('detalhe')"
                  role="button"
                  @click="handleCarregarModalImagem(produto)"
                >
                  <i class="fas fa-image fa-lg"></i>
                </a>
              </p>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div class="modal-footer"></div>
    <modal
      v-if="stateLocal.showModalEstoque"
      :title="tm('msgAth21')"
      @closeModal="stateLocal.showModalEstoque = false"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-2 font-weight-bold">{{ tm("produto") }}:</div>
          <div class="col-10">
            {{ stateLocal.propriedadesModalEstoque.produto.nomeProduto }}
            {{
              stateLocal.propriedadesModalEstoque.produto.sortimento != null
                ? "- " + stateLocal.propriedadesModalEstoque.produto.sortimento
                : ""
            }}
          </div>
        </div>
        <div class="row">
          <div class="col-2 font-weight-bold">{{ tm("sku") }}:</div>
          <div class="col-4">
            {{ stateLocal.propriedadesModalEstoque.produto.productCode }}
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 scrolling table-responsive-sm"
            style="overflow: auto; height: 156px; width: 100%; margin-top: 5px"
          >
            <table class="table table-sm">
              <thead class="thead-light">
                <tr>
                  <th>{{ tm("loja") }}</th>
                  <th style="text-align: center">{{ tm("estoque") }}</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tr
                v-for="estoqueLoja in stateLocal.propriedadesModalEstoque
                  .listaEstoqueLoja"
                :key="estoqueLoja.loja.restaurantId"
                :value="estoqueLoja.loja.restaurantId"
              >
                <td>{{ estoqueLoja.loja.restaurantName }}</td>
                <td style="text-align: center">{{ estoqueLoja.estoque }}</td>
                <td>
                  <a
                    class="px-1 py-1"
                    href="#"
                    :title="tm('trocarLoja')"
                    role="button"
                    @click="handleTrocarLoja(estoqueLoja.loja)"
                  >
                    <i class="fas fa-exchange-alt"></i>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          @click="stateLocal.showModalEstoque = false"
        >
          {{ tm("voltar") }}
        </button>
      </template>
    </modal>
    <modal
      v-if="stateLocal.showModalImagem"
      :title="tm('imagemProduto')"
      @closeModal="stateLocal.showModalImagem = false"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-2 font-weight-bold">{{ tm("produto") }}:</div>
          <div class="col-10">
            {{ stateLocal.propriedadesModalImagem.produto.nomeProduto }}
          </div>
        </div>
        <div class="row">
          <div
            v-if="
              !stateLocal.isEmbedded ||
              !!stateLocal.propriedadesModalImagem.produto.productCode
            "
            class="col-2 font-weight-bold"
          >
            SKU:
          </div>
          <div
            v-if="
              !stateLocal.isEmbedded ||
              !!stateLocal.propriedadesModalImagem.produto.productCode
            "
            class="col-4"
          >
            {{ stateLocal.propriedadesModalImagem.produto.productCode }}
          </div>
          <div class="col-2 font-weight-bold">{{ tm("estoque") }}:</div>
          <div class="col-4">
            {{ stateLocal.propriedadesModalImagem.produto.quantityProductStock }}
          </div>
        </div>
        <div class="row">
          <div class="col-3" />
          <div class="col-6">
            <img
              v-bind:src="stateLocal.propriedadesModalImagem.produto.imagemProduto"
              class="img-fluid"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          @click="stateLocal.showModalImagem = false"
        >
          {{ tm("voltar") }}
        </button>
      </template>
    </modal>
    <modal
      v-if="stateLocal.showModalAdicionarCarrinho"
      :title="tm('msgAth04')"
      @closeModal="stateLocal.showModalAdicionarCarrinho = false"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-2 font-weight-bold">{{ tm("produto") }}:</div>
          <div class="col-10">
            {{ stateLocal.propriedadesModalAdicionarCarrinho.produto.nomeProduto }}
          </div>
        </div>
        <div class="row">
          <div
            v-if="
              !stateLocal.isEmbedded ||
              stateLocal.propriedadesModalAdicionarCarrinho.produto.productCode
            "
            class="col-2 font-weight-bold"
          >
            SKU:
          </div>
          <div
            v-if="
              !stateLocal.isEmbedded ||
              stateLocal.propriedadesModalAdicionarCarrinho.produto.productCode
            "
            class="col-4"
          >
            {{ stateLocal.propriedadesModalAdicionarCarrinho.produto.productCode }}
          </div>
          <div class="col-2 font-weight-bold">{{ tm("estoque") }}:</div>
          <div class="col-4">
            {{ stateLocal.estoqueSelecionado.quantidadeEstoque }}
          </div>
        </div>
        <div
          class="row"
          v-if="
            !stateLocal.isEmbedded ||
            stateLocal.propriedadesModalAdicionarCarrinho.produto.sortimento
          "
        >
          <div class="col-3 font-weight-bold">
            <label for="idSortimento">{{ tm("sortimento") }}:</label>
          </div>
          <div class="col-5 text-center">
            <select
              id="idSortimento"
              class="form-control form-select form-control-sm"
              v-model="stateLocal.estoqueSelecionado"
            >
              <option
                :selected="stateLocal.estoqueSelecionado"
                v-for="estoque in stateLocal.propriedadesModalAdicionarCarrinho.produto
                  .listaEstoqueSortimento"
                :value="estoque"
                :key="estoque"
              >
                {{ estoque.sortimento }}
              </option>
            </select>
          </div>
        </div>

        <div v-if="stateLocal.estoqueSelecionado.quantidadeEstoque > 0" class="row">
          <div class="col-3 font-weight-bold">
            <label for="idQuantidade">{{ tm("quantidade") }}:</label>
          </div>
          <div class="col-5">
            <input
              id="idQuantidade"
              type="number"
              min="1"
              v-model="stateLocal.estoqueSelecionado.quantidadeSolicitada"
              v-bind:max="stateLocal.estoqueSelecionado.quantidadeEstoque"
              class="form-control form-input form-control-sm text-center"
            />
          </div>
        </div>
        <div v-if="stateLocal.estoqueSelecionado.quantidadeEstoque == 0" class="row">
          <div class="col-3 font-weight-bold">
            <label for="idQuantidade">{{ tm("quantidade") }}:</label>
          </div>
          <div class="col-5">
            <input
              id="idQuantidade"
              type="number"
              min="1"
              v-model="stateLocal.estoqueSelecionado.quantidadeSolicitada"
              v-bind:max="10"
              class="form-control form-input form-control-sm text-center"
            />
          </div>
        </div>
        <div v-if="stateLocal.estoqueSelecionado.quantidadeEstoque == 0" class="row">
          <div class="col-3 font-weight-bold">
            <label for="idValor">{{ tm("valor") }}:</label>
          </div>
          <div class="col-5">
            <input
              id="idValor"
              type="number"
              step="0.01"
              v-model="stateLocal.estoqueSelecionado.produto.precoProduto"
              class="form-control form-input form-control-sm text-center"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          @click="stateLocal.showModalAdicionarCarrinho = false"
        >
          {{ tm("cancelar") }}
        </button>
        <button
          type="button"
          class="btn btn-primary js-btn-step"
          @click="handleAdicionarItemCarrinho"
        >
          {{ tm("adicionar") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { reactive, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import ApiBotService from "../../services/ApiBotService";
import Modal from "../../components/Modal";
import { useI18n } from "vue-i18n";

export default {
  components: { Modal },
  setup(props, context) {
    const { tm } = useI18n();
    const store = useStore();
    const listSortimento = ref([]);
    const sortimentoSelected = ref(0);
    const toast = useToast();

    onMounted(() => {
      /* $("#agrupadorRetorno").scroll(function () {
        if (
          $("#agrupadorRetorno").scrollTop() + $("#agrupadorRetorno").innerHeight() >=
          $("#agrupadorRetorno")[0].scrollHeight - 100
        ) {
          buscarItensAdicionais();
        }
      });*/
    });

    const stateLocal = reactive({
      termoBusca: "",
      semEstoque: sessionStorage.getItem("isEmbedded")
        ? JSON.parse(sessionStorage.getItem("isEmbedded"))
        : false,
      sortimentoSelecionado: -1,
      listaProdutos: [],
      isLoading: false,
      listaSortimento: null,
      showModalAdicionarCarrinho: false,
      showModalImagem: false,
      showModalEstoque: false,
      propriedadesModalAdicionarCarrinho: null,
      propriedadesModalImagem: null,
      propriedadesModalEstoque: null,
      estoqueSelecionado: null,
      paginaBusca: 0,
      paginaFinal: 0,
      totalRegistrosBusca: 0,
      produtosBusca: [],
      isEmbedded: sessionStorage.getItem("isEmbedded")
        ? JSON.parse(sessionStorage.getItem("isEmbedded"))
        : false,
    });

    const produtos = computed(() => {
      return store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId];
    });

    const lojasCliente = computed(() => {
      return store.state.ath.lojasCliente[store.state.selectedChatQueue.chatId];
    });

    const sortimentoBusca = computed(() => {
      if (stateLocal.produtosBusca === undefined) return [];
      const listaRetorno = [
        ...new Set(stateLocal.produtosBusca.map((item) => item.sortimento)),
      ].sort();
      if (listaRetorno.length == 1 && listaRetorno[0] == null) return [];
      return listaRetorno;
    });

    const idClient = computed(() => {
      return store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId] &&
        store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
          .codClienteZappag
        ? store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
            .codClienteZappag
        : store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId]
        ? store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].idClient
        : -1;
    });

    async function handleCarregarModalAdicionarItemCarrinho(produto) {
      if (stateLocal.isEmbedded && (!idClient.value || idClient.value == -1)) {
        toast.warning(tm("msgAth05"));
        return;
      }
      stateLocal.propriedadesModalAdicionarCarrinho = {};
      stateLocal.propriedadesModalAdicionarCarrinho.produto = produto;
      stateLocal.propriedadesModalAdicionarCarrinho.produto.listaEstoqueSortimento = [{}];

      var listaEstoqueSortimento = [];
      var estoqueSortimento = {};
      stateLocal.produtosBusca.forEach(function (produtoInterno) {
        //console.log(produtoInterno);
        if (
          (!stateLocal.isEmbedded && produtoInterno.productCode == produto.productCode) ||
          (stateLocal.isEmbedded && produtoInterno.idProduct == produto.idProduct)
        ) {
          estoqueSortimento = {};
          estoqueSortimento.quantidadeEstoque = produtoInterno.quantityProductStock;
          estoqueSortimento.sortimento = produtoInterno.sortimento;
          estoqueSortimento.quantidadeSolicitada = 0;
          estoqueSortimento.produto = produto;
          listaEstoqueSortimento.push(estoqueSortimento);
          if (produtoInterno.sortimento == produto.sortimento) {
            stateLocal.estoqueSelecionado = estoqueSortimento;
          }
        }
      });

      stateLocal.propriedadesModalAdicionarCarrinho.produto.listaEstoqueSortimento = listaEstoqueSortimento;
      stateLocal.showModalAdicionarCarrinho = true;
    }

    async function handleCompartilharDescricao(produto) {
      context.emit("enviarDescricaoProduto", produto);
    }

    async function buscarItensAdicionais() {
      if (
        !stateLocal.isLoading &&
        stateLocal.totalRegistrosBusca > stateLocal.produtosBusca.length &&
        stateLocal.sortimentoSelecionado == -1
      ) {
        stateLocal.isLoading = true;
        stateLocal.paginaBusca += 1;

        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        let idChannel = 2;
        let param = {
          q: stateLocal.termoBusca,
          show: 30,
          validaEstoque: !stateLocal.semEstoque,
          page: stateLocal.paginaBusca,
          idChannel: 2,
        };

        await realizarBuscaProdutoApi(token, idLoja, param);
        stateLocal.isLoading = false;
      }
    }

    async function buscarItensAdicionaisFrenteUltimo() {
      if (
        !stateLocal.isLoading &&
        stateLocal.totalRegistrosBusca > stateLocal.produtosBusca.length &&
        stateLocal.sortimentoSelecionado == -1
      ) {
        stateLocal.isLoading = true;
        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        stateLocal.paginaBusca = stateLocal.paginaFinal;
        let param = {
          q: stateLocal.termoBusca,
          show: stateLocal.restoLista,
          validaEstoque: !stateLocal.semEstoque,
          page: stateLocal.paginaBusca,
          idChannel: 2,
        };
        stateLocal.produtosBusca = [];
        await realizarBuscaProdutoApi(token, idLoja, param);
        stateLocal.isLoading = false;
      }
    }

    async function buscarItensAdicionaisFrente() {
      if (
        !stateLocal.isLoading &&
        stateLocal.totalRegistrosBusca > stateLocal.produtosBusca.length &&
        stateLocal.sortimentoSelecionado == -1
      ) {
        stateLocal.isLoading = true;
        stateLocal.paginaBusca += 1;

        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        let param = {
          q: stateLocal.termoBusca,
          show: 30,
          validaEstoque: !stateLocal.semEstoque,
          page: stateLocal.paginaBusca,
          idChannel: 2,
        };
        stateLocal.produtosBusca = [];
        await realizarBuscaProdutoApi(token, idLoja, param);
        stateLocal.isLoading = false;
      }
    }

    async function buscarItensAdicionaisVoltaPrimeiro() {
      if (
        !stateLocal.isLoading &&
        stateLocal.totalRegistrosBusca > stateLocal.produtosBusca.length &&
        stateLocal.sortimentoSelecionado == -1
      ) {
        stateLocal.isLoading = true;
        stateLocal.paginaBusca = 1;

        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        let param = {
          q: stateLocal.termoBusca,
          show: 30,
          validaEstoque: !stateLocal.semEstoque,
          page: stateLocal.paginaBusca,
          idChannel: 2,
        };
        stateLocal.produtosBusca = [];
        await realizarBuscaProdutoApi(token, idLoja, param);
        stateLocal.isLoading = false;
      }
    }

    async function buscarItensAdicionaisVolta() {
      if (
        !stateLocal.isLoading &&
        stateLocal.totalRegistrosBusca > stateLocal.produtosBusca.length &&
        stateLocal.sortimentoSelecionado == -1
      ) {
        stateLocal.isLoading = true;
        stateLocal.paginaBusca -= 1;

        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        let param = {
          q: stateLocal.termoBusca,
          show: 30,
          validaEstoque: !stateLocal.semEstoque,
          page: stateLocal.paginaBusca,
          idChannel: 2,
        };
        stateLocal.produtosBusca = [];
        await realizarBuscaProdutoApi(token, idLoja, param);
        stateLocal.isLoading = false;
      }
    }

    async function realizarBuscaProdutoApi(token, idLoja, param) {
      let produto = {};
      const response = await ApiBotService.GetProductsByQuery(token, idLoja, param);
      stateLocal.totalRegistrosBusca = response.data.list_Results_Total_Count;
      stateLocal.restoLista = stateLocal.totalRegistrosBusca % 30;
      stateLocal.paginaFinal =
        (stateLocal.totalRegistrosBusca - stateLocal.restoLista) / 30 + 1;
      if (response.data.list_Results_Total_Count === 0) {
        return;
      }
      for (const produtoRetornado of response.data.product_Store_Search) {
        if (produtoRetornado.valor_Anterior_num > produtoRetornado.valor_Produto_num) {
          produto = {
            idProduct: produtoRetornado.cod_Produto_int,
            productCode: produtoRetornado.cod_Produto_Lojista_var,
            nomeProduto: produtoRetornado.nome_Produto_var,
            precoAntigo: produtoRetornado.valor_Anterior_num,
            precoProduto: produtoRetornado.valor_Produto_num,
            quantityProductStock: produtoRetornado.qtd_Produto_Estoque_int,
            sortimento: produtoRetornado.desc_SubItem_Produto_var,
            imagemProduto: produtoRetornado.urL_Imagem_var,
            visivel: true,
            key:
              produtoRetornado.cod_Produto_int +
              "|" +
              produtoRetornado.nome_Produto_var +
              "|" +
              produtoRetornado.desc_SubItem_Produto_var,
          };
        } else {
          produto = {
            idProduct: produtoRetornado.cod_Produto_int,
            productCode: produtoRetornado.cod_Produto_Lojista_var,
            nomeProduto: produtoRetornado.nome_Produto_var,
            precoProduto: produtoRetornado.valor_Produto_num,
            quantityProductStock: produtoRetornado.qtd_Produto_Estoque_int,
            sortimento: produtoRetornado.desc_SubItem_Produto_var,
            imagemProduto: produtoRetornado.urL_Imagem_var,
            visivel: true,
            key:
              produtoRetornado.cod_Produto_int +
              "|" +
              produtoRetornado.nome_Produto_var +
              "|" +
              produtoRetornado.desc_SubItem_Produto_var,
          };
        }

        if (
          produto.sortimento != "" &&
          produto.sortimento != null &&
          !stateLocal.listaSortimento.includes(produto.sortimento)
        ) {
          stateLocal.listaSortimento.push(produto.sortimento);
        }
        if (
          !stateLocal.isEmbedded &&
          !stateLocal.produtosBusca.some(function (prodItem) {
            return (
              prodItem.productCode === produto.productCode &&
              prodItem.sortimento === produto.sortimento
            );
          })
        ) {
          stateLocal.produtosBusca.push(produto);
        }
        if (stateLocal.isEmbedded) {
          stateLocal.produtosBusca.push(produto);
        }
      }
    }

    async function togglesss() {
      if (this.checked) {
        stateLocal.semEstoque = this.checked;
      } else {
        stateLocal.semEstoque = !this.checked;
      }
      this.checked = !this.checked;
    }

    async function buscarProdutoNovo() {
      try {
        stateLocal.paginaBusca = 1;
        if (stateLocal.termoBusca.length < 3) {
          toast.warning(tm("msgAth22"));
          return;
        }

        stateLocal.isLoading = true;
        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        stateLocal.produtosBusca = [];
        stateLocal.listaSortimento = [];
        let termoBuscaAjustado = "%" + stateLocal.termoBusca.replace(" ", "%") + "%";
        termoBuscaAjustado = termoBuscaAjustado.trim();
        let param = {
          q: termoBuscaAjustado,
          validaEstoque: !stateLocal.semEstoque,
          show: 30,
          page: 1,
          idChannel: 2,
        };

        await realizarBuscaProdutoApi(token, idLoja, param);
        if (stateLocal.produtosBusca.length == 0) {
          toast.warning(tm("msgAth23"));
        }
      } catch (error) {
        console.error(error);
        if (error.satus === 401) {
          toast.warning(tm("msgAth12"));
        }
      } finally {
        stateLocal.isLoading = false;
      }
    }

    async function handleCarregarModalEstoque(produto) {
      stateLocal.isLoading = true;
      let chatId = store.state.selectedChatQueue.chatId;
      let token = store.state.ath.apiBotHub[chatId].token;
      let idLojaSelecionada = store.state.ath.selectedLoja[chatId];
      let listaEstoqueLoja = [];
      let estoqueLoja = {};
      let produtoDetalhado;
      let idProduct = produto.idProduct;

      stateLocal.propriedadesModalEstoque = {};
      stateLocal.propriedadesModalEstoque.produto = produto;
      for (const loja of store.state.ath.lojasCliente[chatId]) {
        if (loja.restaurantId == idLojaSelecionada) {
          continue;
        }
        let response = await ApiBotService.GetProductDetails(
          token,
          loja.restaurantId,
          idProduct
        );
        estoqueLoja = {};
        produtoDetalhado = {};
        estoqueLoja.loja = loja;
        if (response.status == 200) {
          for (const produtoInterno of response.data.productSubItems) {
            if (produto.sortimento == produtoInterno.productSubItemName) {
              produtoDetalhado = produtoInterno;
              break;
            }
          }
          estoqueLoja.estoque = produtoDetalhado.quantitySubItemStock;
        } else {
          let item = {};
          item.id = idProduct;
          estoqueLoja.estoque = await store.dispatch("ath/getEstoque", {
            produto: item,
            chatId: chatId,
            idLoja: loja.restaurantId,
          });
        }

        if (estoqueLoja.estoque > 0) {
          listaEstoqueLoja.push(estoqueLoja);
        }
      }
      stateLocal.propriedadesModalEstoque.listaEstoqueLoja = listaEstoqueLoja;

      stateLocal.showModalEstoque = true;
      stateLocal.isLoading = false;
    }

    function handleCarregarModalImagem(produto) {
      stateLocal.propriedadesModalImagem = {};
      stateLocal.propriedadesModalImagem.produto = produto;
      stateLocal.showModalImagem = true;
    }

    async function handleAdicionarItemCarrinho() {
      const chatId = store.state.selectedChatQueue.chatId;
      const token = store.state.ath.apiBotHub[chatId].token;
      const idLojaSelecionada = store.state.ath.selectedLoja[chatId];
      if (stateLocal.estoqueSelecionado.quantidadeEstoque > 0) {
        if (stateLocal.estoqueSelecionado.quantidadeSolicitada < 1) {
          toast.warning(tm("msgAth07"));
        } else if (
          stateLocal.estoqueSelecionado.quantidadeEstoque <
          stateLocal.estoqueSelecionado.quantidadeSolicitada
        ) {
          toast.warning(
            tm("msgAth08") +
              stateLocal.estoqueSelecionado.quantidadeEstoque +
              tm("msgAth09")
          );
        } else {
          try {
            stateLocal.isLoading = true;
            let response = await ApiBotService.GetProductDetails(
              token,
              idLojaSelecionada,
              stateLocal.estoqueSelecionado.produto.idProduct
            );

            let sortementoItem = null;
            if (response.data != "") {
              let listaSubitem = response.data.productSubItems.map(function (e) {
                if (
                  stateLocal.estoqueSelecionado.sortimento == e.productSubItemDescription
                ) {
                  sortementoItem = e;
                }
              });
            }

            let produtoAdicionar = {
              id: stateLocal.estoqueSelecionado.produto.idProduct,
              sku: stateLocal.estoqueSelecionado.produto.productCode,
              desc: stateLocal.estoqueSelecionado.produto.nomeProduto,
              valor: stateLocal.estoqueSelecionado.produto.precoProduto,
              estoque: stateLocal.estoqueSelecionado.produto.quantityProductStock,
              qtd: stateLocal.estoqueSelecionado.quantidadeSolicitada,
              idItem: 0,
              idSubItem: 0,
              desconto: 0,
              sortimento: sortementoItem,
            };

            if (response.status === 200) {
              for (const produtoInterno of response.data.productSubItems) {
                if (
                  stateLocal.estoqueSelecionado.sortimento ==
                  produtoInterno.productSubItemDescription
                ) {
                  (produtoAdicionar.idItem = produtoInterno.idProductItem),
                    (produtoAdicionar.idSubItem = produtoInterno.idProductSubItem);
                  break;
                }
              }
            }

            await store.dispatch("ath/addProdutoCarrinho", {
              produto: produtoAdicionar,
              chatId: chatId,
              preOrder: true,
            });
            toast.info(tm("msgAth10"));

            stateLocal.showModalAdicionarCarrinho = false;
          } catch (erro) {
            if (erro.status === 403) {
              toast.warning(tm("msgAth11"));
            } else if (erro.status === 401) {
              toast.warning(tm("msgAth12"));
            } else {
              //console.log("produtcViewError", erro);
              toast.error(tm("msgAth13"));
            }
          } finally {
            stateLocal.isLoading = false;
          }
        }
      } else {
        try {
          stateLocal.isLoading = true;
          let response = await ApiBotService.GetProductDetails(
            token,
            idLojaSelecionada,
            stateLocal.estoqueSelecionado.produto.idProduct
          );

          if (stateLocal.estoqueSelecionado.quantidadeSolicitada == 0) {
            stateLocal.estoqueSelecionado.quantidadeSolicitada = 1;
          }
          let produtoAdicionar = {
            id: stateLocal.estoqueSelecionado.produto.idProduct,
            sku: stateLocal.estoqueSelecionado.produto.productCode,
            desc: stateLocal.estoqueSelecionado.produto.nomeProduto,
            valor: stateLocal.estoqueSelecionado.produto.precoProduto,
            estoque: stateLocal.estoqueSelecionado.produto.quantityProductStock,
            qtd: stateLocal.estoqueSelecionado.quantidadeSolicitada,
            idItem: 0,
            idSubItem: 0,
            desconto: 0,
          };
          if (response.status === 200) {
            for (const produtoInterno of response.data.productSubItems) {
              if (
                stateLocal.estoqueSelecionado.sortimento ==
                produtoInterno.productSubItemDescription
              ) {
                (produtoAdicionar.idItem = produtoInterno.idProductItem),
                  (produtoAdicionar.idSubItem = produtoInterno.idProductSubItem);
                break;
              }
            }
          }

          await store.dispatch("ath/addProdutoCarrinho", {
            produto: produtoAdicionar,
            chatId: chatId,
            preOrder: true,
          });

          toast.info(tm("msgAth10"));
          stateLocal.showModalAdicionarCarrinho = false;
        } catch (erro) {
          if (erro.status === 403) {
            toast.warning(tm("msgAth11"));
          } else if (erro.status === 401) {
            toast.warning(tm("msgAth12"));
          } else {
            //console.log("produtcViewError", erro);
            toast.error(tm("msgAth13"));
          }
        } finally {
          stateLocal.isLoading = false;
        }
      }
    }

    function handleFiltrarSortimento(valor) {
      for (var indice = 0; indice < stateLocal.produtosBusca.length; indice++) {
        if (valor == "-1" || stateLocal.produtosBusca[indice].sortimento == valor) {
          stateLocal.produtosBusca[indice].visivel = true;
        } else {
          stateLocal.produtosBusca[indice].visivel = false;
        }
      }
    }

    function handleTrocarLoja(loja) {
      if (
        store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId] == undefined
      ) {
        toast.warning(tm("msgAth24"));
        return;
      }
      context.emit("trocaLoja", loja);
    }

    return {
      produtos,
      handleFiltrarSortimento,
      listSortimento,
      sortimentoSelected,
      stateLocal,
      sortimentoBusca,
      handleCompartilharDescricao,
      handleCarregarModalAdicionarItemCarrinho,
      handleAdicionarItemCarrinho,
      handleCarregarModalImagem,
      handleCarregarModalEstoque,
      handleTrocarLoja,
      lojasCliente,
      buscarProdutoNovo,
      buscarItensAdicionais,
      buscarItensAdicionaisVolta,
      buscarItensAdicionaisFrente,
      buscarItensAdicionaisFrenteUltimo,
      buscarItensAdicionaisVoltaPrimeiro,
      realizarBuscaProdutoApi,
      tm,
    };
  },
};
</script>
