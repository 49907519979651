<template>
  <div class="header-ticket card">
    <div class="card-body p-0 my-1">
      <div class="row p-0 ml-1 mb-0">
        <div v-if="tipo" class="col-12 col-md-6 form-group m-0 pb-0">
          <h6 class="my-1">
            {{ tm("ticket") }}: <b>{{ tipo }}</b>
          </h6>
        </div>
        <div v-if="false" class="col-12 col-md-6 form-group m-0">
          <h6 class="my-1">
            {{ tm("numeroPedido") }} <b>{{ pedido }}</b>
          </h6>
        </div>
      </div>

      <div v-for="index in ticketDataList.length" :key="index" class="row p-0 ml-1 mb-0">
        <div class="col-12 col-md-6 form-group m-0" v-if="index % 2 !== 0">
          <h6 class="my-1" style="overflow-x: auto; width: 90%">
            {{ ticketDataList[index - 1].label }}
            <b>{{
              ticketDataList[index - 1].type == 5
                ? formataMoeda(ticketDataList[index - 1].value)
                : ticketDataList[index - 1].value
            }}</b>
          </h6>
        </div>
        <div
          class="col-12 col-md-6 form-group my-0"
          v-if="index % 2 !== 0 && index < ticketDataList.length"
        >
          <h6 class="my-1" style="overflow-x: auto; width: 90%">
            {{ ticketDataList[index].label }}
            <b>{{
              ticketDataList[index].type == 5
                ? formataMoeda(ticketDataList[index].value)
                : ticketDataList[index].value
            }}</b>
          </h6>
        </div>
      </div>
      <div class="row p-0 ml-1 mb-0">
        <div class="col-3">
          <select
            class="form-control form-select"
            @change="viewBtnAtt($event.target.value)"
          >
            <option value="-1" selected>--</option>
            <option v-for="status in listaStatus" :value="status" :key="status">
              {{ status }}
            </option>
          </select>
        </div>
        <button
          type="button"
          :disabled="isDisabled"
          class="btn btn-primary js-btn-step"
          @click="UpdateStage"
        >
          Atualizar
        </button>
      </div>
      <div class="my-1 row pl-2 ml-1 mb-0" v-if="!isDisabled">
        <input
          type="text"
          :placeholder="tm('obs')"
          class="form-control col-3"
          v-model="obsMudanca"
        />
        &nbsp;
        <input
          type="text"
          class="form-control col-3"
          :placeholder="tm('protocolo')"
          v-model="protocoloPedido"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import Validar from "../util/Validadores";
import ChatService from "../services/ChatService";
import { number } from "@intlify/core-base";
import store from "../store";
import { useStore } from "vuex";
export default {
  props: {
    ticket: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  setup(props) {
    const { tm, locale } = useI18n();
    const store = useStore();
    const tipo = computed(() => {
      if (props.ticket == null) {
        return "";
      } else {
        if (props.ticket.customVariables !== undefined) {
          return props.ticket.customVariables["@tipoMsg"]
            ? props.ticket.customVariables["@tipoMsg"]
            : "";
        } else {
          return "";
        }
      }
    });

    const obsMudanca = ref("");
    const protocoloPedido = ref("");

    const pedido = computed(() => {
      return props.tickets.customVariables["orderid"]
        ? props.ticket.customVariables["orderid"]
        : "";
    });
    const ticketDataList = computed(() => {
      return props.ticket.ticket && props.ticket.ticket.ticketDataList
        ? props.ticket.ticket.ticketDataList
        : [];
    });

    const isDisabled = ref(true);
    const statusReclamacao = ref("");

    function viewBtnAtt(e) {
      if (e === "-1") {
        isDisabled.value = true;
      } else {
        isDisabled.value = false;
      }
      statusReclamacao.value = e;
    }

    function formataMoeda(value) {
      return Validar.formataMoeda(
        value,
        Validar.getFormatoMoeda(locale.value),
        Validar.getCurrencyMoeda(locale.value)
      );
    }
    const listaStatus = computed(() => {
      const listaRetorno = ["Pendente"].sort();
      if (listaRetorno.length == 1 && listaRetorno[0] == null) return [];
      return listaRetorno;
    });

    function UpdateStage() {
      props.ticket.customVariables["@motivo"] = statusReclamacao.value;
      props.ticket.customVariables["@obsMUdanca"] = obsMudanca;
      props.ticket.customVariables["@protocoloPedido"] = protocoloPedido;
      const response = ChatService.UpdateCustomVars(
        props.ticket.chatUUID,
        props.ticket.customVariables
      );
    }

    return {
      tm,
      tipo,
      pedido,
      ticketDataList,
      formataMoeda,
      UpdateStage,
      listaStatus,
      isDisabled,
      viewBtnAtt,
      obsMudanca,
      protocoloPedido,
    };
  },
};
</script>

<style>
.ow {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
</style>
