﻿export const environment = {
    apiUrl: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_URL,
    apiBot: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_BOT_URL,
    apiWebhook: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_WEBHOOK,
    apiCheckoutVtex: process.env.VUE_APP_VTEX_CHECKOUT_URL,
    apiOms: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_OMSHUB_URL,
    apiClient: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_CLIENT_URL,
    apiMarketplace: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_MARKETPLACE_URL,
    apiMessenger: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_MESSENGER_URL,
    apiInstagram: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_INSTAGRAM_URL,
};