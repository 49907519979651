import "intro.js/introjs.css";
import moment from "moment";
import CKEditor from '@ckeditor/ckeditor5-vue';
import { createApp } from "vue";
import VueJwtDecode from 'vue-jwt-decode';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import App from "./App.vue";
import router from "./router";
import UsuarioService from "./services/UsuarioService";
import store from "./store";
import Maska from 'maska'
import { createI18n } from 'vue-i18n'
import FlagIcon from 'vue-flag-icon'
import VueTagsInput from '@sipec/vue3-tags-input';

const DEFAULT_LANG = process.env.VUE_APP_LANG ? process.env.VUE_APP_LANG : "pt";
sessionStorage.setItem("lang", DEFAULT_LANG);

const locales = {
  en: require("./lang/i18n/en.json"),
  es: require("./lang/i18n/es.json"),
  pt: require("./lang/i18n/pt.json")
};

const i18n = createI18n({
  legacy: false,
  locale: DEFAULT_LANG,
  fallbackLocale: 'pt' ,
  messages:locales,
})


const options = {
  showCloseButtonOnHover: true,
  transition: "Vue-Toastification__fade",
  maxToasts: 7,
  transitionDuration: 400,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length !== 0) {
      return false;
    }
    return toast;
  }
};

createApp(App)
  .use(store)
  .use(router)
  .use(CKEditor)
  .use(Toast, options)
  .use(Maska)
  .use(i18n)
  .use(FlagIcon)
  .use(VueTagsInput)
  .mount("#app");

// verificar Token Expirado/valido
// setInterval(() => {
//  const token = localStorage.getItem("token");
//  const user =  JSON.parse(localStorage.getItem("usuario"));
//  if (token) {
      //const tokenParsed = VueJwtDecode.decode(token);
     // const dataExp = new Date(tokenParsed.exp * 1000);
     // const expirado = moment().isAfter(moment(dataExp))
 //     UsuarioService.VerificaToken(user.id, token)
 //     .then(res => {
      // if (expirado) {
      //   localStorage.removeItem("token");
      //   localStorage.removeItem("usuario");
      //   store.dispatch('clearStore');
      //   $('.modal').modal('hide');
      //   router.push("Login");
      // }
 //   })
  //  .catch((err) => {
//    })

 // }
//}, 15000)
