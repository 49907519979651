import ClienteService from '../../services/ClienteService';

const state = {
    dadosCliente: [],
    buscaCliente: {},
    resultBuscaClientes: [],
}
const mutations = {
    setDadosCliente(state, dados) {
        //console.log(state, dados)
        state.dadosCliente = dados
    },
    setBuscaCliente(state, busca) {
        state.buscaCliente = busca
    },
    setResultBuscaCliente(state, rBusca) {
        state.resultBuscaClientes = rBusca
    },
    setClienteSelected(state, { chatId, dados }) {
        state.dadosCliente[chatId] = dados
    },
}

const actions = {

    async getDadosCliente(context) {
        let chatId = context.rootState.selectedChatQueue ? context.rootState.selectedChatQueue.chatId : null
        let channel = context.rootState.selectedChatQueue.channel
        let empresa = context.rootState.usuario.empresaId
        if (chatId) {
            try {
                //console.log('teste')
                const response = await ClienteService.getClienteByChannel(empresa, chatId, channel)
                    //console.log(response)
                    //console.log("3333")
                if (response.status == 200) {
                    context.state.dadosCliente[chatId] = response.data;
                }
            } catch (error) {
                console.debug(error);
            }
        }
    },

    async GetClienteByNome(context) {
        if (context.state.buscaCliente.nome) {
            return ClienteService.GetClienteByNome(context.state.buscaCliente.nome)
                .then(response => {
                    context.state.resultBuscaClientes = response.data;
                }).catch(error => {
                    console.debug(error);
                });
        }
    },
    async GetClienteByCpf(context) {
        if (context.state.buscaCliente.cpf) {
            return ClienteService.GetClienteByCpf(context.state.buscaCliente.cpf)
                .then(response => {
                    if (response.data)
                        context.state.resultBuscaClientes = [response.data];
                    else
                        context.state.resultBuscaClientes = [];
                }).catch(error => {
                    console.debug(error);
                });
        }
    },
    async GetClienteById(context, idCliente) {
        if (idCliente) {
            return await ClienteService.GetClienteById(idCliente)
                .then(response => {
                    let chatId = context.rootState.selectedChatQueue.chatId
                    context.state.dadosCliente[chatId] = response.data;
                    return response.data;
                }).catch(error => {
                    console.debug(error);
                });
        }
    },
    async SalvarCanal(context, canal) {
        if (canal && canal.id) {
            return ClienteService.UpdateCanalCliente(canal);
        } else if (canal && !canal.id) {
            return ClienteService.AddCanalCliente(canal);
        }
    },
    async RemoveCanalCliente(context, idCanal) {
        if (idCanal) {
            return ClienteService.RemoveCanalCliente(idCanal);
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
