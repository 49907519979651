<template>
  <ul class="timeline clearfix">
    <li class="timeline-line btn-primary"></li>
    <li class="timeline-item zoom">
      <div class="timeline-badge">
        <img
          class="badge-img"
          alt=""
          :src="require(`@/assets/images/${chatItem.channel}.png`)"
        />
      </div>
      <div class="timeline-card card">
        <div class="contacts-list">
          <div class="contacts-content">
            <div class="row">
              <div class="col-xs-6 col-md-6">
                <div class="chat-name">
                  {{ moment(chatItem.dateStarted).format("DD/MM/yyyy HH:mm") }}
                  <small class="d-md-none">
                    - {{ calcularDiferencaDias }}</small
                  >
                </div>
              </div>
              <div class="col-xs-6 col-md-6">
                <div class="chat-name float-right">
                  <b>{{ tm("cliente") }}:</b> {{ chatItem.chatId }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-6 col-md-6 d-none d-sm-block">
                <div class="chat-name">
                  {{ calcularDiferencaDias }}
                </div>
              </div>
              <div class="col-xs-6 col-md-6">
                <div class="chat-name float-right">
                  <b>{{ tm("atendente") }}:</b> {{ chatItem.nomeAgente }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-9 col-md-9">
                <div class="chat-name">
                  <b>{{ tm("campanha") }}: </b>{{ chatItem.queueId }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="chat-name float-right">
                  <button type="button" class="btn btn-primary btn-sm">
                    {{ tm("visualizar") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { computed } from "@vue/runtime-core";
import differenceInDays from "date-fns/differenceInDays";
import differenceInHours from "date-fns/differenceInHours";
import moment from "moment";
import { useI18n } from "vue-i18n";

export default {
  name: "ChatHistoryItem",
  props: {
    chatItem: {
      type: Object,
    },
  },
  setup(props) {
    const { tm } = useI18n();
    const calcularDiferencaDias = computed(() => {
      let diferenca = differenceInDays(
        new Date(),
        new Date(props.chatItem.dateStarted)
      );

      if (diferenca < 1) {
        diferenca = differenceInHours(
          new Date(),
          new Date(props.chatItem.dateStarted)
        );
        return diferenca + tm("horasAtras");
      }
      return diferenca + tm("diasAtras");
    });

    return {
      moment,
      calcularDiferencaDias,
      tm,
    };
  },
};
</script>

<style scoped>
.card {
  width: 42vw !important;
}

.zoom {
  transition: transform 0.2s; /* Animation */
}

.zoom:hover {
  transform: scale(
    1.03
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

img {
  margin-top: 20px !important;
}

.chat-name {
  font-size: 0.875rem;
  margin-bottom: 5px;
  margin-top: 10px;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }

  .card {
    width: 56vw !important;
  }

  .zoom {
    transition: transform 0.2s; /* Animation */
  }

  .zoom:hover {
    transform: scale(
      1.07
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .chat-name {
    float: left !important;
  }
}
</style>
