<template>
  <div class="historico appnavbar-body">
    <div class="chat-history px-1 pb-1">
      <div class="main-layout">
        <!-- Main Start -->
        <main class="main-new main-visible">
          <!-- Chats Page Start -->
          <div class="chats">
            <div class="chat-body">
              <!-- Chat Header Start-->
              <div class="chat-header">
                <!-- Chat Back Button (Visible only in Small Devices) -->
                <div class="container">
                  <div class="row">
                    <div class="col-sm-6 col-md-5">
                      <div class="input-group">
                        <label class="mb-1 mr-1"
                          >{{ tm("dataInicial") }}:</label
                        >
                        <datepicker
                          inputFormat="dd/MM/yyyy"
                          :locale="langData"
                          typeable
                          class="form-control pl-1 vf-16 pr-0 mr-0"
                          v-model="dataSelect.dataInicio"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text transparent-bg border-left-0"
                          >
                            <!-- Default :: Inline SVG -->
                            <svg
                              class="hw-20 text-muted"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                              />
                            </svg>

                            <!-- Alternate :: External File link -->
                            <!-- <img class="injectable hw-20" src="./../assets/media/heroicons/outline/search.svg" alt="Search icon"> -->
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-5">
                      <div class="input-group">
                        <label class="mb-1 mr-1">{{ tm("dataFinal") }}:</label>
                        <datepicker
                          inputFormat="dd/MM/yyyy"
                          :locale="pt"
                          typeable
                          class="form-control pl-1 vf-16"
                          v-model="dataSelect.dataFinal"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text transparent-bg border-left-0"
                          >
                            <!-- Default :: Inline SVG -->
                            <svg
                              class="hw-20 text-muted"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                              />
                            </svg>
                            <!-- Alternate :: External File link -->
                            <!-- <img class="injectable hw-20" src="./../assets/media/heroicons/outline/search.svg" alt="Search icon"> -->
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="carregarDadosChats()"
                        :disabled="stateLocal.buttonControl"
                      >
                        {{ tm("buscar") }}
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-5">
                      <div class="input-group">
                        <label class="mb-1 mr-1">{{ tm("campanha") }}:</label>
                        <div class="input-group-append">
                          <select
                            class="custom-select font-size-sm shadow-none form-control pl-1 vf-22 pr-0 mr-0"
                            v-model="campanhaSelect"
                          >
                            <option value="">{{
                              tm("selecionarCampanha")
                            }}</option>
                            <option
                              v-for="c in stateLocal.campanhas"
                              :key="c.id"
                              :value="c"
                            >
                              {{ c }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-5">
                      <div class="input-group">
                        <label class="mb-1 mr-1">{{ tm("fluxo") }}:</label>
                        <div class="input-group-append">
                          <select
                            class="custom-select font-size-sm shadow-none form-control pl-1 vf-22 pr-0 mr-0"
                            v-model="fluxoSelect"
                          >
                            <option value="">{{
                              tm("selecionarFluxo")
                            }}</option>
                            <option
                              v-for="f in stateLocal.fluxos"
                              :key="f.id"
                              :value="f.flowUUID"
                            >
                              {{ f.roboNome }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Chat Header End-->

              <!-- Chat Content Start-->
              <div
                class="chat-content scrolling p-new position-relative "
                id="messageBody"
              >
                <div class="container ">
                  <div class="d-flex justify-content-center">
                    <div
                      v-if="stateLocal.isloading"
                      class="spinner-border spinner-border-sm "
                      role="status"
                    ></div>
                  </div>

                  <chat-history-item
                    v-for="(c, i) in stateLocal.chats"
                    :key="i"
                    :chatItem="c"
                    @click="showMessages(c)"
                  />
                  <!-- Icaro Timeline Start -->
                </div>
                <div class="chat-finished" id="chat-finished"></div>
              </div>
              <!-- Chat Content End-->
              <div class="chat-footer"></div>
            </div>
          </div>
          <!-- Chats Page End -->
        </main>
        <!-- Main End -->
      </div>
      <!-- Modal -->
      <modal
        v-if="stateLocal.showModalChatHistoryMessages"
        :title="tm('chatHistorico')"
        @closeModal="stateLocal.showModalChatHistoryMessages = false"
      >
        <template v-slot:body>
          <chat-history-messages :messages="stateLocal.chatMessages" />
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import Datepicker from "vue3-datepicker";
import { pt } from "date-fns/locale";
import ChatHistoryItem from "./chathistoryitem.vue";
import ChatHistoryMessages from "./chathistorymessages.vue";
import Modal from "../../components/Modal";
import differenceInDays from "date-fns/differenceInDays";
import ChatService from "../../services/ChatService";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

const toast = useToast();
export default {
  name: "Historico",
  components: { Datepicker, ChatHistoryItem, ChatHistoryMessages, Modal },
  setup() {
    const { tm, locale } = useI18n();
    const store = useStore();
    const limitDays = 90;
    const campanhaSelect = ref("");
    const fluxoSelect = ref("");
    const dataSelect = reactive({
      dataInicio: ref(new Date().addDays(-limitDays)),
      dataFinal: ref(new Date()),
    });
    const stateLocal = reactive({
      chatMessages: [],
      chats: [],
      buttonControl: false,
      isloading: false,
      showModalChatHistoryMessages: false,
      campanhas: [],
      fluxos: [],
      valorOriginal: [],
    });

    const langData = computed(() => {
      switch (locale.value) {
        case "en":
          return enUS;
        case "es":
          return es;
        case "pt":
          return pt;
        default:
          return pt;
      }
    });

    onMounted(() => {
      // carregarDadosChats();
    });
    //METODOS

    function resetInfo() {
      dataSelect.dataInicio = new Date().addDays(-limitDays);
      dataSelect.dataFinal = new Date();
      stateLocal.chats = [];
    }

    async function showMessages(chatItem) {
      let response = await ChatService.getHistoryByChatUUI(chatItem.chatUUID);
      stateLocal.chatMessages = response.data;
      stateLocal.showModalChatHistoryMessages = true;
    }

    // Watchs
    watch(dataSelect, (novaData) => {
      if (new Date(novaData.dataInicio) > new Date(novaData.dataFinal)) {
        toast.warning(tm("erroPeriodo01"));
        stateLocal.buttonControl = true;
        differenceInDays(
          new Date(dataSelect.dataInicio),
          new Date(dataSelect.dataFinal)
        );
      } else if (
        differenceInDays(
          new Date(dataSelect.dataFinal),
          new Date(dataSelect.dataInicio)
        ) > limitDays
      ) {
        toast.warning(tm("erroPeriodo02") + limitDays + tm("dias") + "!");
        stateLocal.buttonControl = true;
      } else {
        let v = differenceInDays(
          new Date(dataSelect.dataFinal),
          new Date(dataSelect.dataInicio)
        );
        stateLocal.buttonControl = false;
      }
    });

    async function carregarDadosChats() {
      // checar se os dados do cliente já estão carregados
      stateLocal.isloading = true;
      let cliente = null;
      let chatIds = [];
      try {
        if (
          store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
        ) {
          cliente =
            store.state.cliente.dadosCliente[
              store.state.selectedChatQueue.chatId
            ];
        } else {
          await store.dispatch("cliente/getDadosCliente");
          cliente =
            store.state.cliente.dadosCliente[
              store.state.selectedChatQueue.chatId
            ];
        }
        if (cliente) {
          // capturar canais de atendimento
          chatIds = cliente.crmCanalCliente.map((element) => {
            return element.valor;
          });
        } else {
          // cliente não cadastrado
          chatIds.push(store.state.selectedChatQueue.chatId);
        }
        let response = await ChatService.GetAllChatsFromDataChatIds(
          dataSelect.dataInicio.getTime(),
          dataSelect.dataFinal.getTime(),
          chatIds,
          store.state.selectedChatQueue.empresaId
        );
        stateLocal.chats = response.data;
        stateLocal.valorOriginal = response.data;
        stateLocal.campanhas = [
          ...new Set(response.data.map((x) => x.queueId)),
        ];
        const revArray = response.data.reverse();
        stateLocal.fluxos = [
          ...new Set(revArray.map((x) => x.flowUUID)),
        ].map((r) => revArray.reverse().find((v) => v.flowUUID === r));
      } catch (e) {
        //console.log("Historico", e);
      } finally {
        stateLocal.isloading = false;
      }
    }

    return {
      onMounted,
      stateLocal,
      pt,
      carregarDadosChats,
      dataSelect,
      resetInfo,
      showMessages,
      tm,
      langData,
      campanhaSelect,
      fluxoSelect,
    };
  },
  watch: {
    campanhaSelect: function(newVal, oldVal) {
      if (newVal != "") {
        this.stateLocal.chats = this.stateLocal.valorOriginal.filter(
          (x) =>
            x.queueId == newVal &&
            (this.fluxoSelect != ""
              ? x.flowUUID == this.fluxoSelect
              : x.flowUUID != null)
        );
      } else {
        this.stateLocal.chats = this.stateLocal.valorOriginal.filter((x) =>
          this.fluxoSelect != ""
            ? x.flowUUID == this.fluxoSelect
            : x.flowUUID != null
        );
      }
    },
    fluxoSelect: function(newVal, oldVal) {
      if (newVal != "") {
        this.stateLocal.chats = this.stateLocal.valorOriginal.filter(
          (x) =>
            x.flowUUID == newVal &&
            (this.campanhaSelect != ""
              ? x.queueId == this.campanhaSelect
              : x.queueId != null)
        );
      } else {
        this.stateLocal.chats = this.stateLocal.valorOriginal.filter((x) =>
          this.campanhaSelect != ""
            ? x.queueId == this.campanhaSelect
            : x.queueId != null
        );
      }
    },
  },
};
</script>

<style scoped>
.spinner-border-sm {
  align-content: center;
}
.p-new {
  padding: 0.75rem 1.75rem 0.75rem 1.75rem !important;
}

.main-new {
  height: 92vh;
}

.vf-20 {
  width: 20vh !important;
}

[class*="col-"] {
  float: left;
  padding: 5px;
}

.spinner-border-sm {
  width: 3rem;
  height: 3rem;
  border-width: 0.4em;
}

.vf-20 {
  width: 20vh !important;
}

.input-group {
  width: 140% !important;
}

.tamanho {
  width: 50% !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }

  .vw-60 {
    width: 50vw !important;
  }
}
</style>
