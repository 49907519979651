<template>
  <div class="chat-body main-new">
    <div class="chat-header position-relative justify-content-center">
      <form id="searchForm" @submit.prevent="searchInMessage">
        <div class="input-group">
          <input
            type="text"
            id="searchItem"
            class="form-control"
            :placeholder="tm('msgHistorico')"
          />
          <div class="input-group-append">
            <span
              class="input-group-text transparent-bg border-left-0"
              @click="searchInMessage()"
            >
              <!-- Default :: Inline SVG -->
              <svg
                class="hw-20 text-muted"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>

              <!-- Alternate :: External File link -->
              <!-- <img class="injectable hw-20" src="./../assets/media/heroicons/outline/search.svg" alt="Search icon"> -->
            </span>
          </div>
        </div>
        <span v-if="itemsFound > -1" class="small float-right">
          {{ ` ${itemFind} / ${itemsFound}` }}</span
        >
      </form>
    </div>
    <div class="chat-content scrolling position-relative" id="messageBody">
      <div class="container">
        <!-- Message Day Start -->
        <div class="message-day">
          <!-- Received Message Start -->
          <div
            v-for="message in messages"
            :key="message.internalId"
            :class="message.incoming ? 'chm message' : 'chm message self'"
            :id="message.internalId"
          >
            <div class="message-wrapper">
              <div
                v-if="Validar.isText(message.message)"
                class="message-content"
              >
                <span
                  style="position: relative; overflow-wrap: break-word"
                  v-html="message.message"
                ></span>
              </div>
              <div
                v-if="Validar.isImage(message.message)"
                class="message-content"
              >
                <a
                  target="_blank"
                  :href="Validar.returnImageURL(message.message)"
                >
                  <img
                    :src="Validar.returnImageURL(message.message)"
                    alt=""
                    style="
                      height: 50px !important;
                      width: 50px !important;
                      max-height: 50px !important;
                      max-width: 50px !important;
                    "
                  />
                </a>
                <br /><span
                  v-html="Validar.returnImageText(message.message)"
                ></span>
              </div>
              <div
                v-if="Validar.isDocument(message.message)"
                class="message-content"
              >
                <a target="_blank" :href="Validar.returnURL(message.message)">
                  <img :src="require(`@/assets/images/file.png`)" alt="" />
                </a>
                <br /><span
                  v-html="Validar.returnImageText(message.message)"
                ></span>
              </div>
              <div
                v-if="
                  Validar.isAudio(message.message) ||
                  Validar.isVoice(message.message)
                "
                class="message-content"
              >
                <audio
                  controls="x"
                  :src="Validar.returnURL(message.message)"
                  style="max-width: 90%"
                >
                  Your browser does not support the audio element.
                </audio>
              </div>
              <div
                v-if="Validar.isVideo(message.message)"
                class="message-content"
              >
                <video
                  controls="x"
                  :src="Validar.returnURL(message.message)"
                  style="max-width: 90%"
                >
                  Your browser does not support the video element.
                </video>
              </div>
            </div>
            <div
              v-if="!Validar.isSystem(message.message)"
              class="message-options"
            >
              <div class="avatar avatar-sm">
                <img
                  v-if="message.incoming"
                  alt=""
                  :src="require(`@/assets/images/${message.channel}.png`)"
                />
                <img
                  v-else
                  alt=""
                  :src="require('@/assets/images/icon-user.png')"
                />
              </div>
              <span v-if="message.timestamp" class="message-date">{{
                moment(message.timestamp)
                  .tz(timezoneDefault)
                  .format("DD/MM/YYYY HH:mm")
              }}</span>
            </div>
          </div>
          <!-- Received Message End -->
        </div>
        <!-- Message Day End -->
      </div>
      <!-- Scroll to finish -->
      <div class="chat-finished" id="chat-finished"></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Validar from "@/util/Validadores";
import { reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
export default {
  name: "ChatHistoryMessages",
  props: ["messages"],
  setup(props) {
    const { tm } = useI18n();
    const timezoneDefault = ref("");
    const store = useStore();
    let itemsFound = ref(-1);
    let itemFind = ref(0);
    let lastTermo = ref("");
    let lastIndex = ref("");

    timezoneDefault.value = store.state.timeZone;

    function searchInMessage() {
      let index = 0;
      let termo = document
        .getElementById("searchForm")
        .elements["searchItem"].value.toLowerCase()
        .trim();

      let divs = document.getElementsByClassName("chm");

      if (termo == lastTermo && termo.trim() != "") {
        index = lastIndex;
      } else {
        //desmarca qualquer texto que tenha sido marcado
        for (let i = 0; i < divs.length; i++) {
          let para = divs[i].getElementsByTagName("span");
          let innerHTML = para[0].innerHTML
            .replace('<span style="background-color: yellow">', "")
            .replace("</span>", "");
          para[0].innerHTML = innerHTML;
        }
        itemFind.value = 0;
        itemsFound.value = -1;
        lastTermo = termo.trim();
        if (termo.trim() == "") {
          return;
        }
      }

      lastTermo = termo;
      let arrFinds = props.messages.filter(
        (x) => x.message.toLowerCase().indexOf(termo) > -1
      );
      itemsFound.value = arrFinds.length;

      for (let i = index; i < itemsFound.value; i++) {
        let element = document
          .getElementById(arrFinds[i].internalId)
          .getElementsByTagName("span")[0];
        let innerHTML = element.innerHTML;
        let textIndex = element.innerText.toLowerCase().indexOf(termo);

        innerHTML =
          innerHTML.substring(0, textIndex) +
          "<span style='background-color: yellow'>" +
          innerHTML.substring(textIndex, textIndex + termo.length) +
          "</span>" +
          innerHTML.substring(textIndex + termo.length);
        element.innerHTML = innerHTML;
        element.scrollIntoView();
        itemFind.value = i + 1;
        lastIndex = i + 1;
        break;
      }
    }

    return {
      moment,
      Validar,
      timezoneDefault,
      searchInMessage,
      lastTermo,
      lastIndex,
      itemsFound,
      itemFind,
      tm,
    };
  },
};
</script>

<style scoped>
.p-new {
  padding: 0.75rem 1.75rem 0.75rem 1.75rem !important;
}

.main-new {
  height: 70vh !important;
}

.highlight {
  background-color: yellow;
}
</style>
