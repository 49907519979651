<template>
  <div class="atendimento px-1 pb-1">
    <div>
      <h6 class="pl-1">{{ nomeLoja }}</h6>
    </div>
    <div v-if="mensagemCampanhaUsuario">
      <h6
        class="pl-1 scrolling"
        style="overflow: auto; height: 6vh; width: 100%; margin-top: 5px"
      >
        {{ mensagemCampanhaUsuario }}
      </h6>
    </div>
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <a
          class="nav-link pl-0 pr-0"
          :class="{ active: store.state.ath.currentOpcao === 'secoes' }"
          href="#"
          @click="setOpcao('secoes')"
        >
          {{ tm("secoes") }}
          <div
            v-if="store.state.ath.isloadingSecoes || store.state.ath.isLoadingConection "
            class="spinner-border spinner-border-sm"
            role="status"
          ></div>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link pl-0 pr-0"
          :class="{ active: store.state.ath.currentOpcao === 'produtos' }"
          href="#"
          @click="setOpcao('produtos')"
        >
          {{ tm("produtos") }}
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link pl-0 pr-0"
          :class="{ active: store.state.ath.currentOpcao === 'carrinho' }"
          href="#"
          @click="setOpcao('carrinho')"
        >
          {{ tm("carrinho") }}
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link pl-0 pr-0"
          :class="{ active: store.state.ath.currentOpcao === 'pedido' }"
          href="#"
          @click="setOpcao('pedido')"
        >
          {{ tm("pedido") }}
        </a>
      </li>
      <li class="nav-item">
        <div class="nav-link pl-4 pr-0">
          <a
            v-if="false"
            class="px-1 py-1"
            href="#"
            :title="tm('vendaSpot')"
            role="button"
          >
            <i class="fas fa-camera fa-2x"></i>
          </a>
          <a
            v-if="!stateLocal.isEmbedded && fonteVtex"
            class="px-1 py-1"
            href="#"
            :title="tm('compartilharCatalogo')"
            role="button"
            @click="enviarCatalogo(null)"
          >
            <i class="fas fa-book-open fa-2x"></i>
          </a>
          <a
            class="px-1 py-1"
            href="#"
            :title="tm('trocarLoja')"
            role="button"
            v-if="!stateLocal.isEmbedded"
            @click="trocaLojaInicio"
          >
            <i class="fas fa-exchange-alt fa-2x"></i>
          </a>
        </div>
      </li>
    </ul>

    <secoes
      :key="componentKeySecoes"
      v-if="store.state.ath.currentOpcao === 'secoes'"
      @exibirModalProdutos="stateLocal.showModalEnviarProduto = true"
      @setPropriedadesModalProduto="setPropriedadesModalProduto"
      @enviarCatalogo="enviarCatalogo"
      @forceRerender="forceRerender"
    />

    <pedido
      v-if="store.state.ath.currentOpcao === 'pedido'"
      @enviarMsgChat="enviarMsgChat"
    />

    <produtos
      v-if="store.state.ath.currentOpcao === 'produtos'"
      @enviarDescricaoProduto="handleEnviarProdutoSelecionado"
      @trocaLoja="trocaLoja"
    />

    <carrinho
      :key="componentKeyCarrinho"
      v-if="store.state.ath.currentOpcao === 'carrinho'"
      @enviarMsgChat="enviarMsgChat"
      @forceRerender="forceRerender"
    />

    <modal
      v-if="stateLocal.showModalEnviarProduto"
      v-bind:title="stateLocal.propriedadesModalProdutos.nomeSecao"
      @closeModal="stateLocal.showModalEnviarProduto = false"
    >
      <template v-slot:body>
        <div
          v-if="stateLocal.propriedadesModalProdutos.listaSortimento != null"
          class="row"
        >
          <div class="col-3">{{ tm("sortimento") }}:</div>
          <div class="col-5">
            <select v-model="sortimentoSelected" class="form-control form-select">
              <option :value="null">-- {{ tm("selecione") }} --</option>
              <option
                v-for="sortimento in stateLocal.propriedadesModalProdutos.listaSortimento"
                :value="sortimento"
                :key="sortimento"
              >
                {{ sortimento }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" style="height: 350px">
          <div id="outerDivWrapper" style="height: 250px; margin-top: 1px" class="col-12">
            <div style="width: 100%">
              <div
                v-if="stateLocal.isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
              ></div>
            </div>
            <div id="scrollableContent" class="scrolling">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <ul
                      v-if="!stateLocal.isLoading"
                      class="list-group"
                      id="lista-produtos-loja"
                    >
                      <template
                        v-for="produto in stateLocal.propriedadesModalProdutos
                          .listaProduto"
                        :key="`${produto}`"
                      >
                        <li
                          @click="handleSelecionarProduto(produto)"
                          v-bind:class="{ active: produto.selecionado }"
                          v-if="produto.visivel"
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <div class="image-parent">
                            <img
                              v-bind:src="produto.imagemProduto"
                              class="img-fluid"
                              v-bind:alt="produto.nomeProduto"
                            />
                          </div>
                          <span class="col-6">
                            {{ produto.nomeProduto }} - {{ produto.sortimento }}
                          </span>
                          <span
                            class="col-3"
                            style="white-space: nowrap; text-align: right"
                          >
                            {{ tm("siglaMoeda") }} {{ produto.precoProduto }}
                          </span>
                          <span>
                            <a
                              class="pr-1 pl-1"
                              href="#"
                              :title="tm('adicionarCarrinho')"
                              role="button"
                              @click="handleCarregarModalAdicionarItemCarrinho(produto)"
                            >
                              <i class="fas fa-cart-plus fa-lg"></i>
                            </a>
                          </span>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div style="width: 65%; float: right; margin-right: 60px" class="row">
              <!--primeiro-->
              <button
                v-if="stateLocal.paginaBusca > 1"
                type="button"
                class="btn btn-primary js-btn-step"
                @click="buscarItensAdicionaisVoltaPrimeiro"
              >
                <div><i class="fas fa-angle-double-left"></i></div>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-primary js-btn-step"
                @click="buscarItensAdicionaisVoltaPrimeiro"
                disabled
              >
                <div><i class="fas fa-angle-double-left"></i></div>
              </button>
              <!--primeiro-->
              <!--1 anterior-->
              <button
                v-if="stateLocal.paginaBusca > 1"
                type="button"
                class="btn btn-primary js-btn-step"
                @click="buscarItensAdicionaisVolta"
              >
                <div>{{ stateLocal.paginaBusca - 1 }}</div>
              </button>
              <button
                v-else-if="stateLocal.paginaBusca > 0"
                type="button"
                class="btn btn-primary js-btn-step"
                @click="buscarItensAdicionaisVolta"
                disabled
              >
                <div>{{ stateLocal.paginaBusca - 1 }}</div>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-primary js-btn-step"
                @click="buscarItensAdicionaisVolta"
                disabled
              >
                <div>-</div>
              </button>
              <!--1 anterior-->
              <!---->
              <button type="button" class="btn btn-primary js-btn-step" disabled>
                <div>{{ stateLocal.paginaBusca }}</div>
              </button>
              <!---->
              <!--1 depois-->
              <button
                v-if="stateLocal.paginaBusca < stateLocal.paginaFinal"
                type="button"
                class="btn btn-primary js-btn-step"
                @click="buscarItensAdicionaisFrente"
              >
                <div>{{ stateLocal.paginaBusca + 1 }}</div>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-primary js-btn-step"
                @click="buscarItensAdicionaisFrente"
                disabled
              >
                <div>-</div>
              </button>
              <!--1 depois-->
              <!-- ultimo-->
              <button
                v-if="stateLocal.paginaBusca < stateLocal.paginaFinal"
                type="button"
                class="btn btn-primary js-btn-step"
                @click="buscarItensAdicionaisFrenteUltimo"
              >
                <i class="fas fa-angle-double-right"></i>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-primary js-btn-step"
                @click="buscarItensAdicionaisFrenteUltimo"
                disabled
              >
                <i class="fas fa-angle-double-right"></i>
              </button>
              <!-- ultimo-->
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          @click="stateLocal.showModalEnviarProduto = false"
        >
          {{ tm("cancelar") }}
        </button>
        <button
          type="button"
          class="btn btn-primary js-btn-step"
          @click="handleEnviarProdutosSelecionados"
        >
          {{ tm("enviarSelecionados") }}
        </button>
        <button
          type="button"
          class="btn btn-primary js-btn-step"
          @click="handleEnviarTodosProdutos"
        >
          {{ tm("enviarTodos") }}
        </button>
      </template>
    </modal>
    <modal
      v-if="stateLocal.showModalTroca"
      :title="tm('trocarLoja')"
      @closeModal="cancelarTroca"
    >
      <template v-slot:body>
        <div v-if="stateLocal.inicioTroca">
          <div class="row">
            <div class="col-12">
              <h6 class="text-truncate">
                <b>{{ tm("msgAth01") }}</b>
              </h6>
            </div>
          </div>
          <ul
            class="list-group border list-group-flush scrolling"
            style="overflow: auto; height: 112px; width: 100%"
          >
            <li
              class="list-group-item py-1"
              v-for="loja in lojasCliente"
              :key="loja.restaurantId"
            >
              <div class="media align-items-center">
                <div class="custom-control custom-switch">
                  <input
                    type="radio"
                    class="custom-control-input"
                    :value="loja"
                    :id="loja.restaurantId"
                    :checked="
                      loja.restaurantId == stateLocal.lojaSelecionada.restaurantId
                    "
                    v-model="stateLocal.lojaSelecionada"
                  />
                  <label class="custom-control-label" :for="loja.restaurantId"
                    >&nbsp;</label
                  >
                </div>
                <div class="media-body">
                  <p class="mb-0">{{ loja.restaurantName }}</p>
                </div>
              </div>
            </li>
          </ul>
          <div class="row" style="margin-top: 5px">
            <div class="col-12 mb-0 input-group rounded">
              <label for="idCepBusca">{{ tm("buscar") }}:&nbsp;</label>
              <input
                id="idCepBusca"
                type="text"
                :placeholder="tm('erroCliente06')"
                v-model.trim="stateLocal.cepBusca"
                maxlength="9"
              />
              <span
                @click="buscarLoja"
                :disabled="stateLocal.isLoadingTroca"
                class="border-0 btn btn-primary"
                id="busca-addon"
              >
                <i class="fas fa-search"></i>
              </span>
            </div>
          </div>
          <ul
            class="list-group border list-group-flush scrolling"
            style="overflow: auto; height: 112px; width: 100%"
          >
            <li
              class="list-group-item py-1"
              v-for="loja in stateLocal.listaLojaPesquisa"
              :key="loja.restaurantId"
            >
              <div class="media align-items-center">
                <div class="custom-control custom-switch">
                  <input
                    type="radio"
                    class="custom-control-input"
                    :value="loja"
                    :id="loja.restaurantId"
                    :checked="
                      loja.restaurantId == stateLocal.lojaSelecionada.restaurantId
                    "
                    v-model="stateLocal.lojaSelecionada"
                  />
                  <label class="custom-control-label" :for="loja.restaurantId"
                    >&nbsp;</label
                  >
                </div>
                <div class="media-body">
                  <p class="mb-0">{{ loja.restaurantName }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12">
              <h6 class="text-truncate">
                <b>{{ tm("lojaSelecionada") }}: </b>{{ stateLocal.nomeTroca }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="text-truncate">
                  <b>{{ tm("itensCarrinho") }}:</b>
                </h6>
                <h6 class="text-truncate">
                  <b>{{ tm("emEstoque") }}:</b>
                </h6>
              </div>
            </div>
          </div>
          <ul
            class="list-group border list-group-flush scrolling"
            style="overflow: auto; height: 200px; width: 100%"
          >
            <li v-if="stateLocal.isChecking" class="list-group-item py-1">
              <div class="spinner-border spinner-border-sm" role="status"></div>
            </li>
            <li
              v-if="
                !stateLocal.carrinhoCliente || stateLocal.carrinhoCliente.length === 0
              "
            >
              <p class="pl-1">{{ tm("carrinhoVazio") }}</p>
            </li>
            <li
              class="list-group-item py-1"
              v-for="produto in stateLocal.carrinhoCliente"
              :key="produto.id"
            >
              <div class="media align-items-center">
                <div class="media-body">
                  <p class="mb-0">{{ produto.desc }}</p>
                </div>
                <div class="custom-control custom-switch">
                  <div v-if="produto.estoque === 0">
                    <i class="fas fa-times"></i>
                  </div>
                  <div v-else-if="produto.qtd <= produto.estoque">
                    <i class="fas fa-check"></i>
                  </div>
                  <div v-else>
                    <p class="mb-0">
                      <b>{{ "Apenas " + produto.estoque + " em estoque! " }}</b
                      ><i class="fas fa-exclamation-triangle"></i>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          :disabled="stateLocal.isLoadingTroca"
          @click="cancelarTroca"
        >
          {{ tm("cancelar") }}
        </button>
        <div v-if="stateLocal.inicioTroca">
          <button
            type="button"
            class="btn btn-primary js-btn-step"
            :disabled="stateLocal.isLoadingTroca"
            @click="verificaEstoque"
          >
            {{ tm("trocarLoja") }}
            <div
              v-if="stateLocal.isLoadingTroca"
              class="spinner-border spinner-border-sm"
              role="status"
            ></div>
          </button>
        </div>
        <div v-else>
          <button
            type="button"
            class="btn btn-primary js-btn-step"
            :disabled="stateLocal.isLoadingTroca"
            @click="handleTrocarLoja"
          >
            {{ tm("trocarLoja") }}
            <div
              v-if="stateLocal.isLoadingTroca"
              class="spinner-border spinner-border-sm"
              role="status"
            ></div>
          </button>
        </div>
      </template>
    </modal>
    <modal
      v-if="stateLocal.showModalAdicionarCarrinho"
      :title="tm('msgAth04')"
      @closeModal="stateLocal.showModalAdicionarCarrinho = false"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-2 font-weight-bold">Produto:</div>
          <div class="col-10">
            {{ stateLocal.propriedadesModalAdicionarCarrinho.produto.nomeProduto }}
          </div>
        </div>
        <div class="row">
          <div
            v-if="
              !stateLocal.isEmbedded ||
              stateLocal.propriedadesModalAdicionarCarrinho.produto.codigoProduto
            "
            class="col-2 font-weight-bold"
          >
            {{ tm("sku") }}:
          </div>
          <div
            v-if="
              !stateLocal.isEmbedded ||
              stateLocal.propriedadesModalAdicionarCarrinho.produto.codigoProduto
            "
            class="col-4"
          >
            {{ stateLocal.propriedadesModalAdicionarCarrinho.produto.codigoProduto }}
          </div>
          <div class="col-2 font-weight-bold">Estoque:</div>
          <div class="col-4">
            {{ stateLocal.estoqueSelecionado.quantidadeEstoque }}
          </div>
        </div>
        <div v-if="stateLocal.estoqueSelecionado.quantidadeEstoque > 0" class="row">
          <div class="col-3 font-weight-bold">
            <label for="idQuantidade">{{ tm("quantidade") }}:</label>
          </div>
          <div class="col-5">
            <input
              id="idQuantidade"
              type="number"
              min="1"
              v-model="stateLocal.estoqueSelecionado.quantidadeSolicitada"
              v-bind:max="stateLocal.estoqueSelecionado.quantidadeEstoque"
              class="form-control form-input form-control-sm text-center"
            />
          </div>
        </div>
        <div v-if="stateLocal.estoqueSelecionado.quantidadeEstoque == 0" class="row">
          <div class="col-3 font-weight-bold">
            <label for="idQuantidade">{{ tm("quantidade") }}:</label>
          </div>
          <div class="col-5">
            <input
              id="idQuantidade"
              type="number"
              min="1"
              v-model="stateLocal.estoqueSelecionado.quantidadeSolicitada"
              v-bind:max="10"
              class="form-control form-input form-control-sm text-center"
            />
          </div>
        </div>
        <div v-if="stateLocal.estoqueSelecionado.quantidadeEstoque == 0" class="row">
          <div class="col-3 font-weight-bold">
            <label for="idValor">{{ tm("valor") }}:</label>
          </div>
          <div class="col-5">
            <input
              id="idValor"
              type="number"
              step="0.01"
              v-model="stateLocal.estoqueSelecionado.produto.precoProduto"
              class="form-control form-input form-control-sm text-center"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          @click="
            stateLocal.showModalAdicionarCarrinho = false;
            stateLocal.isLoading = false;
          "
        >
          {{ tm("cancelar") }}
        </button>
        <button
          type="button"
          class="btn btn-primary js-btn-step"
          @click="handleAdicionarItemCarrinho"
        >
          {{ tm("adicionar") }}
        </button>
      </template>
    </modal>

    <modal
      v-if="stateLocal.showModalConfirmacaoEnviarTodos"
      :title="tm('confirmacao')"
      @closeModal="stateLocal.showModalConfirmacaoEnviarTodos = false"
    >
      <template v-slot:body>
        <div class="row" style="text-align: center">
          <h3>{{ tm("msgAth02") }}</h3>
        </div>
        <br />
        <div style="text-align: center">
          <p>{{ tm("msgAth03") }}</p>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          @click="stateLocal.showModalConfirmacaoEnviarTodos = false"
        >
          {{ tm("cancelar") }}
        </button>
        <button
          type="button"
          class="btn btn-primary js-btn-step"
          @click="handleEnviarTodosProdutos"
        >
          {{ tm("confirmar") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { reactive, computed, onMounted, watch, ref } from "vue";
import Secoes from "./Secoes";
import Produtos from "./Produtos";
import Carrinho from "./Carrinho";
import Pedido from "./Pedido";
import Modal from "../../components/Modal";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import ApiBotService from "../../services/ApiBotService";
import constants from "../../util/constants";
import { useI18n } from "vue-i18n";

export default {
  props: {
    nomeSecao: String,
    idSecao: Number,
  },
  components: { Secoes, Produtos, Carrinho, Pedido, Modal },
  setup(props, context) {
    const { tm } = useI18n();
    const store = useStore();
    const toast = useToast();
    const stateLocal = reactive({
      termoBusca: "",
      semEstoque: sessionStorage.getItem("isEmbedded")
        ? JSON.parse(sessionStorage.getItem("isEmbedded"))
        : false,
      sortimentoSelecionado: -1,
      listaProdutos: [],
      isLoading: false,
      listaSortimento: null,
      showModalAdicionarCarrinho: false,
      showModalImagem: false,
      showModalEstoque: false,
      showModalConfirmacaoEnviarTodos: false,
      propriedadesModalAdicionarCarrinho: null,
      propriedadesModalImagem: null,
      propriedadesModalEstoque: null,
      estoqueSelecionado: null,
      paginaBusca: 1,
      paginaFinal: 0,
      totalRegistrosBusca: 0,
      restoLista: 0,
      showPagina: 10,
      CanalVendas: 2,
      produtosBusca: [],
      idProduto: 0,
      isEmbedded: sessionStorage.getItem("isEmbedded")
        ? JSON.parse(sessionStorage.getItem("isEmbedded"))
        : false,
    });
    const componentKeySecoes = ref(0);
    const componentKeyCarrinho = ref(0);
    const sortimentoSelected = ref(null);

    const mensagemCampanhaUsuario = computed(() => {
      if (store.state.campanhaUsuario && store.state.campanhaUsuario.length > 0) {
        return store.state.campanhaUsuario[0].msg_Atendimento_Campanha_var.trim();
      }
      return "";
    });

    const fonteVtex = computed(() => {
      let pluginAtivo = store.state.listConfiguracoes.filter(
        (y) => y.chave == "fonte.vtex" && y.empresaId == store.state.usuario.empresa.id
      );
      if (pluginAtivo.length > 0 && pluginAtivo[0].valor.trim() == "true") {
        return false;
      } else {
        return true;
      }
    });

    const idClient = computed(() => {
      //console.log(store.state);
      return store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId] &&
        store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
          .codClienteZappag
        ? store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
            .codClienteZappag
        : store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId]
        ? store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].idClient
        : -1;
    });

    watch(sortimentoSelected, (newVal) => {
      handleFiltrarSortimento(newVal);
    });

    async function handleCarregarModalAdicionarItemCarrinho(produto) {
      const chatId = store.state.selectedChatQueue.chatId;
      const token = store.state.ath.apiBotHub[chatId].token;
      const idLojaSelecionada = store.state.ath.selectedLoja[chatId];
      if (stateLocal.isEmbedded && (!idClient.value || idClient.value == -1)) {
        toast.warning(tm("msgAth05"));
        return;
      }

      try {
        stateLocal.isLoading = true;
        let response = await ApiBotService.GetProductDetails(
          token,
          idLojaSelecionada,
          produto.idProduto
        );

        if (response.status == 200) {
          stateLocal.idProduto = produto.idProduto;
          stateLocal.produtosBusca = response.data.productSubItems;
          stateLocal.propriedadesModalAdicionarCarrinho = {};
          stateLocal.propriedadesModalAdicionarCarrinho.produto = produto;
          stateLocal.propriedadesModalAdicionarCarrinho.produto.listaEstoqueSortimento = [
            {},
          ];

          var listaEstoqueSortimento = [];
          var estoqueSortimento = {};
          stateLocal.produtosBusca.forEach(function (produtoInterno) {
            if (response != null) {
              estoqueSortimento = {};
              estoqueSortimento.quantidadeEstoque = produtoInterno.quantitySubItemStock;
              estoqueSortimento.sortimento = produtoInterno.productSubItemDescription;
              estoqueSortimento.quantidadeSolicitada = 0;
              estoqueSortimento.produto = produto;
              listaEstoqueSortimento.push(estoqueSortimento);
              if (produtoInterno.productSubItemName == produto.sortimento) {
                stateLocal.estoqueSelecionado = estoqueSortimento;
              }
            }
          });

          stateLocal.propriedadesModalAdicionarCarrinho.produto.listaEstoqueSortimento = listaEstoqueSortimento;
          stateLocal.showModalAdicionarCarrinho = true;
        } else if (response.status == 204) {
          var listaEstoqueSortimento = [];
          var estoqueSortimento = {};
          let estoqueLoja = {};
          let item = {};

          stateLocal.idProduto = produto.idProduto;
          stateLocal.propriedadesModalAdicionarCarrinho = {};
          stateLocal.propriedadesModalAdicionarCarrinho.produto = produto;
          stateLocal.idProduto = produto.idProduto;
          item.id = produto.idProduto;
          estoqueLoja.estoque = await store.dispatch("ath/getEstoque", {
            produto: item,
            chatId: chatId,
            idLoja: idLojaSelecionada,
          });

          if (estoqueLoja.estoque != null) {
            estoqueSortimento = {};
            estoqueSortimento.quantidadeEstoque = estoqueLoja.estoque;
            estoqueSortimento.sortimento = produto.nomeProduto;
            estoqueSortimento.quantidadeSolicitada = 0;
            estoqueSortimento.produto = produto;
            listaEstoqueSortimento.push(estoqueSortimento);
            stateLocal.estoqueSelecionado = estoqueSortimento;
          }

          stateLocal.estoqueSelecionado.quantidadeEstoque = estoqueLoja.estoque;
          stateLocal.showModalAdicionarCarrinho = true;
        }
      } catch (error) {
        //console.log(error);
      }
    }

    function setPropriedadesModalProduto(propriedades) {
      stateLocal.propriedadesModalProdutos = propriedades;
      stateLocal.paginaFinal = stateLocal.propriedadesModalProdutos.paginaFinal;
      stateLocal.totalRegistrosBusca =
        stateLocal.propriedadesModalProdutos.totalRegistrosBusca;
      stateLocal.paginaBusca = stateLocal.propriedadesModalProdutos.paginaBusca;
      sortimentoSelected.value = null;
      if (stateLocal.propriedadesModalProdutos.listaProduto.length > 0) {
        stateLocal.showModalEnviarProduto = true;
      } else {
        toast.warning(tm("msgAth06"));
      }
    }

    async function handleAdicionarItemCarrinho() {
      const chatId = store.state.selectedChatQueue.chatId;
      const token = store.state.ath.apiBotHub[chatId].token;
      const idLojaSelecionada = store.state.ath.selectedLoja[chatId];

      if (stateLocal.estoqueSelecionado.quantidadeEstoque > 0) {
        if (stateLocal.estoqueSelecionado.quantidadeSolicitada < 1) {
          toast.warning(tm("msgAth07"));
        } else if (
          stateLocal.estoqueSelecionado.quantidadeEstoque <
          stateLocal.estoqueSelecionado.quantidadeSolicitada
        ) {
          toast.warning(
            tm("msgAth08") +
              stateLocal.estoqueSelecionado.quantidadeEstoque +
              tm("msgAth09")
          );
        } else {
          try {
            stateLocal.isLoading = true;
            let response = await ApiBotService.GetProductDetails(
              token,
              idLojaSelecionada,
              stateLocal.idProduto
            );
            let sortementoItem = null;
            if (response.data != "") {
              let listaSubitem = response.data.productSubItems.map(function (e) {
                if (
                  stateLocal.estoqueSelecionado.sortimento == e.productSubItemDescription
                ) {
                  sortementoItem = e;
                }
              });
            }
            let precoProdutoSelcionado = stateLocal.estoqueSelecionado.produto.precoProduto
              .replaceAll(".", "")
              .replaceAll(",", ".");

            let produtoAdicionar = {
              id: stateLocal.estoqueSelecionado.produto.idProduto,
              sku: stateLocal.estoqueSelecionado.produto.codigoProduto,
              desc: stateLocal.estoqueSelecionado.produto.nomeProduto,
              valor:
                typeof precoProdutoSelcionado == "string"
                  ? parseFloat(precoProdutoSelcionado)
                  : precoProdutoSelcionado,
              estoque: stateLocal.estoqueSelecionado.quantidadeEstoque,
              qtd:
                typeof stateLocal.estoqueSelecionado.quantidadeSolicitada == "string"
                  ? parseInt(stateLocal.estoqueSelecionado.quantidadeSolicitada)
                  : stateLocal.estoqueSelecionado.quantidadeSolicitada,
              idItem: 0,
              idSubItem: 0,
              desconto: 0,
              sortimento: sortementoItem,
            };
            //console.log(produtoAdicionar);
            //console.log(stateLocal.estoqueSelecionado);
            if (response.status === 200) {
              for (const produtoInterno of response.data.productSubItems) {
                if (
                  stateLocal.estoqueSelecionado.sortimento ==
                  produtoInterno.productSubItemDescription
                ) {
                  (produtoAdicionar.idItem = produtoInterno.idProductItem),
                    (produtoAdicionar.idSubItem = produtoInterno.idProductSubItem);
                  break;
                }
              }
            }

            await store.dispatch("ath/addProdutoCarrinho", {
              produto: produtoAdicionar,
              chatId: chatId,
              preOrder: true,
            });
            toast.info(tm("msgAth10"));
            stateLocal.showModalAdicionarCarrinho = false;
          } catch (erro) {
            if (erro.status === 403) {
              toast.warning(tm("msgAth11"));
            } else if (erro.status === 401) {
              toast.warning(tm("msgAth12"));
            } else {
              //console.log("indexViewError", erro);
              toast.error(tm("msgAth13"));
            }
          } finally {
            stateLocal.isLoading = false;
          }
        }
      } else {
        try {
          stateLocal.isLoading = true;
          let response = await ApiBotService.GetProductDetails(
            token,
            idLojaSelecionada,
            stateLocal.idProduto
          );

          // //console.log(
          //   "stateLocal.estoqueSelecionado.produto",
          //    stateLocal.estoqueSelecionado.produto
          //  );

          stateLocal.estoqueSelecionado.produto.precoProduto = stateLocal.estoqueSelecionado.produto.precoProduto.replaceAll(
            ",",
            "."
          );
          if (typeof stateLocal.estoqueSelecionado.quantidadeSolicitada == "string") {
            parseFloat(stateLocal.estoqueSelecionado.quantidadeSolicitada);
          }
          if (stateLocal.estoqueSelecionado.quantidadeSolicitada == 0) {
            stateLocal.estoqueSelecionado.quantidadeSolicitada = 1;
          }
          let produtoAdicionar = {
            id: stateLocal.estoqueSelecionado.produto.idProduto,
            sku: stateLocal.estoqueSelecionado.produto.codigoProduto,
            desc:
              stateLocal.estoqueSelecionado.produto.sortimento != "" &&
              stateLocal.estoqueSelecionado.produto.sortimento != null
                ? `${stateLocal.estoqueSelecionado.produto.nomeProduto} - ${stateLocal.estoqueSelecionado.produto.sortimento}`
                : stateLocal.estoqueSelecionado.produto.nomeProduto,
            valor:
              typeof stateLocal.estoqueSelecionado.produto.precoProduto == "string"
                ? parseFloat(stateLocal.estoqueSelecionado.produto.precoProduto)
                : stateLocal.estoqueSelecionado.produto.precoProduto,
            estoque: stateLocal.estoqueSelecionado.quantidadeEstoque,
            qtd: stateLocal.estoqueSelecionado.quantidadeSolicitada,
            idItem: 0,
            idSubItem: 0,
            desconto: 0,
          };
          ////console.log("produtoAdicionar", produtoAdicionar);

          if (response.status === 200) {
            for (const produtoInterno of response.data.productSubItems) {
              if (
                stateLocal.estoqueSelecionado.sortimento ==
                produtoInterno.productSubItemName
              ) {
                (produtoAdicionar.idItem = produtoInterno.idProductItem),
                  (produtoAdicionar.idSubItem = produtoInterno.idProductSubItem);
                break;
              }
            }
          }

          await store.dispatch("ath/addProdutoCarrinho", {
            produto: produtoAdicionar,
            chatId: chatId,
            preOrder: true,
          });
          toast.info(tm("msgAth10"));
          stateLocal.showModalAdicionarCarrinho = false;
        } catch (erro) {
          if (erro.status === 403) {
            toast.warning(tm("msgAth11"));
          } else if (erro.status === 401) {
            toast.warning(tm("msgAth12"));
          } else {
            //console.log("indexViewError", erro);
            toast.error(tm("msgAth13"));
          }
        } finally {
          stateLocal.isLoading = false;
        }
      }
    }

    function handleSelecionarProduto(produto) {
      produto.selecionado = !produto.selecionado;
    }

    function handleEnviarTodosProdutosConfirmacao() {
      stateLocal.showModalConfirmacaoEnviarTodos = true;
    }

    function handleEnviarTodosProdutos() {
      var produtosSelecionados = stateLocal.propriedadesModalProdutos.listaProduto.filter(
        function (produto) {
          return produto.visivel == true;
        }
      );
      context.emit("enviarDescricaoProduto", produtosSelecionados);
      stateLocal.showModalConfirmacaoEnviarTodos = false;
      stateLocal.showModalEnviarProduto = false;
    }

    function handleEnviarProdutoSelecionado(produto) {
      var produtos = [];
      produtos.push(produto);
      context.emit("enviarDescricaoProduto", produtos);
    }

    function handleEnviarProdutosSelecionados() {
      var produtosSelecionados = stateLocal.propriedadesModalProdutos.listaProduto.filter(
        function (produto) {
          return produto.selecionado == true;
        }
      );

      if (produtosSelecionados.length > 0) {
        context.emit("enviarDescricaoProduto", produtosSelecionados);
        stateLocal.showModalEnviarProduto = false;
      } else {
        toast.warning(tm("msgAth14"));
      }
    }

    function handleFiltrarSortimento(sortimento) {
      for (
        var indice = 0;
        indice < stateLocal.propriedadesModalProdutos.listaProduto.length;
        indice++
      ) {
        if (
          sortimento == null ||
          stateLocal.propriedadesModalProdutos.listaProduto[indice].sortimento ===
            sortimento
        ) {
          stateLocal.propriedadesModalProdutos.listaProduto[indice].visivel = true;
        } else {
          stateLocal.propriedadesModalProdutos.listaProduto[indice].visivel = false;
          stateLocal.propriedadesModalProdutos.listaProduto[indice].selecionado = false;
        }
      }
    }

    const lojasCliente = computed(() => {
      return store.state.ath.lojasCliente[store.state.selectedChatQueue.chatId];
    });

    let nomeLoja = computed(() => {
      if (store.state.ath.selectedLojaDados[store.state.selectedChatQueue.chatId]) {
        if (fonteVtex) {
          return nomeLojaVtex();
        }
        return store.state.ath.selectedLojaDados[store.state.selectedChatQueue.chatId]
          .restaurantName;
      } else {
        return "";
      }
    });
    function nomeLojaVtex() {
      return store.state.selectedChatQueue.customVariables["@lojaAtendimentoNome"];
    }
    async function buscarItensAdicionaisFrente() {
      if (
        !stateLocal.isLoading &&
        stateLocal.totalRegistrosBusca >
          stateLocal.propriedadesModalProdutos.listaProduto.length &&
        stateLocal.sortimentoSelecionado == -1
      ) {
        stateLocal.isLoading = true;
        stateLocal.paginaBusca += 1;

        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        let idSession = stateLocal.propriedadesModalProdutos.idSession;
        let page = stateLocal.paginaBusca;
        let show = stateLocal.showPagina;
        let idCanalVendas = stateLocal.CanalVendas;
        stateLocal.produtosBusca = [];
        const response = await ApiBotService.GetProductsBySessionAndStore(
          token,
          idLoja,
          idSession,
          idCanalVendas,
          page,
          show
        );
        stateLocal.isLoading = false;

        var produtos = [];
        const listaProduto = response.data.products;
        for (var indice = 0; indice < listaProduto.length; indice++) {
          if (listaProduto[indice].quantityProductStock > 0) {
            produtos[produtos.length] = {
              idProduto: listaProduto[indice].idProduct,
              codigoProduto: listaProduto[indice].productCode,
              descricaoProduto: listaProduto[indice].sessionDescription,
              nomeProduto: listaProduto[indice].productName,
              imagemProduto: listaProduto[indice].imageProduct,
              precoProduto: listaProduto[indice].productValue.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              }),
              sortimento: listaProduto[indice].productItemName,
              visivel: true,
              selecionado: false,
            };
          }
        }
        var sortimento = [...new Set(listaProduto.map((item) => item.productItemName))];

        if (sortimento.length == 1 && sortimento[0] == null) {
          sortimento = null;
        } else {
          sortimento.sort();
        }

        var propriedades = {
          nomeSecao: stateLocal.propriedadesModalProdutos.nomeSecao,
          idSession: idSession,
          listaSortimento: sortimento,
          listaProduto: produtos,
          totalRegistrosBusca: stateLocal.totalRegistrosBusca,
          restoLista: stateLocal.restoLista,
          paginaFinal: stateLocal.paginaFinal,
          paginaBusca: stateLocal.paginaBusca,
        };

        await setPropriedadesModalProduto(propriedades);
        stateLocal.isLoading = false;
      }
    }

    async function buscarItensAdicionaisFrenteUltimo() {
      if (
        !stateLocal.isLoading &&
        stateLocal.totalRegistrosBusca >
          stateLocal.propriedadesModalProdutos.listaProduto.length &&
        stateLocal.sortimentoSelecionado == -1
      ) {
        stateLocal.isLoading = true;
        stateLocal.paginaBusca = stateLocal.paginaFinal;

        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        let idSession = stateLocal.propriedadesModalProdutos.idSession;
        let idCanalVendas = stateLocal.CanalVendas;
        let show = stateLocal.showPagina;
        let page = stateLocal.paginaBusca;

        stateLocal.produtosBusca = [];
        const response = await ApiBotService.GetProductsBySessionAndStore(
          token,
          idLoja,
          idSession,
          idCanalVendas,
          page,
          show
        );

        var produtos = [];
        const listaProduto = response.data.products;
        for (var indice = 0; indice < listaProduto.length; indice++) {
          if (listaProduto[indice].quantityProductStock > 0) {
            produtos[produtos.length] = {
              idProduto: listaProduto[indice].idProduct,
              codigoProduto: listaProduto[indice].productCode,
              descricaoProduto: listaProduto[indice].sessionDescription,
              nomeProduto: listaProduto[indice].productName,
              imagemProduto: listaProduto[indice].imageProduct,
              precoProduto: listaProduto[indice].productValue.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              }),
              sortimento: listaProduto[indice].productItemName,
              visivel: true,
              selecionado: false,
            };
          }
        }
        var sortimento = [...new Set(listaProduto.map((item) => item.productItemName))];

        if (sortimento.length == 1 && sortimento[0] == null) {
          sortimento = null;
        } else {
          sortimento.sort();
        }

        var propriedades = {
          nomeSecao: stateLocal.propriedadesModalProdutos.nomeSecao,
          idSession: idSession,
          listaSortimento: sortimento,
          listaProduto: produtos,
          totalRegistrosBusca: stateLocal.totalRegistrosBusca,
          restoLista: stateLocal.restoLista,
          paginaFinal: stateLocal.paginaFinal,
          paginaBusca: stateLocal.paginaBusca,
        };

        await setPropriedadesModalProduto(propriedades);
        stateLocal.isLoading = false;
      }
    }

    async function buscarItensAdicionaisVoltaPrimeiro() {
      if (
        !stateLocal.isLoading &&
        stateLocal.totalRegistrosBusca > stateLocal.produtosBusca.length &&
        stateLocal.sortimentoSelecionado == -1
      ) {
        stateLocal.isLoading = true;
        stateLocal.paginaBusca = 1;

        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        let idSession = stateLocal.propriedadesModalProdutos.idSession;
        let idCanalVendas = stateLocal.CanalVendas;
        let show = stateLocal.showPagina;
        let page = stateLocal.paginaBusca;

        stateLocal.produtosBusca = [];
        const response = await ApiBotService.GetProductsBySessionAndStore(
          token,
          idLoja,
          idSession,
          idCanalVendas,
          page,
          show
        );

        var produtos = [];
        const listaProduto = response.data.products;
        for (var indice = 0; indice < listaProduto.length; indice++) {
          if (listaProduto[indice].quantityProductStock > 0) {
            produtos[produtos.length] = {
              idProduto: listaProduto[indice].idProduct,
              codigoProduto: listaProduto[indice].productCode,
              descricaoProduto: listaProduto[indice].sessionDescription,
              nomeProduto: listaProduto[indice].productName,
              imagemProduto: listaProduto[indice].imageProduct,
              precoProduto: listaProduto[indice].productValue.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              }),
              sortimento: listaProduto[indice].productItemName,
              visivel: true,
              selecionado: false,
            };
          }
        }
        var sortimento = [...new Set(listaProduto.map((item) => item.productItemName))];

        if (sortimento.length == 1 && sortimento[0] == null) {
          sortimento = null;
        } else {
          sortimento.sort();
        }

        var propriedades = {
          nomeSecao: stateLocal.propriedadesModalProdutos.nomeSecao,
          idSession: idSession,
          listaSortimento: sortimento,
          listaProduto: produtos,
          totalRegistrosBusca: stateLocal.totalRegistrosBusca,
          restoLista: stateLocal.restoLista,
          paginaFinal: stateLocal.paginaFinal,
          paginaBusca: stateLocal.paginaBusca,
        };

        await setPropriedadesModalProduto(propriedades);
        stateLocal.isLoading = false;
      }
    }

    async function buscarItensAdicionaisVolta() {
      if (
        !stateLocal.isLoading &&
        stateLocal.totalRegistrosBusca > stateLocal.produtosBusca.length &&
        stateLocal.sortimentoSelecionado == -1
      ) {
        stateLocal.isLoading = true;
        stateLocal.paginaBusca -= 1;

        let chatId = store.state.selectedChatQueue.chatId;
        let idLoja = store.state.ath.selectedLoja[chatId];
        let token = store.state.ath.apiBotHub[chatId].token;
        let idSession = stateLocal.propriedadesModalProdutos.idSession;
        let idCanalVendas = stateLocal.CanalVendas;
        let show = stateLocal.showPagina;
        let page = stateLocal.paginaBusca;

        stateLocal.produtosBusca = [];
        const response = await ApiBotService.GetProductsBySessionAndStore(
          token,
          idLoja,
          idSession,
          idCanalVendas,
          page,
          show
        );

        var produtos = [];
        const listaProduto = response.data.products;
        for (var indice = 0; indice < listaProduto.length; indice++) {
          if (listaProduto[indice].quantityProductStock > 0) {
            produtos[produtos.length] = {
              idProduto: listaProduto[indice].idProduct,
              codigoProduto: listaProduto[indice].productCode,
              descricaoProduto: listaProduto[indice].sessionDescription,
              nomeProduto: listaProduto[indice].productName,
              imagemProduto: listaProduto[indice].imageProduct,
              precoProduto: listaProduto[indice].productValue.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              }),
              sortimento: listaProduto[indice].productItemName,
              visivel: true,
              selecionado: false,
            };
          }
        }
        var sortimento = [...new Set(listaProduto.map((item) => item.productItemName))];

        if (sortimento.length == 1 && sortimento[0] == null) {
          sortimento = null;
        } else {
          sortimento.sort();
        }

        var propriedades = {
          nomeSecao: stateLocal.propriedadesModalProdutos.nomeSecao,
          idSession: idSession,
          listaSortimento: sortimento,
          listaProduto: produtos,
          totalRegistrosBusca: stateLocal.totalRegistrosBusca,
          restoLista: stateLocal.restoLista,
          paginaFinal: stateLocal.paginaFinal,
          paginaBusca: stateLocal.paginaBusca,
        };

        await setPropriedadesModalProduto(propriedades);
        stateLocal.isLoading = false;
      }
    }

    onMounted(() => {
      stateLocal.inicioTroca = true;
      stateLocal.isLoadingTroca = false;
      stateLocal.isEmbedded = sessionStorage.getItem("isEmbedded")
        ? JSON.parse(sessionStorage.getItem("isEmbedded"))
        : false;
    });

    function setOpcao(value) {
      store.commit("ath/setOpcao", value);
    }

    function trocaLojaInicio() {
      stateLocal.lojaSelecionada =
        store.state.ath.selectedLojaDados[store.state.selectedChatQueue.chatId];
      stateLocal.showModalTroca = true;
    }

    async function trocaLoja(loja) {
      stateLocal.lojaSelecionada = loja;
      stateLocal.nomeTroca = loja.restaurantName;
      stateLocal.inicioTroca = false;
      stateLocal.isChecking = true;
      stateLocal.showModalTroca = true;
      await verificaEstoque();
      stateLocal.isChecking = false;
    }

    async function verificaEstoque() {
      stateLocal.nomeTroca = stateLocal.lojaSelecionada.restaurantName;
      stateLocal.isLoadingTroca = true;
      stateLocal.carrinhoCliente =
        store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId];
      if (
        stateLocal.lojaSelecionada.restaurantId !=
          store.state.ath.selectedLoja[store.state.selectedChatQueue.chatId] &&
        stateLocal.carrinhoCliente
      ) {
        for (let [i, item] of stateLocal.carrinhoCliente.entries()) {
          item.estoque = await store.dispatch("ath/getEstoque", {
            produto: item,
            chatId: store.state.selectedChatQueue.chatId,
            idLoja: stateLocal.lojaSelecionada.restaurantId,
          });
        }
      }
      stateLocal.isLoadingTroca = false;
      stateLocal.inicioTroca = false;
    }

    async function buscarLoja() {
      let chatId = store.state.selectedChatQueue.chatId;
      let token = store.state.ath.apiBotHub[chatId].token;

      stateLocal.isLoadingTroca = true;
      try {
        let idrede = process.env.VUE_APP_COD_REDE
          ? process.env.VUE_APP_COD_REDE
          : constants.ID_REDE;
        const response = await ApiBotService.GetStoresByRede(token, idrede, {
          zipCode: stateLocal.cepBusca,
        });
        stateLocal.listaLojaPesquisa = response.data;
      } catch (ex) {
        if (ex.status === 404) {
          toast.warning(tm("msgAth15"));
        }
      }
      stateLocal.isLoadingTroca = false;
    }

    async function handleTrocarLoja() {
      stateLocal.isLoadingTroca = true;
      if (
        stateLocal.lojaSelecionada.restaurantId !=
        store.state.ath.selectedLoja[store.state.selectedChatQueue.chatId]
      ) {
        let lojaAnterior =
          store.state.ath.selectedLojaDados[store.state.selectedChatQueue.chatId];
        try {
          store.commit("ath/setLojaSelected", {
            loja: stateLocal.lojaSelecionada.restaurantId,
            chatId: store.state.selectedChatQueue.chatId,
          });
          store.commit("ath/setLojaSelectedDados", {
            dados: stateLocal.lojaSelecionada,
            chatId: store.state.selectedChatQueue.chatId,
          });
          await store.dispatch("ath/changeLoja", {
            idLoja: lojaAnterior.restaurantId,
            carrinho: stateLocal.carrinhoCliente,
          });
          await store.dispatch("ath/getSessoesLoja");
          forceRerender("secoes");
        } catch (error) {
          store.commit("ath/setLojaSelected", {
            loja: lojaAnterior.restaurantId,
            chatId: store.state.selectedChatQueue.chatId,
          });
          store.commit("ath/setLojaSelectedDados", {
            dados: lojaAnterior,
            chatId: store.state.selectedChatQueue.chatId,
          });
          stateLocal.lojaSelecionada = lojaAnterior;
          toast.error(tm("msgAth16"));
        }
      }
      stateLocal.isLoadingTroca = false;
      stateLocal.inicioTroca = true;
      stateLocal.showModalTroca = false;
    }

    function cancelarTroca() {
      stateLocal.lojaSelecionada =
        store.state.ath.selectedLojaDados[store.state.selectedChatQueue.chatId];
      stateLocal.showModalTroca = false;
      stateLocal.inicioTroca = true;
    }

    function enviarMsgChat(msg) {
      context.emit("enviarMsgChat", msg);
    }

    async function enviarCatalogo(secao) {
      await context.emit("enviarCatalogo", secao);
    }

    function forceRerender(opcao) {
      if (opcao) {
        if (opcao === "carrinho") {
          componentKeyCarrinho.value += 1;
        } else if (opcao === "secoes") {
          componentKeySecoes.value += 1;
        }
      }
    }

    return {
      mensagemCampanhaUsuario,
      store,
      stateLocal,
      setOpcao,
      handleTrocarLoja,
      lojasCliente,
      nomeLoja,
      cancelarTroca,
      nomeLojaVtex,
      props,
      setPropriedadesModalProduto,
      handleSelecionarProduto,
      handleFiltrarSortimento,
      handleEnviarProdutosSelecionados,
      handleCarregarModalAdicionarItemCarrinho,
      handleEnviarTodosProdutos,
      handleEnviarTodosProdutosConfirmacao,
      handleAdicionarItemCarrinho,
      enviarMsgChat,
      verificaEstoque,
      handleEnviarProdutoSelecionado,
      trocaLoja,
      buscarLoja,
      enviarCatalogo,
      trocaLojaInicio,
      componentKeySecoes,
      componentKeyCarrinho,
      fonteVtex,
      forceRerender,
      buscarItensAdicionaisVoltaPrimeiro,
      buscarItensAdicionaisFrente,
      buscarItensAdicionaisFrenteUltimo,
      buscarItensAdicionaisVolta,
      tm,
      sortimentoSelected,
    };
  },
};
</script>

<style>
#outerDivWrapper,
#outerDiv {
  width: 100%;
  margin: 0em;
}

#scrollableContent {
  height: 100%;
  margin: 0em;
  overflow-y: auto;
}

.image-parent {
  max-width: 40px;
}

#lista-produtos-loja > li {
  font-size: 0.8em;
  cursor: pointer;
}
</style>
