<template>
  <div class="appnavbar-body">
    <div class="note-container py-2 px-2">
      <div class="card mb-2 mr-1">
        <div class="topico" :class="!!stateLocal.showConsulta ? 'open' : ''">
          <header
            class="card-header py-2 m-0 titulo"
            @click="stateLocal.showConsulta = !stateLocal.showConsulta"
          >
            <span class="mb-0 font-weight-bold">{{ tm("consultarPedido") }}</span>
            <i class="fas fa-chevron-circle-down"></i>
          </header>
          <div class="conteudo">
            <div class="card-body" style="overflow-x: auto">
              <div class="row">
                <div class="col-12 form-group mb-2">
                  <label class="mb-1">{{ tm("numeroPedido") }}</label>
                  <input
                    type="text"
                    id="pedidoBusca"
                    class="form-control p-1"
                    v-model="stateLocal.pedidoBusca"
                  />
                </div>
              </div>
            </div>
            <div class="card-body" style="overflow-x: auto">
              <div class="row">
                <div class="col-12 form-group mb-2">
                  <label class="mb-1">{{ tm("cpfCliente") }}</label>
                  <input
                    type="text"
                    id="pedidoBuscaCpf"
                    class="form-control p-1"
                    placeholder="999.999.999-99"
                    v-maska="'###.###.###-##'"
                    v-model="stateLocal.pedidoBuscaCpf"
                  />
                </div>
              </div>
            </div>
            <div class="p-2 mb-3 card-footer text-right border-bottom">
              <button type="button" class="btn-sm btn-primary" @click="buscarPedido">
                <i class="fa fa-search fa-lg mx-1"></i>{{ tm("buscar") }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="topico"
          :class="!!stateLocal.showListaPedido ? 'open' : ''"
          style="z-index: 90"
        >
          <header
            class="card-header py-2 m-0 titulo"
            @click="stateLocal.showListaPedido = !stateLocal.showListaPedido"
          >
            <span class="mb-0 font-weight-bold">{{ tm("listaPedidos") }}</span>
            <i class="fas fa-chevron-circle-down"></i>
          </header>
          <div class="conteudo">
            <div class="card-body p-1">
              <div class="row">
                <div class="col-12 form-group mb-1">
                  <div
                    class="text-center text-primary my-2"
                    v-if="stateLocal.isLoadingListaPedidos"
                  >
                    <div class="spinner-border spinner-border-sm mt-3"></div>
                    <br />
                    <strong>{{ tm("carregando") }}...</strong>
                  </div>
                </div>
              </div>
              <div v-for="(pedido, index) in stateLocal.listaPedidos" :key="index">
                <div class="contacts-content">
                  <div class="contacts-info">
                    <a
                      :id="'ped_' + index"
                      class="py-0 px-2"
                      href="#"
                      :title="tm('detalhe')"
                      role="button"
                      @click="detalhePedidoLista(pedido)"
                      >{{ pedido.uid }} --
                      {{ convertDataPedido(pedido.issuedAt) }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="topico"
          :class="!!stateLocal.showDetalhe ? 'open' : ''"
          style="z-index: 90"
        >
          <header
            class="card-header py-2 m-0 titulo"
            @click="stateLocal.showDetalhe = !stateLocal.showDetalhe"
          >
            <span class="mb-0 font-weight-bold">{{ tm("detalhesPedido") }}</span>
            <i class="fas fa-chevron-circle-down"></i>
          </header>
          <div class="conteudo">
            <div class="card-body card-body-pedido pt-1" style="overflow-x: hidden">
              <div class="row">
                <div class="col-12 form-group mb-1">
                  <div
                    class="text-center text-primary my-2"
                    v-if="stateLocal.isLoadingPedidos"
                  >
                    <div class="spinner-border spinner-border-sm mt-3"></div>
                    <br />
                    <strong>{{ tm("carregando") }}...</strong>
                  </div>
                </div>
              </div>
              <div class="row pl-1 pr-1 pb-0">
                <div class="col-12 col-md-6 form-group mb-1">
                  <label class="mb-1 text-nowrap">{{
                    tm("data") + "/" + tm("hora")
                  }}</label>
                  <h6 class="font-weight-bold">{{ stateLocal.dataPedido }}</h6>
                </div>
                <div class="col-12 col-md-6 form-group mb-1">
                  <label class="mb-1 text-nowrap">{{ tm("numero") }}</label>
                  <h6 class="font-weight-bold">{{ stateLocal.numeroPedido }}</h6>
                </div>
              </div>
              <div v-if="stateLocal.situacaoPedido" class="row pl-1 pr-1 pb-0">
                <div class="col-12 form-group mb-1">
                  <label class="mb-1">{{ tm("situacao") }}</label>
                  <textarea
                    v-model="stateLocal.situacaoPedido"
                    disabled
                    class="form-control scrolling textarea-custom font-weight-bold"
                    rows="1"
                  >
                  </textarea>
                </div>
              </div>
              <div class="row pl-1 pr-1 pb-0">
                <div class="col-12 form-group mb-1 mr-2">
                  <label class="mb-1">{{ tm("cliente") }}</label>
                  <textarea
                    v-model="stateLocal.nomeCliente"
                    disabled
                    class="form-control scrolling textarea-custom font-weight-bold"
                    rows="1"
                  >
                  </textarea>
                </div>
              </div>
              <div class="row pl-1 pr-1 pb-0">
                <div class="col-12 form-group mb-0 pr-0">
                  <label class="mb-1 text-nowrap">{{ tm("cpfCliente") }}</label>
                  <h6 class="font-weight-bold">{{ stateLocal.cpfCliente }}</h6>
                </div>
              </div>
              <div class="row pl-1 pr-1 pb-0">
                <div class="col-12 form-group mb-1">
                  <label class="mb-1">{{ tm("formaPagamento") }}</label>
                  <textarea
                    v-model="stateLocal.formaPagamento"
                    disabled
                    class="form-control scrolling textarea-custom font-weight-bold"
                    rows="2"
                  >
                  </textarea>
                </div>
              </div>

              <header class="card-header py-2m-0 pr-0 titulo">
                <span class="mb-0 font-weight-bold">{{ tm("itens") }}</span>
              </header>

              <table class="table table-sm mb-1" style="width: 95%">
                <tr v-for="(item, index) in stateLocal.itens" :key="index">
                  <td class="text-left w-10 pl-1 pr-1">
                    <div class="badge badge-rounded badge-light">{{ item.quantity }}</div>
                  </td>

                  <td class="align-middle p-1">
                    <small class="mb-0">{{
                      item.name ? item.name : item.description
                    }}</small>
                  </td>
                  <td class="text-right w-25 text-nowrap pl-1 pr-1">
                    <small>{{
                      formataMoeda(
                        (item.discountValueAsDecimal > 0
                          ? item.discountValueAsDecimal
                          : item.valueAsDecimal) * item.quantity
                      )
                    }}</small>
                  </td>
                </tr>
              </table>

              <table
                v-if="stateLocal.itens && stateLocal.itens.length > 0"
                class="table table-sm"
                style="width: 95%"
              >
                <tr v-if="stateLocal.frete">
                  <td colspan="2" class="align-middle pb-0 pt-0 pl-2 pr-1">
                    <h6 class="mb-0">{{ "Frete" }}</h6>
                  </td>
                  <td class="text-right w-25 text-nowrap pb-0 pt-0 pl-1 pr-1">
                    <small class="mb-0 font-weight-bold">{{ stateLocal.frete }}</small>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="align-middle pb-0 pt-0 pl-2 pr-1">
                    <h6 class="mb-0">{{ "Total Pedido" }}</h6>
                  </td>
                  <td class="text-right w-25 text-nowrap pb-0 pt-0 pl-1 pr-1">
                    <small class="mb-0 font-weight-bold">{{
                      stateLocal.totalGeral
                    }}</small>
                  </td>
                </tr>
              </table>
            </div>

            <div class="p-2 mb-2 mt-2 card-footer text-right">
              <button
                type="button"
                :disabled="
                  !stateLocal.numeroPedido || stateLocal.statusPedido == 'invoiced'
                "
                @click="stateLocal.showModalCancelar = true"
                :title="tm('cancelarPedido')"
                class="btn btn-primary"
              >
                <i class="fa fa-ban fa-lg mx-1"></i>{{ tm("cancelar") }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="false"
          class="topico"
          :class="!!stateLocal.showOcorrencia ? 'open' : ''"
        >
          <header
            class="card-header py-2 m-0 titulo"
            @click="stateLocal.showOcorrencia = !stateLocal.showOcorrencia"
          >
            <span class="mb-0 font-weight-bold">{{ tm("ocorrencia") }}</span>
            <i class="fas fa-chevron-circle-down"></i>
          </header>
          <div class="conteudo">
            <div class="card-body">
              <div class="row pl-1 pr-1 pb-0">
                <div class="col-12 col-md-6 form-group mb-0">
                  <label class="mb-1 text-nowrap">{{
                    tm("data") + "/" + tm("hora")
                  }}</label>
                  <h6 class="font-weight-bold">{{ stateLocal.dataOcorrencia }}</h6>
                </div>
                <div class="col-12 col-md-6 form-group mb-0">
                  <label class="mb-1 text-nowrap">{{ tm("prioridade") }}</label>
                  <h6 class="font-weight-bold">{{ stateLocal.prioridade }}</h6>
                </div>
              </div>
              <div class="row pl-1 pr-1 pb-0">
                <div class="col-12 form-group mb-0">
                  <label class="mb-1">{{ tm("motivo") }}</label>
                  <textarea
                    v-model="stateLocal.motivo"
                    disabled
                    class="form-control scrolling textarea-custom font-weight-bold"
                    rows="3"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="false"
          class="topico"
          :class="!!stateLocal.showHistorico ? 'open' : ''"
        >
          <header
            class="card-header py-2 m-0 titulo"
            @click="stateLocal.showHistorico = !stateLocal.showHistorico"
          >
            <span class="mb-0 font-weight-bold">{{ tm("historicoProtocolos") }}</span>
            <i class="fas fa-chevron-circle-down"></i>
          </header>
          <div class="conteudo">
            <div class="card-body p-1" style="overflow-x: auto; width: 80%">
              <ul class="contacts-list unread p-0 m-0">
                <div v-for="(protocolo, index) in stateLocal.historico" :key="index">
                  <li class="contacts-item unread" style="cursor: default">
                    <div class="contacts-content">
                      <div class="contacts-info">
                        <small class="mb-0">{{ protocolo.data }}</small>
                        <a
                          v-if="false"
                          :id="'hist' + index"
                          class="py-0 px-1"
                          href="#"
                          :title="tm('detalhe')"
                          role="button"
                          @click="detalheProtocolo(protocolo)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </a>
                      </div>
                      <div class="contacts-texts" style="">
                        <textarea
                          v-model="protocolo.numero"
                          disabled
                          class="form-control scrolling textarea-custom font-weight-bold"
                          rows="2"
                        >
                        </textarea>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      v-if="stateLocal.showModalCancelar"
      :title="tm('cancelarPedido')"
      @closeModal="stateLocal.showModalCancelar = false"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <h6 class="text-truncate">
              {{ tm("msgConfirmarCancelamento") + stateLocal.numeroPedido + "?" }}
            </h6>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          @click="stateLocal.showModalCancelar = false"
        >
          {{ tm("nao") }}
        </button>
        <button type="button" class="btn btn-primary js-btn-step" @click="cancelar">
          {{ tm("sim") }}
        </button>
      </template>
    </modal>

    <modal
      v-if="stateLocal.showModalHitorico"
      :title="tm('protocolo')"
      @closeModal="stateLocal.showModalHitorico = false"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-12"></div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import moment from "moment";
import Validar from "../../util/Validadores";
import { reactive } from "vue";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import constants from "../../util/constants";
import OmsHubService from "../../services/OmsHubService";
import Modal from "../../components/Modal";
import { useI18n } from "vue-i18n";

const toast = useToast();
export default {
  props: {
    timezoneDefault: String,
  },
  components: { Modal },
  setup(props, context) {
    const { tm, locale } = useI18n();
    const store = useStore();
    const stateLocal = reactive({
      isLoadingPedidos: false,
      isLoadingListaPedidos: false,
      showConsulta: false,
      showDetalhe: false,
      showListaPedido: false,
      showHistorico: false,
      showOcorrencia: false,
      showModalCancelar: false,
      showModalHitorico: false,
      pedidoBusca: "",
      pedidoBuscaCpf: "",
      numeroPedido: "",
      dataPedido: "",
      cpfCliente: "",
      nomeCliente: "",
      totalPedido: "",
      totalGeral: "",
      formaPagamento: "",
      frete: "",
      situacaoPedido: "",
      statusPedido: "",
      itens: [],
      dataOcorrencia: "",
      prioridade: "",
      motivo: "",
      historico: [],
      listaPedidos: [],
      produtoItem: {},
      order: null,
      timezoneDefault2: props.timezoneDefault,
    });

    //methods
    function limparDados() {
      stateLocal.numeroPedido = "";
      stateLocal.dataPedido = "";
      stateLocal.cpfCliente = "";
      stateLocal.itens = [];
      stateLocal.nomeCliente = "";
      stateLocal.situacaoPedido = "";
      stateLocal.statusPedido = "";
      stateLocal.totalPedido = "";
      stateLocal.totalGeral = "";
      stateLocal.formaPagamento = "";
      stateLocal.frete = "";
      stateLocal.dataOcorrencia = "";
      stateLocal.prioridade = "";
      stateLocal.motivo = "";
      stateLocal.produtoItem = {};
    }

    async function cancelar() {
      if (!stateLocal.numeroPedido) {
        return false;
      }
      try {
        let mediaId = "";
        if (
          store.state.selectedChatQueue &&
          store.state.selectedChatQueue.customVariables["MediaId"]
        ) {
          mediaId = store.state.selectedChatQueue.customVariables["MediaId"];
        } else {
          let pluginAtivo = store.state.listConfiguracoes.filter(
            (y) =>
              y.chave == "plugin.marketplace" &&
              y.empresaId == store.state.usuario.empresa.id
          );
          if (pluginAtivo.length > 0 && pluginAtivo[0].valor.trim() != "") {
            mediaId = pluginAtivo[0].valor;
          }
        }
        if (mediaId) {
          if (!store.state.oms.clienteProfile[mediaId]) {
            await store.dispatch("oms/getDadosOmsHub", {
              mediaId: mediaId,
              channelId: 1005,
            });
          }
          if (store.state.oms.clienteProfile[mediaId] && stateLocal.order) {
            let order = Object.assign({}, stateLocal.order);
            let transformation = {
              type: "Cancel",
              arguments: [],
            };
            let transformations = [];
            transformations.push(transformation);
            order.transformations = transformations;
            //console.log("updade", order);
            const response = await OmsHubService.UpdateOrder(
              mediaId,
              store.state.oms.clienteProfile[mediaId].channelId,
              store.state.oms.clienteProfile[mediaId].userData,
              stateLocal.numeroPedido,
              stateLocal.order.origin,
              order
            );
            toast.info(tm("msgSucessoCancelamento"));
          }
        } else {
          //console.log("Erro: mediaId não infomado.");
          toast.warning(tm("erroCancelamento"));
        }
      } catch (err) {
        //console.log(err);
        toast.warning(tm("erroCancelamento"));
      } finally {
        this.buscarPedido();
        stateLocal.showModalCancelar = false;
      }
    }

    function setPedido() {
      limparDados();
      if (
        store.state.selectedChatQueue &&
        store.state.selectedChatQueue.customVariables["@orderId"]
      ) {
        //console.log("chatId:", store.state.selectedChatQueue.chatId);
        //console.log("chatUUID:", store.state.selectedChatQueue.chatUUID);
        //console.log("customVariables:", store.state.selectedChatQueue.customVariables);
        stateLocal.pedidoBusca =
          store.state.selectedChatQueue.customVariables["@orderId"];
        buscarPedido();
      } else {
        stateLocal.pedidoBusca = "";
        stateLocal.pedidoBuscaCpf = "";
        stateLocal.showConsulta = true;
      }
    }

    function convertDataPedido(data) {
      return moment(data).tz(props.timezoneDefault).format("DD/MM/YYYY HH:mm");
    }

    async function buscarPedido() {
      if (!stateLocal.pedidoBusca && !stateLocal.pedidoBuscaCpf) {
        document.getElementById("pedidoBusca").focus();
        toast.warning(tm("erroPedido"));
        return false;
      }
      try {
        limparDados();
        stateLocal.showConsulta = false;
        let mediaId = "";
        if (
          store.state.selectedChatQueue &&
          store.state.selectedChatQueue.customVariables["MediaId"]
        ) {
          mediaId = store.state.selectedChatQueue.customVariables["MediaId"];
        } else {
          let pluginAtivo = store.state.listConfiguracoes.filter(
            (y) =>
              y.chave == "plugin.marketplace" &&
              y.empresaId == store.state.usuario.empresa.id
          );
          //console.log("chave", pluginAtivo);
          if (pluginAtivo.length > 0 && pluginAtivo[0].valor.trim() != "") {
            mediaId = pluginAtivo[0].valor;
          }
        }
        let cpf = document.getElementById("pedidoBuscaCpf").value;
        //console.log(stateLocal.pedidoBuscaCpf, cpf);
        if (mediaId) {
          if (!store.state.oms.clienteProfile[mediaId]) {
            await store.dispatch("oms/getDadosOmsHub", {
              mediaId: mediaId,
              channelId: 1005,
            });
          }
          //console.log(mediaId);
          //console.log(store.state.oms.clienteProfile);
          //console.log(store.state.oms.clienteProfile[mediaId]);
          if (store.state.oms.clienteProfile[mediaId]) {
            let orderResponse = "";
            if (cpf == "") {
              stateLocal.isLoadingPedidos = true;
              stateLocal.showDetalhe = true;
              const response = await OmsHubService.GetOrderByUid(
                mediaId,
                store.state.oms.clienteProfile[mediaId].userData,
                store.state.oms.clienteProfile[mediaId].channelId,
                {
                  orderUid: stateLocal.pedidoBusca,
                }
              );
              orderResponse = response.data ? response.data : "";
              //console.log("pedido", orderResponse);
              if (orderResponse != "") {
                stateLocal.order = orderResponse;
                stateLocal.numeroPedido = orderResponse.uid;
                stateLocal.cpfCliente = Validar.formatCnpjCpf(
                  orderResponse.invoiceCpfCnpj
                );
                stateLocal.nomeCliente = orderResponse.invoiceName;
                stateLocal.totalPedido = formataMoeda(orderResponse.totalAsDecimal);
                stateLocal.totalGeral = formataMoeda(
                  orderResponse.totalAsDecimal + orderResponse.deliveryFeeAsDecimal
                );
                stateLocal.formaPagamento = orderResponse.payment
                  ? orderResponse.payment.description
                    ? orderResponse.payment.description
                    : orderResponse.payment.name
                  : "";
                stateLocal.frete = formataMoeda(orderResponse.deliveryFeeAsDecimal);
                stateLocal.dataPedido = orderResponse.issuedAt
                  ? moment(orderResponse.issuedAt)
                      .tz(props.timezoneDefault)
                      .format("DD/MM/YYYY HH:mm")
                  : "";
                stateLocal.situacaoPedido = orderResponse.status;
                stateLocal.statusPedido = orderResponse.statusCode;
                stateLocal.itens = await formataItens(orderResponse.products);
                //TODO pegar historico
                stateLocal.historico = [];
                //TODO pegar ocorrencia
                stateLocal.motivo = "";
                stateLocal.dataOcorrencia = "";
                stateLocal.prioridade = "";
              } else {
                toast.warning(tm("pedidoNaoEncontrado"));
              }
            } else {
              stateLocal.isLoadingListaPedidos = true;
              stateLocal.showListaPedido = true;
              const response = await OmsHubService.OrdersByClientProperty(mediaId, {
                key: "cpf",
                value: cpf
                  .replaceAll(".", "")
                  .replaceAll(".", "")
                  .replaceAll(".", "")
                  .replaceAll("-", ""),
                page: 1,
                itemsPerpage: 15,
              });
              stateLocal.listaPedidos =
                response.data && response.data.elements.length > 0
                  ? response.data.elements
                  : [];
              if (stateLocal.listaPedidos == []) {
                toast.warning(tm("pedidoNaoEncontrado"));
              }
              //console.log(stateLocal.listaPedidos);
            }
          } else {
            //console.log("Erro: HubOms - getClienteProfile");
            toast.warning(tm("erroBuscaPedido"));
          }
        } else {
          //console.log("Erro: mediaId não infomado.");
          toast.warning(tm("erroBuscaPedido"));
        }
      } catch (err) {
        //console.log(err);
        toast.warning(tm("erroBuscaPedido"));
      } finally {
        stateLocal.isLoadingListaPedidos = false;
        stateLocal.isLoadingPedidos = false;
      }
    }

    function formataMoeda(value) {
      return Validar.formataMoeda(
        value,
        Validar.getFormatoMoeda(locale.value),
        Validar.getCurrencyMoeda(locale.value)
      );
    }

    async function formataItens(produtos) {
      let itens = [];
      for (let [i, produto] of produtos.entries()) {
        stateLocal.produtoItem = {};
        stateLocal.produtoItem.description = produto.description;
        stateLocal.produtoItem.image = produto.image;
        stateLocal.produtoItem.name = produto.name;
        stateLocal.produtoItem.quantity = produto.quantity;
        stateLocal.produtoItem.discountValueAsDecimal = produto.discountValueAsDecimal;
        stateLocal.produtoItem.valueAsDecimal = produto.valueAsDecimal;
        if (
          produto.valueAsDecimal == 0 &&
          produto.discountValueAsDecimal == 0 &&
          produto.innerProducts &&
          produto.innerProducts.length > 0
        ) {
          await verificaValor(produto.innerProducts);
        }
        itens.push({ ...stateLocal.produtoItem });
      }
      return itens;
    }

    async function verificaValor(sortimento) {
      for (let [i, element] of sortimento.entries()) {
        if (
          element.valueAsDecimal == 0 &&
          element.discountValueAsDecimal == 0 &&
          element.innerProducts &&
          element.innerProducts.length > 0
        ) {
          await verificaValor(element.innerProducts);
        } else {
          stateLocal.produtoItem.discountValueAsDecimal = element.discountValueAsDecimal;
          stateLocal.produtoItem.valueAsDecimal = element.valueAsDecimal;
          stateLocal.produtoItem.quantity = element.quantity;
          stateLocal.produtoItem.description = element.description;
          stateLocal.produtoItem.image = element.image;
          stateLocal.produtoItem.name = element.name;
        }
      }
    }

    function detalheProtocolo(value) {
      //console.log(value);
      stateLocal.showModalHitorico = true;
    }

    async function detalhePedidoLista(orderResponse) {
      try {
        stateLocal.isLoadingPedidos = true;
        stateLocal.showDetalhe = true;
        stateLocal.showListaPedido = false;
        stateLocal.order = orderResponse;
        stateLocal.numeroPedido = orderResponse.uid;
        stateLocal.cpfCliente = Validar.formatCnpjCpf(orderResponse.invoiceCpfCnpj);
        stateLocal.nomeCliente = orderResponse.invoiceName;
        stateLocal.totalPedido = formataMoeda(orderResponse.totalAsDecimal);
        stateLocal.totalGeral = formataMoeda(
          orderResponse.totalAsDecimal + orderResponse.deliveryFeeAsDecimal
        );
        stateLocal.formaPagamento = orderResponse.payment
          ? orderResponse.payment.description
            ? orderResponse.payment.description
            : orderResponse.payment.name
          : "";
        stateLocal.frete = formataMoeda(orderResponse.deliveryFeeAsDecimal);
        stateLocal.dataPedido = orderResponse.issuedAt
          ? moment(orderResponse.issuedAt)
              .tz(props.timezoneDefault)
              .format("DD/MM/YYYY HH:mm")
          : "";
        stateLocal.situacaoPedido = orderResponse.status;
        stateLocal.statusPedido = orderResponse.statusCode;
        stateLocal.itens = await formataItens(orderResponse.products);
        //TODO pegar historico
        stateLocal.historico = [];
        //TODO pegar ocorrencia
        stateLocal.motivo = "";
        stateLocal.dataOcorrencia = "";
        stateLocal.prioridade = "";
      } finally {
        stateLocal.isLoadingPedidos = false;
      }
    }

    //created

    return {
      props,
      store,
      stateLocal,
      cancelar,
      buscarPedido,
      convertDataPedido,
      constants,
      tm,
      setPedido,
      formataMoeda,
      detalheProtocolo,
      detalhePedidoLista,
    };
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

body {
  background-color: #eee;
  font-family: sans-serif;
}
.topico {
  width: 100%;
}

.topico .titulo {
  cursor: pointer;
  position: relative;
  transition: all 0.2s linear;
}

.topico .titulo > svg {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 20px;
  height: 20px;
  transform: translateY(-50%) rotate(0deg);
  transition: all 0.2s linear;
}

.topico .conteudo {
  color: #3c3c3c;
  opacity: 0;
  max-height: 0px;
  transition: all 0.4s ease-out;
}

.topico .conteudo div.card-body-pedido {
  transition: all 0.4s ease-out;
  max-height: 0px;
  overflow-y: auto;
}

.topico.open .titulo > svg {
  transform: translateY(-50%) rotate(180deg);
}

.topico.open .conteudo {
  overflow: hidden;
  opacity: 1;
  max-height: 90vh;
}

.topico.open .conteudo div.card-body-pedido {
  min-height: calc(70vh - 74px);
}

.topico.open .conteudo div.card-footer {
  max-height: 74px;
}

.textarea-custom {
  background-color: #fff !important;
  border-style: none !important;
  padding: 0px !important;
  resize: none !important;
}

.ow {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
</style>
