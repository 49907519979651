<template>
  <div class="secoes">
    <table class="table table-sm">
      <thead class="thead-light">
        <tr class="table-default">
          <th class="col col-10">
            {{ tm("secao") }}
            <div
              v-if="isloadingProdutos"
              class="spinner-border spinner-border-sm"
              role="status"
            ></div>
          </th>
          <th class="col col-2">{{ tm("acoes") }}</th>
        </tr>
      </thead>
    </table>
    <div id="outerDivWrapper">
      <div id="scrollableContent" class="scrolling">
        <table
          class="table table-sm"
          id="tabela-secao"
          v-if="!store.state.ath.isloadingSecoes && existeSecao"
        >
          <template v-for="secao in stateLocal.listaSecao" v-bind:key="secao.idSession">
            <tr v-bind:id="'item-' + secao.idSession">
              <td
                v-if="secao.subSessionStore.length > 0"
                v-bind:class="{ itemAgrupador: secao.subSessionStore.length > 0 }"
                class="item-nivel-1 col-10"
              >
                <i class="fas fa-fw fa-chevron-right"></i>
                {{ secao.sessionName }}
              </td>
              <td
                v-else
                v-bind:class="{ itemAgrupador: secao.subSessionStore.length > 0 }"
                class="item-nivel-1 col-10"
              >
                {{ secao.sessionName }}
              </td>
              <td class="col-2">
                <a
                  v-if="!stateLocal.isEmbedded && fonteVtex"
                  class="py-1"
                  href="#"
                  :title="tm('compartilharCatalogo')"
                  role="button"
                  @click.once="$emit('enviarCatalogo', secao.idSession)"
                >
                  <i class="fas fa-share-square fa-lg"></i> </a
                >&nbsp;
                <a
                  v-if="secao.productQuantity > 0"
                  class="py-1 exibirProdutos"
                  href="#"
                  :title="tm('msgAth17')"
                  role="button"
                >
                  <i class="fas fa-clipboard-list fa-lg"></i>
                </a>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  onUpdated,
  ref,
  computed,
  onRenderTriggered,
  onBeforeUpdate,
} from "vue";
import { useStore } from "vuex";
import ApiBotService from "../../services/ApiBotService";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

const JQuery = require("jquery");
window.$ = JQuery;

export default {
  setup(props, context) {
    const { tm } = useI18n();
    const store = useStore();
    const toast = useToast();
    const isloadingProdutos = ref(false);

    const existeSecao = computed(() => {
      return store.state.ath.existeSecoesLoja[
        store.state.ath.selectedLoja[store.state.selectedChatQueue.chatId]
      ];
    });

    const stateLocal = reactive({
      paginaBusca: 0,
      paginaFinal: 0,
      totalRegistrosBusca: 0,
      restoLista: 0,
      idLoja: store.state.ath.selectedLoja[store.state.selectedChatQueue.chatId],
      listaSecao: [],
      isEmbedded: sessionStorage.getItem("isEmbedded")
        ? JSON.parse(sessionStorage.getItem("isEmbedded"))
        : false,
    });

    onBeforeUpdate(() => {
      carregarJquery();
    });

    setSecoes();

    async function setSecoes() {
      var idLoja = store.state.ath.selectedLoja[store.state.selectedChatQueue.chatId];
      //console.log("idLoja");
      //console.log(idLoja);
      //console.log("idLoja");
      if (idLoja) {
        if (!store.state.ath.secoesLoja[idLoja]) {
          await store.dispatch("ath/getSessoesLoja");
        }
        stateLocal.listaSecao = store.state.ath.secoesLoja[idLoja];
      }
    }

    function carregarJquery() {
      $(document).off("click", ".itemAgrupador");
      $(document).on("click", ".itemAgrupador", function () {
        var imagem = $(this).find("svg");
        var elementoRaiz = $(this).parent();
        var nivelAtual = this.classList[1].split("-")[2];
        if (isElementCollapsed(imagem)) {
          adicionarElementos(elementoRaiz, nivelAtual);
        } else {
          ocultarElementos(elementoRaiz);
        }
        modificarImagem(imagem);
      });

      /*
      $(document).
        .off("click", ".exibirProdutos")
        .on("click", ".exibirProdutos", function () {
          notificarModal($(this));
        });
*/

      if ($(window).width() <= 1200) {
        $(document).ready(function () {
          $(".exibirProdutos").bind("click", function () {
            //do some things
            notificarModal($(this));
            $(this).unbind("click", arguments.callee); //unbind *just this handler*
          });
        });
      } else {
        $(document)
          .off("click", ".exibirProdutos")
          .on("click", ".exibirProdutos", function () {
            notificarModal($(this));
          });
      }

      $(document)
        .off("click", ".compartilharCatalogo")
        .on("click", ".compartilharCatalogo", function () {
          compartilharCatalogo($(this));
        });
    }

    const fonteVtex = computed(() => {
      let pluginAtivo = store.state.listConfiguracoes.filter(
        (y) => y.chave == "fonte.vtex" && y.empresaId == store.state.usuario.empresa.id
      );
      if (pluginAtivo.length > 0 && pluginAtivo[0].valor.trim() == "true") {
        return false;
      } else {
        return true;
      }
    });

    function ocultarElementos(elementoRaiz) {
      var sessaoId = elementoRaiz.attr("id").split("-")[1];
      var elementos = elementoRaiz.parent().find(".descendente-item-" + sessaoId);
      elementos.css("display", "none");
    }

    function adicionarElementos(elementoRaiz, nivelAtual) {
      var sessaoId = elementoRaiz.attr("id").split("-")[1];
      var idLoja = store.state.ath.selectedLoja[store.state.selectedChatQueue.chatId];
      var secao = findById(
        store.state.ath.secoesLoja[idLoja],
        sessaoId,
        "subSessionStore"
      );

      var descendentes = elementoRaiz.parent().find(".descendente-item-" + sessaoId);
      var classList = elementoRaiz.attr("class");
      var classesDescendentes = "";

      if (typeof classList !== "undefined") {
        classesDescendentes = classList.split(/\s+/).toString() + " ";
      }

      //Devemos criar os elementos filhos caso ainda não tenha
      if (descendentes.length == 0) {
        var texto;
        var classeAgrupadora;
        var possuiFilhos;
        var possuiProdutos;

        secao.subSessionStore.sort(function (a, b) {
          if (a.sessionName < b.sessionName) return 1;
          if (a.sessionName > b.sessionName) return -1;
          return 0;
        });

        for (var indice = 0; indice < secao.subSessionStore.length; indice++) {
          classeAgrupadora = "";
          texto = "";
          possuiFilhos = false;
          possuiProdutos = secao.subSessionStore[indice].productQuantity > 0;

          if (secao.subSessionStore[indice].subSessionStore.length > 0) {
            classeAgrupadora = "itemAgrupador";
            possuiFilhos = true;
          }

          texto +=
            `<tr class="` +
            classesDescendentes +
            `descendente-item-` +
            sessaoId +
            `" id="item-` +
            secao.subSessionStore[indice].idSession +
            `">
                                <td class="` +
            classeAgrupadora +
            ` item-nivel-` +
            (parseInt(nivelAtual) + 1) +
            `">` +
            (possuiFilhos ? `<i class="fas fa-chevron-right fa-fw"></i>` : ``) +
            secao.subSessionStore[indice].sessionName +
            `</td>
                                <td style="white-space: nowrap;">`;
          if (!stateLocal.isEmbedded) {
            if (!fonteVtex) {
              texto +=
                `<a class="py-1 compartilharCatalogo" href="#" title="` +
                tm("msgAth18") +
                `" role="button" ">
                                    <i class="fas fa-share-square fa-lg"></i>
                                  </a>`;
            }
          }
          if (possuiProdutos) {
            texto +=
              `<a class="py-1 exibirProdutos" href="#" title="` +
              tm("msgAth17") +
              `" role="button">
                                    <i class="fas fa-clipboard-list fa-lg"></i>
                                </a>`;
          }
          texto += `</td>
                        </tr>`;

          $(texto).insertAfter(elementoRaiz);

          //elementoRaiz.inserAfter( texto );
        }
      } else {
        for (var indice = 0; indice < descendentes.length; indice++) {
          //Caso tenha apenas um ascende pode sempre expandir
          if ($(descendentes[indice]).attr("class").split(/\s+/).length <= 1) {
            $(descendentes[indice]).css("display", "");
          } else {
            var classesElemento = $(descendentes[indice]).attr("class").split(/\s+/);
            var elementoPai = $(
              "#item-" + classesElemento[classesElemento.length - 1].split("-")[2]
            );
            var imagem = elementoPai.find("svg");
            //Caso tenha vários ascendentes só expandira caso já estivesse expandido, ou se o clique tenha sido no ascendente direto
            if (
              imagem.hasClass("fa-chevron-down") ||
              elementoPai.attr("id") == elementoRaiz.attr("id")
            ) {
              $(descendentes[indice]).css("display", "");
            }
          }
        }
      }
    }

    function isElementCollapsed(imagem) {
      return imagem.hasClass("fa-chevron-right");
    }

    function modificarImagem(imagem) {
      if (imagem.hasClass("fa-chevron-down")) {
        imagem.removeClass("fa-chevron-down");
        imagem.addClass("fa-chevron-right");
      } else {
        imagem.removeClass("fa-chevron-right");
        imagem.addClass("fa-chevron-down");
      }
    }

    function findById(arr, id, nestingKey) {
      // if empty array then return
      if (arr.length == 0) return;

      // return element if found else collect all children(or other nestedKey) array and run this function
      return (
        arr.find((d) => d.idSession == id) ||
        findById(
          arr.flatMap((d) => d[nestingKey] || []),
          id
        ) ||
        "Not found"
      );
    }

    async function notificarModal(objeto) {
      isloadingProdutos.value = true;
      var idSession = objeto.parent().parent().attr("id").split("-")[1];
      var nomeSecao = $(objeto.parent().parent().find("td")[0]).text();
      var idLoja = store.state.ath.selectedLoja[store.state.selectedChatQueue.chatId];
      var token = store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].token;
      var idCanalVendas = 2;
      var page = 1;
      var show = 10;
      stateLocal.paginaBusca += 1;
      try {
        const response = await ApiBotService.GetProductsBySessionAndStore(
          token,
          idLoja,
          idSession,
          idCanalVendas,
          page,
          show
        );

        stateLocal.totalRegistrosBusca = response.data.list_Results_Total_Count;
        stateLocal.restoLista = stateLocal.totalRegistrosBusca % 10;
        stateLocal.paginaFinal =
          (stateLocal.totalRegistrosBusca - stateLocal.restoLista) / 10 + 1;

        const listaProduto = response.data.products;

        if (listaProduto.length == 0) {
          toast.warning(tm("msgAth19"));
        } else {
          var sortimento = [...new Set(listaProduto.map((item) => item.productItemName))];

          if (sortimento.length == 1 && sortimento[0] == null) {
            sortimento = null;
          } else {
            sortimento.sort();
          }

          var produtos = [];
          for (var indice = 0; indice < listaProduto.length; indice++) {
            if (listaProduto[indice].quantityProductStock > 0) {
              if (listaProduto[indice].previousValue > listaProduto[indice].productValue)
                produtos[produtos.length] = {
                  idProduto: listaProduto[indice].idProduct,
                  codigoProduto: listaProduto[indice].productCode,
                  descricaoProduto: listaProduto[indice].sessionDescription,
                  nomeProduto: listaProduto[indice].productName,
                  imagemProduto: listaProduto[indice].imageProduct,
                  precoAntigo: listaProduto[indice].previousValue.toLocaleString(
                    "pt-br",
                    {
                      minimumFractionDigits: 2,
                    }
                  ),
                  precoProduto: listaProduto[indice].productValue.toLocaleString(
                    "pt-br",
                    {
                      minimumFractionDigits: 2,
                    }
                  ),
                  sortimento: listaProduto[indice].productItemName,
                  visivel: true,
                  selecionado: false,
                };
              else {
                produtos[produtos.length] = {
                  idProduto: listaProduto[indice].idProduct,
                  codigoProduto: listaProduto[indice].productCode,
                  descricaoProduto: listaProduto[indice].sessionDescription,
                  nomeProduto: listaProduto[indice].productName,
                  imagemProduto: listaProduto[indice].imageProduct,
                  precoProduto: listaProduto[indice].productValue.toLocaleString(
                    "pt-br",
                    {
                      minimumFractionDigits: 2,
                    }
                  ),
                  sortimento: listaProduto[indice].productItemName,
                  visivel: true,
                  selecionado: false,
                };
              }
            }
          }

          var propriedades = {
            nomeSecao: tm("produtosSecao") + " - " + nomeSecao,
            idSession: idSession,
            listaSortimento: sortimento,
            listaProduto: produtos,
            totalRegistrosBusca: stateLocal.totalRegistrosBusca,
            restoLista: stateLocal.restoLista,
            paginaFinal: stateLocal.paginaFinal,
            paginaBusca: 1,
          };

          context.emit("setPropriedadesModalProduto", propriedades);
        }
      } catch (ex) {
        if (ex.status == "404") {
          toast.warning(tm("msgAth19"));
        }
      } finally {
        isloadingProdutos.value = false;
      }
    }

    async function compartilharCatalogo(objeto) {
      var idSession = objeto.parent().parent().attr("id").split("-")[1];
      await context.emit("enviarCatalogo", idSession);
    }

    return {
      stateLocal,
      store,
      notificarModal,
      compartilharCatalogo,
      isloadingProdutos,
      existeSecao,
      tm,
      fonteVtex,
    };
  },
};
</script>

<style>
.item-nivel-2 {
  padding-left: 20px !important;
}
.item-nivel-3 {
  padding-left: 40px !important;
}
.item-nivel-4 {
  padding-left: 60px !important;
}
.item-nivel-5 {
  padding-left: 80px !important;
}
.item-nivel-6 {
  padding-left: 100px !important;
}

.itemAgrupador {
  cursor: pointer;
}

#outerDivWrapper,
#outerDiv {
  height: 75vh;
  margin: 0em;
}

#scrollableContent {
  height: 100%;
  margin: 0em;
  overflow-y: auto;
}

table {
  margin-bottom: 0px !important;
}
</style>
