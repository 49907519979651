import { environment } from "../environments/env";
import Api from "./Api";
import { route } from "./routeDictionary";

export default {

  GetCliente(numero, empresaId) {
    return Api.get(`${environment.apiUrl}${route.GetCliente}`, { params: { numero: numero, empresaId: empresaId } });
  },
  getClienteByName(nome) {
    return Api.get(`${environment.apiUrl}${route.GetClienteByName}`, { params: { nome: nome } });
  },
  getClienteByChannel(empresaId, chatId, channel) {
    return Api.get(`${environment.apiUrl}${route.GetClienteByChannel}`, { params: { empresaId: empresaId, valor: chatId, tipo: channel } });
  },
  GetClienteById(clienteId) {
    return Api.get(`${environment.apiUrl}${route.GetClienteById}`, { params: { id: clienteId } });
  },
  GetClienteByNome(clienteName) {
    return Api.get(`${environment.apiUrl}${route.GetClienteByNome}`, { params: { nome: clienteName } });
  },
  GetClienteByCpf(clienteCPF) {
    return Api.get(`${environment.apiUrl}${route.GetClienteByCpf}`, { params: { cpf: clienteCPF } });
  },
  GetCanaisCliente(clienteId) {
    return Api.get(`${environment.apiUrl}${route.GetCanaisCliente}/${clienteId}`);
  },
  GetEnderecosCliente(clienteId) {
    return Api.get(`${environment.apiUrl}${route.GetEnderecosCliente}/${clienteId}`);
  },
  AddCanalCliente(canal) {
    return Api.post(`${environment.apiUrl}${route.AddCanalCliente}`, canal);
  },
  UpdateCanalCliente(canal) {
    return Api.put(`${environment.apiUrl}${route.UpdateCanalCliente}`, canal);
  },
  RemoveCanalCliente(idCanal) {
    return Api.delete(`${environment.apiUrl}${route.RemoveCanalCliente}`, { params: { id: idCanal } });
  },
  AddCliente(arr) {
    return Api.post(`${environment.apiUrl}${route.AddCliente}`, arr);
  },
  UpdateCliente(arr) {
    return Api.put(`${environment.apiUrl}${route.UpdateCliente}`, arr);
  },
  GetClienteByNomeOrCpf(empresaId, termo) {
    return Api.get(`${environment.apiUrl}${route.GetClienteByNomeOrCpf}`, { params: { empresaId: empresaId, termo: termo } });
  },
  PostAccessToken() {
    return Api.post(`${environment.apiClient}`,  {client_id: process.env.VUE_APP_API_CLIENT_ID, client_secret: process.env.VUE_APP_API_CLIENT_SECRET_URL, refresh_token: process.env.VUE_APP_API_REFRESH_TOKEN_URL, acao: process.env.VUE_APP_API_ACAO_CLIENT_URL} );
  },
  PostConsultarClientePorTelefone(telefone, clientToken) {
    return Api.post(`${environment.apiClient}`, { telefone: telefone, acao: process.env.VUE_APP_API_ACAO_CONSULTA_URL }, { headers: { clientToken: clientToken } } );
  }
}
