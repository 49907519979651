import router from '@/router';
import store from "@/store";
import Axios from "axios";
import { useToast } from "vue-toastification";
import { environment } from "../environments/env";

const locales = {
    en: require("../lang/i18n/en.json"),
    es: require("../lang/i18n/es.json"),
    pt: require("../lang/i18n/pt.json")
};

const DEFAULT_LANG = process.env.VUE_APP_LANG ? process.env.VUE_APP_LANG : "pt";
let msgs = locales.pt;
let lang = (sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : DEFAULT_LANG)
switch (lang) {
    case 'en':
        msgs = locales.en;
    case 'es':
        msgs = locales.es;
    case 'pt':
        msgs = locales.pt;
}

const toast = useToast();
//criando objeto axios
const API = Axios.create({
    baseURL: environment.apiUrl
});

API.defaults.showLoader = true;

//interceptando requsições
API.interceptors.request.use(
    async config => {
        if (config.showLoader) {
            //console.debug("API.interceptors.request");
            //store.dispatch('loader/pending');
        }

        try {
            const token = localStorage.getItem("token");
            if (config.headers.HermesToken) {
                config.headers.Authorization = `Bearer ${config.headers.HermesToken}`;
                delete config.headers.HermesToken;
            } else if (config.headers.botToken) {
                config.headers.Authorization = `Bearer ${config.headers.botToken}`;
                delete config.headers.botToken;
            } else if (config.headers.clientToken) {
                config.headers.Authorization = `Bearer ${config.headers.clientToken}`;
                delete config.headers.botToken;
            } else if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        } catch (error) {
            console.debug(error);
            //store.dispatch('loader/done');
        }
    }, error => {
        if (error.config.showLoader) {
            //console.debug("API.interceptors.request.error");
            //store.dispatch('loader/done');
        }
        return Promise.reject(error);
    });

API.interceptors.response.use(
    response => {
        if (response.config.showLoader) {
            //console.debug("API.interceptors.response");
            //store.dispatch('loader/done');
        }
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        let response = error.response;

        if (response && error.response.status) {
            switch (error.response.status) {
                //case 400:
                //  console.debug("API.interceptors.response.status.400");
                //  break;
                case 401:
                    //console.log("API.interceptors.response.status.401");
                    let apiBot = (error.response.config.url).includes('/bot/') || (error.response.config.url).includes('/zappag');
                    let apiHook = (error.response.config.url).includes('/hook/') || (error.response.config.url).includes('/checkout');
                    let apiHermes = (error.response.config.url).includes('/hermes/');
                    let apiOms = (error.response.config.url).includes('/HubOms/') || (error.response.config.url).includes('/huboms');
                    let isEmbedded = (sessionStorage.getItem('isEmbedded') ? JSON.parse(sessionStorage.getItem('isEmbedded')) : false)
                    if (!apiHermes && !apiBot && !apiHook && !isEmbedded && !apiOms) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("usuario");
                        localStorage.removeItem("sessaoCriada");
                        localStorage.removeItem("datahoraKeepalive");
                        store.dispatch('clearStore');
                        $('.modal').modal('hide');
                        toast.warning(msgs.erro01);
                        router.push("login");
                    }
                    break;
                case 403:
                    {
                        //console.log("API.interceptors.response.status.403");
                        let apiBot = (error.response.config.url).includes('/bot/') || (error.response.config.url).includes('/zappag');
                        let apiHook = (error.response.config.url).includes('/hook/') || (error.response.config.url).includes('/checkout');
                        let apiHermes = (error.response.config.url).includes('/hermes/');
                        let apiOms = (error.response.config.url).includes('/HubOms/') || (error.response.config.url).includes('/huboms');
                        let isEmbedded = (sessionStorage.getItem('isEmbedded') ? JSON.parse(sessionStorage.getItem('isEmbedded')) : false)
                        if (!apiHermes && !apiBot && !apiHook && !isEmbedded && !apiOms) {
                            router.replace("logout");
                        }
                        break;
                    }
                case 409:
                    {
                        //console.log("API.interceptors.response.status.409");
                        let apiBot = (error.response.config.url).includes('/bot/') || (error.response.config.url).includes('/zappag');
                        let apiHook = (error.response.config.url).includes('/hook/') || (error.response.config.url).includes('/checkout');
                        let apiHermes = (error.response.config.url).includes('/hermes/');
                        let apiOms = (error.response.config.url).includes('/HubOms/') || (error.response.config.url).includes('/huboms');
                        let isEmbedded = (sessionStorage.getItem('isEmbedded') ? JSON.parse(sessionStorage.getItem('isEmbedded')) : false)
                        if (!apiHermes && !apiBot && !apiHook && !isEmbedded && !apiOms) {
                            if (localStorage.getItem("token")) {
                                toast.warning(msgs.erro01);
                            }
                            localStorage.removeItem("token");
                            localStorage.removeItem("usuario");
                            localStorage.removeItem("sessaoCriada");
                            localStorage.removeItem("datahoraKeepalive");
                            store.dispatch('clearStore');
                            $('.modal').modal('hide');
                            router.push("login");
                            break;
                        }
                    }
                case 418:
                    {
                        //console.log("API.interceptors.response.status.418");
                        let apiBot = (error.response.config.url).includes('/bot/') || (error.response.config.url).includes('/zappag');
                        let apiHook = (error.response.config.url).includes('/hook/') || (error.response.config.url).includes('/checkout');
                        let apiHermes = (error.response.config.url).includes('/hermes/');
                        let apiOms = (error.response.config.url).includes('/HubOms/') || (error.response.config.url).includes('/huboms');
                        let isEmbedded = (sessionStorage.getItem('isEmbedded') ? JSON.parse(sessionStorage.getItem('isEmbedded')) : false)
                        if (!apiHermes && !apiBot && !apiHook && !isEmbedded && !apiOms) {
                            if (localStorage.getItem("token")) {
                                toast.warning(msgs.erro02);
                            }
                            localStorage.removeItem("token");
                            localStorage.removeItem("usuario");
                            localStorage.removeItem("sessaoCriada");
                            store.dispatch('clearStore')
                            $('.modal').modal('hide');
                            router.push("login");
                            break;
                        }
                    }
                case 500:
                    {
                        //console.log("API.interceptors.response.status.500");
                        let apiBot = (error.response.config.url).includes('/bot/') || (error.response.config.url).includes('/zappag');
                        let apiHook = (error.response.config.url).includes('/hook/') || (error.response.config.url).includes('/checkout');
                        let apiHermes = (error.response.config.url).includes('/hermes/');
                        let apiOms = (error.response.config.url).includes('/HubOms/') || (error.response.config.url).includes('/huboms');
                        if (apiBot) {
                            toast.warning(msgs.erro03);
                        }
                        if (apiHook) {
                            toast.warning(msgs.erro04);
                        }
                        if (apiOms) {
                            toast.warning(msgs.erro14);
                        }
                        if (!apiHermes && !apiBot && !apiHook && !apiOms) {
                            toast.warning(msgs.erro05);
                            // localStorage.removeItem("token");
                            // localStorage.removeItem("usuario");
                            // store.dispatch('clearStore');
                            // $('.modal').modal('hide');
                            // router.push("login");
                        }
                    }
            }
        }

        if (response && response.config.showLoader) {
            //console.debug("API.interceptors.response.error");
            //store.dispatch('loader/done');
        }
        return Promise.reject(error.response);

    });

export default API;
