import Api from "./Api";
import { environment } from "../environments/env";
import { route } from "./routeDictionary";

export default {
  GetCampaignsByUser(usuarioId) {
        return Api.get(`${environment.apiUrl}${route.GetCampaignsByUser}/${usuarioId}`);
    },
  GetCampaignsActive(empresaId) {
      return Api.get(`${environment.apiUrl}${route.GetCampaignsActive}/${empresaId}`);
  },
}
