<template>
<div class="message-resposta mb-2" :style="background ? 'background: '+ background:'background-color: #fff;'">
  <span class="p-1 ml-1" ><b>{{props.from }}</b></span>
  <div  class="p-1 ml-1"  v-if="Validar.isText(props.message)">
    <span  style="position: relative; overflow-wrap: break-word" v-html="props.message">
    </span>
  </div>
  <div  class="p-1 ml-1" v-if="Validar.isImage(props.message)">
    <a  target="_blank" :href="Validar.returnImageURL(props.message)">
      <img :src="Validar.returnImageURL(props.message)"  alt=""
        style="
          height: 50px !important;
          width: 50px !important;
          max-height: 50px !important;
          max-width: 50px !important;
        "/>
    </a>
    <br />
    <span  v-html="Validar.returnImageText(props.message)"></span>
  </div>
  <div class="p-1 ml-1" v-if="Validar.isDocument(props.message)">
    <a  target="_blank" :href="Validar.returnURL(props.message)">
      <img :src="require(`@/assets/images/file.png`)" alt=""/>
    </a>
    <br />
    <span v-html="Validar.returnImageText(props.message)"></span>
  </div>
  <div  class="p-1 ml-1" v-if="Validar.isAudio(props.message) || Validar.isVoice(props.message)">
    <audio  controls="x"   :src="Validar.returnURL(props.message)" style="max-width: 90%">
      Your browser does not support the audio element.
    </audio>
  </div>
  <div  class="p-1 ml-1"  v-if="Validar.isVideo(props.message)">
    <video  controls="x" :src="Validar.returnURL(props.message)" style="max-width: 90%">
      Your browser does not support the video element.
    </video>
  </div>

</div>
</template>

<script>
import Validar from "../util/Validadores";
export default {
  props: {
    message: String,
    from:String,
    background:String
  },

  setup(props) {

   return {
      Validar,
      props,

    }
  }
}
</script>

<style>

.message-resposta {
  height: 100%;
  border-radius:0.30rem ;
  border-left: 3px solid #9CA3AF ;
  /*box-shadow: 0 0 1em #bbbfc7;*/
  color: #8094ae;

}

</style>
