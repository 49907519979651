import { environment } from "../environments/env";
import Api from "./Api";
import { route } from "./routeDictionary";

export default {
  GetEventoListByAgent(id, startDate, endDate) {
    return Api.get(`${environment.apiUrl}${route.GetEventoListByAgent}`, { params: { agentId: id, startDate: startDate, endDate: endDate} });
  },
  GetEventoListByEmpresa(id, startDate, endDate) {
    return Api.get(`${environment.apiUrl}${route.GetEventoListByEmpresa}`, { params: { empresaId: id, startDate: startDate, endDate: endDate} });
  },
  AddEvento(agendaId,arr) {
    return Api.post(`${environment.apiUrl}${route.InsertEvento}`, arr,  { params: { agendaId: agendaId} } );
  },
  UpdateEvento(id, arr) {
    return Api.put(`${environment.apiUrl}${route.UpdateEvento}`, arr, { params: { id: id} });
  },
  DeleteEvento(id) {
    return Api.delete(`${environment.apiUrl}${route.DeleteEvento}`, { params: { id: id } });
  },
  CreateAgenda(idEmpresa, arrAgenda) {
    return Api.post(`${environment.apiUrl}${route.InsertAgenda}`, arrAgenda, { params: { empresaId: idEmpresa } } );
  },
  GetAgendaByEmpresa(idEmpresa){
    return Api.get(`${environment.apiUrl}${route.GetAgendaByEmpresa}`, { params: {empresaId: idEmpresa}})
  },
  GetAgendaByAgent(id){
    return Api.get(`${environment.apiUrl}${route.GetAgendaByAgent}`, { params: {agentId: id}})
  },
  DeleteAgenda(idAgenda){
    return Api.delete(`${environment.apiUrl}${route.DeleteAgenda}`, { params: {id: idAgenda}})
  },
  UpdateAgenda(idAgenda, arrAgenda){
    return Api.put(`${environment.apiUrl}${route.UpdateAgenda}`, arrAgenda, { params: {id: idAgenda}})
  },
  GetAgendaListByService(idServico,idAgente){
    return Api.get(`${environment.apiUrl}${route.GetAgendaListByService}`, { params: {serviceId: idServico , agentId:idAgente }})
  },
  GetServicoByEmpresa(idEmpresa){
    return Api.get(`${environment.apiUrl}${route.GetServicoByEmpresa}`, { params: {empresaId: idEmpresa}})
  },
  GetServiceListByAgenda(idAgenda){
    return Api.get(`${environment.apiUrl}${route.GetServiceListByAgenda}`, { params: {agendaId: idAgenda}})
  },
  GetHorariosDisponiveis(param){
    return Api.get(`${environment.apiUrl}${route.GetHorariosDisponiveis}`, { params: param })
  },
}
