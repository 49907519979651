import { environment } from "../environments/env";
import Api from "./Api";
import { route } from "./routeDictionary";

export default {

  GetTabulacoesByCampanha(campanha) {
    return Api.get(`${environment.apiUrl}${route.GetTabulacoesByCampanha}`, { params: { campanha: campanha } });
  },
  GetTabulacoesByParent(idTabulacao) {
    return Api.get(`${environment.apiUrl}${route.GetTabulacoesByParent}`, { params: { idTabulacao: idTabulacao } });
  },
  GetTabulacoesByCampanhaAndEmpresa(campanha,empresaId,tipo) {
    return Api.get(`${environment.apiUrl}${route.GetTabulacoesByCampanhaAndEmpresa}`, { params: { campanha: campanha, empresaId:empresaId, tipoTabulacaoId: tipo } });
  },
}
