<template>
  <div class="chat-body main-new">
    <div class="chat-header position-relative justify-content-center">
      <section>
        <form id="searchForm">
          <div class="input-group">
            <input
              class="estilo_1"
              type="text"
              @input="onChange"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
              @keydown.enter="onEnter"
              v-model="stateLocal.search"
              v-maska="stateLocal.mascara"
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-primary estilo_1"
                @click="searchrDadosChats()"
              >
                {{ tm("buscar") }}
              </button>
            </div>
            <ul
              id="autocomplete-results"
              v-show="stateLocal.isOpen"
              class="autocomplete-results"
            >
              <li
                v-for="(result, i) in stateLocal.results"
                :key="i"
                @click="setResult(result)"
                class="autocomplete-result"
                :class="{ 'is-active': i === stateLocal.arrowCounter }"
              >
                {{ result.nome  + " " + result.sobrenome}}
              </li>
            </ul>
          </div>
        </form>
        <form id="searchForm">
          <div class="row">
            <div class="col-sm center-block">
              <input
                class="input-busca"
                type="radio"
                v-model="options"
                value="numero"
              />
              <label for="numero">Por Número</label>
            </div>
            <div class="col-sm center-block">
              <input
                class="input-busca"
                type="radio"
                v-model="options"
                value="nome"
              />
              <label for="nome">Por Nome</label>
            </div>
          </div>
        </form>
      </section>
    </div>
    <div class="chat-content scrolling position-relative" id="messageBodyModal">
      <div class="container">
        <div class="d-flex justify-content-center">
          <template v-if="stateLocal.isLoading">
            <transition name="modal">
              <div class="modal-mask">
                <div
                  class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
                >
                  <div class="modal-content">
                    <div class="modal-body">
                      <slot name="body">
                        <div class="d-flex justify-content-center">
                          <div
                            v-if="stateLocal.isLoading"
                            class="spinner-border text-primary spinner-border-sm "
                            role="status"
                          ></div>
                        </div>
                      </slot>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </template>
        </div>
        <!-- Message Day Start -->
        <div class="message-day" id="messageDay" ref="container">
          <!-- Received Message Start -->
          <div
            v-for="message in stateLocal.chatMessages"
            :key="message.internalId"
            :class="message.incoming ? 'chm message' : 'chm message self'"
            :id="message.internalId"
          >
            <div class="message-wrapper">
              <div
                v-if="Validar.isText(message.message)"
                class="message-content"
              >
                <span
                  style="position: relative; overflow-wrap: break-word"
                  v-html="message.message"
                ></span>
              </div>
              <div
                v-if="Validar.isImage(message.message)"
                class="message-content"
              >
                <a
                  target="_blank"
                  :href="Validar.returnImageURL(message.message)"
                >
                  <img
                    :src="Validar.returnImageURL(message.message)"
                    alt=""
                    style="
                      height: 50px !important;
                      width: 50px !important;
                      max-height: 50px !important;
                      max-width: 50px !important;
                    "
                  />
                </a>
                <br /><span
                  v-html="Validar.returnImageText(message.message)"
                ></span>
              </div>
              <div
                v-if="Validar.isDocument(message.message)"
                class="message-content"
              >
                <a target="_blank" :href="Validar.returnURL(message.message)">
                  <img :src="require(`@/assets/images/file.png`)" alt="" />
                </a>
                <br /><span
                  v-html="Validar.returnImageText(message.message)"
                ></span>
              </div>
              <div
                v-if="
                  Validar.isAudio(message.message) ||
                    Validar.isVoice(message.message)
                "
                class="message-content"
              >
                <audio
                  controls="x"
                  :src="Validar.returnURL(message.message)"
                  style="max-width: 90%"
                >
                  Your browser does not support the audio element.
                </audio>
              </div>
              <div
                v-if="Validar.isVideo(message.message)"
                class="message-content"
              >
                <video
                  controls="x"
                  :src="Validar.returnURL(message.message)"
                  style="max-width: 90%"
                >
                  Your browser does not support the video element.
                </video>
              </div>
            </div>
            <div
              v-if="!Validar.isSystem(message.message)"
              class="message-options"
            >
              <div class="avatar avatar-sm">
                <img
                  v-if="message.incoming"
                  alt=""
                  :src="require(`@/assets/images/${message.channel}.png`)"
                />
                <img
                  v-else
                  alt=""
                  :src="require('@/assets/images/icon-user.png')"
                />
              </div>
              <span class="message-date">{{
                moment(message.timestamp)
                  .tz(timezoneDefault)
                  .format("DD/MM/YYYY HH:mm")
              }}</span>
            </div>
          </div>
          <!-- Received Message End -->
        </div>
        <!-- Message Day End -->
      </div>
      <!-- Scroll to finish -->
      <div class="chat-finished" id="chat-finished"></div>
    </div>
    <!-- Chat Footer Start-->
    <!-- <div class="chat-footer"> -->
    <div
      class="chat-footer"
      v-if="stateLocal.diferencaData < 24 && stateLocal.usersActive == 0"
    >
      <div
        class="instant-messages mx-2 p-1"
        v-if="showInstanMessages"
        style="z-index: 10"
      >
        <table class="table m-0">
          <tbody
            class="mainBody scrolling"
            style="overflow: auto; height: 200px; width: 100%"
          >
            <tr
              v-for="sugestaoMsg in instantMessages"
              :key="sugestaoMsg.mensagem"
            >
              <td>
                <a href="#" @click="setMessage(sugestaoMsg.mensagem)">
                  <span>{{ sugestaoMsg.mensagem }}</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="attachment py-2">
        <div class="float-left dropdown border-0 text-center">
          <a
            class="float-left"
            :title="tm('mensagemRapida')"
            href="#"
            role="button"
            data-toggle="modal"
            data-target="#msgRapidaAgendamento"
            @click="clearMsgRapida"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              width="24"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
          </a>
        </div>

        <div class="dropdown border-0" v-if="emojiAvailable">
          <a class="float-left text-warning" title="Emoji" href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              width="24"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </a>
          <div class="dropdown-content rounded emoji-panel">
            <Emoji @emojiAppend="appendEmoji" />
            <!--displayEmojiTab-->
          </div>
        </div>

        <div class="float-left dropdown border-0">
          <a
            :title="tm('enviar') + ' ' + tm('arquivo')"
            class="p-0"
            href="#"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style="height: 1.7rem"
          >
            <svg
              class="hw-20"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
              />
            </svg>
          </a>
          <div class="dropdown-menu">
            <a
              :title="tm('enviar') + ' ' + tm('imagem')"
              class="dropdown-item"
              href="#"
              data-toggle="modal"
              data-target="#enviarImagemAgendamento"
              @click="clearFile"
            >
              <svg
                class="hw-20 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/photograph.svg" alt=""> -->
              <span>{{ tm("imagem") }}</span>
            </a>
            <a
              :title="tm('enviar') + ' ' + tm('audio')"
              class="dropdown-item"
              href="#"
              data-toggle="modal"
              data-target="#enviarAudioAgendamento"
              @click="clearFile"
            >
              <svg
                class="hw-20 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                />
              </svg>
              <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/volume-up.svg" alt=""> -->
              <span>{{ tm("audio") }}</span>
            </a>
            <a
              :title="tm('enviar') + ' ' + tm('documento')"
              class="dropdown-item"
              href="#"
              data-toggle="modal"
              data-target="#enviarDocumentoAgendamento"
              @click="clearFile"
            >
              <svg
                class="hw-20 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                />
              </svg>
              <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/document.svg" alt=""> -->
              <span>{{ tm("documento") }}</span>
            </a>
            <a
              :title="tm('enviar') + ' ' + tm('video')"
              class="dropdown-item"
              href="#"
              data-toggle="modal"
              data-target="#enviarVideoAgendamento"
              @click="clearFile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="hw-20 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/user.svg" alt=""> -->
              <span>{{ tm("video") }}</span>
            </a>
          </div>
        </div>

        <div class="float-left dropdown border-0 text-center">
          <a
            :title="tm('gravarAudio')"
            v-if="!isRecording"
            class="float-left"
            href="#"
            role="button"
            @click="startRecord"
            style="width: 1.6rem"
            v-show="exibirBtnAudio"
          >
            <i class="fas fa-microphone"></i>
          </a>
          <a
            :title="tm('pararGravarAudio')"
            v-if="isRecording"
            class="float-left text-reset"
            href="#"
            role="button"
            @click="stopRecord"
            style="width: 1.6rem"
          >
            <i class="fas fa-stop" style="color: red"></i>
            <small
              v-if="isRecording"
              style="
                      display: block;
                      margin-left: 6px;
                      height: 0px;
                      margin: 0px;
                      font-size: x-small;
                      margin-top: -7px;
                    "
            >
              {{ timeText }}
            </small>
          </a>
        </div>
      </div>

      <div
        v-if="
          selectedChatQueue &&
            selectedChatQueue.channel != constants.TipoCanal.indexOf('EMAIL') &&
            showBotaoRolagem
        "
        @click="scrollToBottomSelected"
        class="
                showBotaoRolagem
                text-center
                px-2
                py-1
                rounded
                position-absolute
              "
        style="
                right: 50%;
                transform: translateX(50%);
                cursor: pointer;
                white-space: nowrap;
                bottom: 7rem;
                background: #fffd;
                border: 1px solid #e5e9f2;
              "
      >
        {{ tm("mensagemConversa01") }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          width="24"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
          />
        </svg>
      </div>

      <textarea
        v-model="stateLocal.message"
        v-on:keyup="exibeInstantMessages"
        v-on:keydown.enter.exact="gerarAtendimento('inputText')"
        class="form-control emojionearea-form-control m-1 scrolling"
        id="messageInput"
        rows="1"
        :placeholder="tm('mensagemEmail01')"
      ></textarea>

      <div
        @click="gerarAtendimento('inputText')"
        class="
                btn btn-primary btn-icon
                send-icon
                rounded-circle
                text-light
                mb-1
              "
        role="button"
      >
        <svg
          class="hw-24"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
        <!-- <img src="./../assets/media/heroicons/outline/arrow-right.svg" alt="" class="injectable"> -->
      </div>
    </div>
    <div class="hsm-icon" role="button">
      <!-- <a
        :title="tm('enviarHsm')"
        class=""
        href="#"
        role="button"
        data-toggle="modal"
        data-target="#envarHsm"
        @click="clearHsm"
      >
        <svg
          class="hw-24"
          xmlns="http://www.w3.org/2000/svg"
          height="40"
          width="40"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          />
        </svg>
      </a> -->
      <button
        :title="tm('enviarHsm')"
        href="#"
        role="button"
        data-toggle="modal"
        data-target="#envarHsm"
        @click="clearHsm"
        type="button"
        class="btn btn-primary estilo_1"
      >
        {{ tm("enviarHsm") }}
      </button>
    </div>
    <!-- Button HSM ---->
    <!-- <div role="button">
      <a
        :title="tm('enviarHsm')"
        class=""
        href="#"
        role="button"
        data-toggle="modal"
        data-target="#envarHsmNovoAtendimento"
        @click="clearHsm"
      >
        <svg
          class="hw-24"
          xmlns="http://www.w3.org/2000/svg"
          height="40"
          width="40"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          />
        </svg>
      </a>
    </div> -->
    <!-- Chat Footer End-->
    <!-- Modal :: Enviar HSM Start-->
    <div
      class="modal fade"
      ref="modalHsmNovoAtendimento"
      id="envarHsmNovoAtendimento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-centered
          modal-dialog-scrollable
          modal-dialog-zoom
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("enviar") + " HSM" }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row form-group">
              <div class="col-12">
                <select
                  class="custom-select font-size-sm shadow-none"
                  v-model="hsmSelectedAgendamento"
                >
                  <option value="">{{ tm("selecioneHsm") }}</option>
                  <option
                    v-for="mensagemHsm in listHSM"
                    :key="mensagemHsm.id"
                    :value="mensagemHsm"
                  >
                    {{ mensagemHsm.titulo }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="hsmSelectedAgendamento" class="row form-group">
              <div class="col-12">
                <span>{{
                  tm("mensagemPadrao") + hsmSelectedAgendamento.mensagem
                }}</span>
              </div>
            </div>
            <div v-if="hsmSelectedAgendamento" class="row">
              <label class="col-5">{{ tm("numeroWhatsapp") }}</label>
              <div class="col-7">
                <input
                  type="number"
                  class="form-control form-control-md"
                  v-model="selectedWhatsApp"
                />
              </div>
            </div>
            <div v-if="selectedParams.length > 0">
              <div
                v-for="param in selectedParams"
                :key="param.chave"
                class="row"
              >
                <label class="col-5">{{ param.chave }}:</label>
                <div class="col-7">
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="param.valor"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              :disabled="!hsmSelectedAgendamento"
              @click="sendHsmNovoAtendimento"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar HSM End-->
    <!-- Modal :: Enviar Imagem Start-->
    <div
      class="modal fade"
      id="enviarImagemAgendamento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-centered
          modal-dialog-scrollable
          modal-dialog-zoom
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("enviar") + " " + tm("imagem") }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div v-if="!fileAnexo">
                  <h6>
                    {{
                      tm("formatosAceitos") +
                        " " +
                        Validar.DEFAULT_PROPS_FILES.allowed_image_types.toString()
                    }}
                  </h6>
                  <h6>{{ tm("limiteArquivo") + " 5Mb" }}</h6>
                  <label for="inputImage" class="btn btn-primary js-btn-step">
                    + {{ tm("arquivo") }}</label
                  >
                  <input
                    id="inputImage"
                    style="display: none"
                    name="inputImage"
                    type="file"
                    @change="onFileChange"
                    accept="image/jpeg,image/jpg,image/png,image/bmp"
                  />
                </div>
                <div v-else>
                  <h6>{{ tm("arquivo") }}: {{ fileSelected.name }}</h6>
                  <img :src="fileAnexo" class="w-100 h-100" />
                  <a :title="tm('remover')" href="#" @click="clearFile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="!fileAnexo"
              @click="gerarAtendimento('inputImage')"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar Imagem End-->
    <!-- Modal :: Enviar Audio Start-->
    <div
      class="modal fade"
      id="enviarAudioAgendamento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-centered
          modal-dialog-scrollable
          modal-dialog-zoom
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("enviar") + " " + tm("audio") }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div v-if="!fileAnexo">
                  <h6>
                    {{
                      tm("formatosAceitos") +
                        " " +
                        Validar.DEFAULT_PROPS_FILES.allowed_audio_types.toString()
                    }}
                  </h6>
                  <h6>{{ tm("limiteArquivo") + " 5Mb" }}</h6>
                  <label for="inputAudio" class="btn btn-primary js-btn-step">
                    + {{ tm("arquivo") }}</label
                  >
                  <input
                    id="inputAudio"
                    style="display: none"
                    name="inputAudio"
                    type="file"
                    @change="onFileChange"
                    accept="audio/mpeg,audio/mp4,audio/aac,audio/ogg,audio/m4a,audio/gsm,audio/x-gsm, .gsm"
                  />
                </div>
                <div v-else>
                  <h6>{{ tm("arquivo") }} : {{ fileSelected.name }}</h6>
                  <audio controls="x" :src="fileAnexo" style="max-width: 90%">
                    Your browser does not support the audio element.
                  </audio>
                  <a :title="tm('remover')" href="#" @click="clearFile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="!fileAnexo"
              @click="gerarAtendimento('inputAudio')"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar Audio End-->
    <!-- Modal :: Enviar Video Start-->
    <div
      class="modal fade"
      id="enviarVideoAgendamento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-centered
          modal-dialog-scrollable
          modal-dialog-zoom
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("enviar") + " " + tm("video") }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div v-if="!fileAnexo">
                  <h6>
                    {{
                      tm("formatosAceitos") +
                        " " +
                        Validar.DEFAULT_PROPS_FILES.allowed_video_types.toString()
                    }}
                  </h6>
                  <h6>{{ tm("limiteArquivo") + " 5Mb" }}</h6>
                  <label for="inputVideo" class="btn btn-primary js-btn-step">
                    + {{ tm("arquivo") }}</label
                  >
                  <input
                    id="inputVideo"
                    style="display: none"
                    name="inputVideo"
                    type="file"
                    @change="onFileChange"
                    accept="video/mp4"
                  />
                </div>
                <div v-else>
                  <h6>{{ tm("arquivo") }}: {{ fileSelected.name }}</h6>
                  <video controls="x" :src="fileAnexo" style="max-width: 90%">
                    Your browser does not support the video element.
                  </video>
                  <a :title="tm('remover')" href="#" @click="clearFile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="!fileAnexo"
              @click="gerarAtendimento('inputVideo')"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar Video End-->
    <!-- Modal :: Enviar Documento Start-->
    <div
      class="modal fade"
      id="enviarDocumentoAgendamento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-centered
          modal-dialog-scrollable
          modal-dialog-zoom
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ tm("enviar") + " " + tm("documento") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div v-if="!fileAnexo">
                  <h6>
                    {{
                      tm("formatosAceitos") +
                        " " +
                        Validar.DEFAULT_PROPS_FILES.allowed_document_types.toString()
                    }}
                  </h6>
                  <h6>{{ tm("limiteArquivo") + " 5Mb" }}</h6>
                  <label
                    for="inputDocument"
                    class="btn btn-primary js-btn-step"
                  >
                    + {{ tm("arquivo") }}</label
                  >
                  <input
                    id="inputDocument"
                    style="display: none"
                    name="inputDocument"
                    type="file"
                    @change="onFileChange"
                    accept="application/pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx, application/vnd.ms-powerpoint, application/zip"
                  />
                </div>
                <div v-else>
                  <h6>Arquivo: {{ fileSelected.name }}</h6>
                  <a :title="tm('remover')" href="#" @click="clearFile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="!fileAnexo"
              @click="gerarAtendimento('inputDocument')"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar Documento End-->
    <!-- Modal :: Mensagem Rápida Start-->
    <div
      class="modal fade"
      ref="modalMsgRapidaAgendamento"
      id="msgRapidaAgendamento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-centered
          modal-dialog-scrollable
          modal-dialog-zoom
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("mensagemRapida") }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row form-group">
              <div class="col-12">
                <select
                  class="custom-select font-size-sm shadow-none"
                  v-model="topicoSelected"
                >
                  <option value="0">{{
                    tm("selecioneTopicoMsgRapida")
                  }}</option>
                  <option
                    v-for="topico in listTopicos"
                    :key="topico.id"
                    :value="topico.id"
                  >
                    {{ topico.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-if="stateLocal.listMsgRapidas.length > 0"
              class="row form-group"
            >
              <div class="col-12">
                <select
                  class="custom-select font-size-sm shadow-none"
                  v-model="msgRapidaSelected"
                >
                  <option value="">{{ tm("selecioneMsgRapida") }}</option>
                  <option
                    v-for="msg in stateLocal.listMsgRapidas"
                    :key="msg.id"
                    :value="msg.mensagem"
                    :title="msg.mensagem"
                  >
                    {{ msg.mensagem.substring(0, 60) + "..." }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="stateLocal.listMsgRapidas.length == 0"
              @click="enviarMsgRapida"
            >
              {{ tm("selecionar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Mensagem Rápida End-->
  </div>
</template>
<script>
import moment from "moment";
import Validar from "@/util/Validadores";
import { reactive, computed, ref, watch } from "@vue/reactivity";
import Emoji from "../../components/Emoji.vue";
import ChatService from "../../services/ChatService";
import HsmService from "../../services/HsmService";
import ClienteService from "../../services/ClienteService";
import HermesApiService from "../../services/HermesApiService";
import NumeroEnterpriseService from "../../services/NumeroEnterpriseService";
import Decipher from "../../util/Decipher";
import ListService from "../../services/ListService";
import UsuarioService from "../../services/UsuarioService";
import differenceInHours from "date-fns/differenceInHours";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "../../store";
import constants from "../../util/constants";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../components/Modal";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

const toast = useToast();
export default {
  name: "SearchChatHistoryMessages",
  props: ["messages"],
  emits: ["close"],
  components: {
    Emoji,
    Modal,
  },
  setup(props, { emit }) {
    const { tm } = useI18n();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const timezoneDefault = ref("");
    let itemsFound = ref(-1);
    let itemFind = ref(0);
    let lastTermo = ref("");
    let lastIndex = ref("");
    const fileSelected = ref("");
    const fileAnexo = ref("");
    const mediaRecorder = ref(null);
    const timeRecording = ref(null);
    const topicoSelected = ref(0);
    const isRecording = ref(false);
    const timeText = ref("");
    const listTopicos = ref([]);
    const listMsgRapidas = ref([]);
    const msgRapidaSelected = ref("");
    const listHSM = ref([]);
    const selectedWhatsApp = ref("");
    const hsmSelectedAgendamento = ref("");
    const selectedParams = ref([]);
    const options = ref("numero");
    const stateLocal = reactive({
      isLoading: false,
      message: "",
      //options: "numero",
      chatMessages: [],
      chatIdClient: {},
      dadosAgendamentoCriado: [],
      selectedChatId: "",
      diferencaData: 25,
      buttonControl: false,
      usersActive: 0,
      dadosQueue: [],
      results: [],
      value: "",
      mascara: "#############",
      search: "",
      isOpen: false,
      arrowCounter: -1,
      listMsgRapidas: [],
    });

    // Functions

    async function onChange() {
      try {
        if (stateLocal.search.length > 2 && options.value == "nome") {
          const responseClient = await ClienteService.GetClienteByNomeOrCpf(
            store.state.usuario.empresaId,
            stateLocal.search
          );
         // const responseClient = await ClienteService.getClienteByName(
         //   stateLocal.search , store.state.usuario.empresaId
         // );
          stateLocal.results = responseClient.data;

          stateLocal.isOpen = true;
          stateLocal.isLoading = false;
        } else if (stateLocal.search.length == 0 && options.value == "nome") {
          stateLocal.chatMessages = [];
          stateLocal.search = "";
          stateLocal.isOpen = false;
          stateLocal.isLoading = false;
          stateLocal.usersActive = 1;
        }
      } catch (e) {}
    }

    async function setResult(value) {
      stateLocal.search = value.nome +  " " + value.sobrenome;
      stateLocal.chatIdClient = {};
      try {
      let res = await  ClienteService.GetCanaisCliente(value.id);
      if (res.data && res.data.length > 0 ) {
        let numero = res.data.find(x => x.tipo == 1 || x.tipo == 9 );
        stateLocal.chatIdClient = numero;
      }
      } catch (e) {}
      stateLocal.isOpen = false;
    }

    function onArrowDown() {
      if (stateLocal.arrowCounter < stateLocal.results.length) {
        stateLocal.arrowCounter = stateLocal.arrowCounter + 1;
      }
    }

    function onArrowUp() {
      if (stateLocal.arrowCounter > 0) {
        stateLocal.arrowCounter = stateLocal.arrowCounter - 1;
      }
    }

    function onEnter() {
      stateLocal.search = stateLocal.results[this.arrowCounter].nome;
      stateLocal.isOpen = false;
      stateLocal.arrowCounter = -1;
    }

    timezoneDefault.value = store.state.timeZone;

    async function searchrDadosChats() {
      stateLocal.message = "";
      stateLocal.isLoading = true;
      let response;
      stateLocal.chatMessages = [];
      if (stateLocal.search == "" || stateLocal.search == null) {
        toast.warning(tm("erroCLiente14"));
        stateLocal.isLoading = false;
      } else if (options.value == "numero") {
        try {
          response = await ChatService.getHistoryByStartDateByEndDate(
            new Date().addDays(-30).toISOString(),
            new Date().toISOString(),
            stateLocal.search,
            store.state.usuario.empresaId
          );
           stateLocal.chatMessages = response.data;
        } catch (e) {
          //console.log(e);
        } finally {
          stateLocal.isLoading = false;
        }
      } else if (options.value == "nome" && stateLocal.chatIdClient) {
        try {
          response = await ChatService.getHistoryByStartDateByEndDate(
            new Date().addDays(-30).toISOString(),
            new Date().toISOString(),
            stateLocal.chatIdClient.valor,
            store.state.usuario.empresaId
          );
          stateLocal.chatMessages = response.data;
        } catch (e) {
        } finally {
          stateLocal.isLoading = false;
        }
      } else {
        stateLocal.isLoading = false;
      }


      if (stateLocal.chatMessages.length == 0) {
        this.stateLocal.diferencaData = 25;
        toast.warning(tm("erroCLiente15"));
        return;
      }

      let lastItemData =
        stateLocal.chatMessages[stateLocal.chatMessages.length - 1].timestamp;

      try {
        let lastMessageEmpresaIdChatId = await ChatService.getCurrentMonthItemsForChatId(
          stateLocal.chatMessages[stateLocal.chatMessages.length - 1].empresaId,
          options.value == "nome"
            ? stateLocal.chatIdClient.valor
            : stateLocal.search
        );
        stateLocal.dadosQueue = lastMessageEmpresaIdChatId.data;
        stateLocal.usersActive = lastMessageEmpresaIdChatId.data.filter(
          (x) => x.active == true
        ).length;
      } catch (e) {
        //console.log("Error", e);
      } finally {
        const modal = document.getElementById("messageBodyModal");
        $("#messageBodyModal").animate(
          { scrollTop: modal.scrollHeight },
          "slow"
        );
      }

      stateLocal.diferencaData = differenceInHours(
        new Date(),
        new Date(lastItemData)
      );
    }

    async function gerarAtendimento(tipo) {
      let myuuid = uuidv4();
      let responseConfigMediaId = [];
      try {
        let campanhaDefault = store.state.listConfiguracoes.filter(
          (x) => x.chave == "atendimento.ativo.campanha.default" && x.empresaId == store.state.usuario.empresa.id
        );
        if (campanhaDefault.length == 0) {
          toast.warning(tm("erroGerarAth03"));
          return;
        }
        let response = await UsuarioService.LoadConfigurationsMediaId(
          campanhaDefault[0].chave,
          campanhaDefault[0].campanhaId
        );

        responseConfigMediaId = response.data;
      } catch (e) {
        //console.log("Error ", e);
      } finally {
        let custom = {
          "@idLoja": store.state.usuario.empresa.idLojaManager
            ? store.state.usuario.empresa.idLojaManager
            : "",
          MediaId: responseConfigMediaId[0].mediaId,
        };
        let param = {
          empresaId:
            stateLocal.chatMessages[stateLocal.chatMessages.length - 1]
              .empresaId,
          flowUUID:
            stateLocal.chatMessages[stateLocal.chatMessages.length - 1].flowId,
          chatUUID: myuuid,
          flowName: "ATH Chat",
          channel:
            stateLocal.chatMessages[stateLocal.chatMessages.length - 1].channel,
          priority: 50,

          GoalPoint: false,
          queueId: responseConfigMediaId[0].nome,
          websocket: null,

          chatId:
            stateLocal.chatMessages[stateLocal.chatMessages.length - 1].chatId,
          agentId: store.state.usuario.id,
          instanceId: null,
        };

        ChatService.GerarAtendimento(custom, param)
          .then((response) => {
            stateLocal.dadosAgendamentoCriado = response.data;

            toast.info(tm("msgGerarAth"));
          })
          .catch((error) => {
            //console.log("Error ", error);
          })
          .finally(() => {
            emit("closeBusca");
            //getChats();

            if (tipo == "inputText") {
              sendMessage();
            } else {
              sendAnexo(tipo);
            }
          });
      }
    }

    function sendHsmNovoAtendimento() {
      if (!selectedWhatsApp.value) {
        toast.warning(tm("erroWhatsapp"));
      } else {
        let params = "";
        for (let key in selectedParams.value) {
          params += selectedParams.value[key].valor + "|";
        }
        if (params != "") {
          params = params.substring(0, params.length - 1);
        }
        HsmService.AddHsm({
          destinatario: selectedWhatsApp.value,
          mensagem: hsmSelectedAgendamento.value.mensagem,
          dataInclusao: moment().format(),
          remetente: store.state.usuario.nome,
          parametro: params,
          mensagemsalvaId: hsmSelectedAgendamento.value.id,
          usuarioId: store.state.usuario.id,
        })
          .then((response) => {
            toast.info(tm("hsmInserido"));
            clearHsm();
          })
          .catch((error) => {
            toast.warning(tm("erroHsm"));
          });

        $(this.$refs.modalHsmNovoAtendimento).modal("toggle");
      }
    }

    const getListHsm = async () => {
      listHSM.value = [];
      try {
        const response = await ListService.getMensagensSalvas(
          store.state.usuario.empresaId
        );
        listHSM.value = response.data;
      } catch (error) {
        //console.log("Error", error);
      }
    };

    function clearHsm() {
      //emit("closeBusca");
      hsmSelectedAgendamento.value = "";
      selectedWhatsApp.value = "";
      selectedParams.value = [];
      emit("closeBusca");
    }

    async function sendMessage(event, email) {
      let chatSelecionado = stateLocal.dadosAgendamentoCriado;
      updateChatStatus(chatSelecionado);
      if (event) {
        event.preventDefault();
      }

      if (stateLocal.message && stateLocal.message.trim() != "") {
        let msg = stateLocal.message.trim();
        //clearMessage();
        if (
          chatSelecionado.channel === constants.TipoCanal.indexOf("WHATSAPP") ||
          chatSelecionado.channel ===
            constants.TipoCanal.indexOf("WHATSAPPBUSINESS")
        ) {
          const resMedia = await NumeroEnterpriseService.GetNumberByMediaId(
            chatSelecionado.customVariables["MediaId"]
          );

          const respToken = await HermesApiService.getToken(
            resMedia.data.url,
            resMedia.data.usuario,
            Decipher.decrypt(resMedia.data.senha)
          );

          let hermesClient = {
            url: resMedia.data.url,
            mediaId: resMedia.data.mediaId,
            usuario: resMedia.data.usuario,
            tokenTimestamp: new Date(),
            token: respToken.data.jwt,
          };
          HermesApiService.sendMessage(
            hermesClient,
            chatSelecionado.chatId,
            msg,
            chatSelecionado.channel,
            hermesClient.mediaId
          )
            .then((response) => {
              return addToChatHistory(
                msg,
                chatSelecionado,
                undefined,
                response
              );
            })
            .catch((e) => {
              toast.warning(tm("erroEnviarMensagem"));
            });
        } else if (
          chatSelecionado.channel === constants.TipoCanal.indexOf("TELEGRAM")
        ) {
          store
            .dispatch("sendMessageTelegram", {
              msg: msg,
              chatId: chatSelecionado,
            })
            .then((r) => {
              return addToChatHistory(msg, chatSelecionado);
            })
            .catch((e) => {
              toast.warning(tm("erroEnviarMensagem"));
            });
        } else if (
          chatSelecionado.channel === constants.TipoCanal.indexOf("WEBCHAT")
        ) {
          let hub = await store.dispatch("getConectionChat", {
            chatQueue: chatSelecionado,
          });
          const response = await store.dispatch("sendMessageChatHubChatId", {
            msg: msg,
            chatQueue: chatSelecionado,
            hub: hub,
          });
          if (!response) {
            toast.warning(tm("erroEnviarMensagem"));
          } else {
            return addToChatHistory(msg, chatSelecionado);
          }
        } else {
          return addToChatHistory(msg, chatSelecionado, email);
        }
      }
    }

    function addToChatHistory(msg, chatQueue, email, resp) {
      if (chatQueue) {
        let messageId =
          resp && resp.data && resp.data.message && resp.data.message.id
            ? resp.data.message.id
            : null;
        let message = {
          chatUUID: chatQueue.chatUUID,
          empresaId: chatQueue.empresaId,
          channel: chatQueue.channel,
          message: msg,
          agentId: chatQueue.agentId,
          chatId: chatQueue.chatId,
          flowId: chatQueue.flowUUID,
          stepFlowId: 0,
          stepName: "Atendimento Humano",
          incoming: false,
          timestamp: moment().format(),
          protocol: chatQueue.protocol,
          messageId: messageId,
        };
        if (email !== undefined) {
          message.email = email;
        }
        return ChatService.AddToChatHistory(message)
          .then((response) => {
            getChatMessages().then(() => {
              scrollToBottom();
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    function searchInMessage() {
      let index = 0;
      let termo = document
        .getElementById("searchForm")
        .elements["searchItem"].value.toLowerCase()
        .trim();

      let divs = document.getElementsByClassName("chm");

      lastTermo = termo;
      let arrFinds = props.messages.filter(
        (x) => x.message.toLowerCase().indexOf(termo) > -1
      );
      itemsFound.value = arrFinds.length;
    }

    function clearMsgRapida() {
      topicoSelected.value = 0;
      msgRapidaSelected.value = "";
    }

    function enviarMsgRapida() {
      stateLocal.message = msgRapidaSelected.value;
    }

    const getTopicosMsgRapidas = async () => {
      listTopicos.value = [];
      if (store.state.usuario.empresaId) {
        try {
          const response = await ListService.getTopicosMensagemRapida(1);

          listTopicos.value = response.data;
        } catch (error) {
          console.error(error);
        }
      }
    };

    // Envio de Arquivos -- Inicio

    function clearFile() {
      fileSelected.value = "";
      fileAnexo.value = "";
    }

    async function sendAnexo(tipo) {
      let chatSelecionado = stateLocal.dadosAgendamentoCriado;
      updateChatStatus(chatSelecionado);
      let msg = "";
      let fileType = "";
      let nomeArquivo = "";
      switch (tipo) {
        case "inputImage":
          msg = "IMAGE|";
          fileType = "image";
          nomeArquivo = fileSelected.value.name;
          break;
        case "inputAudio":
          msg = "AUDIO|";
          fileType = "audio";
          nomeArquivo = fileSelected.value.name;
          break;
        case "inputVideo":
          msg = "VIDEO|";
          fileType = "video";
          nomeArquivo = fileSelected.value.name;
          break;
        case "inputDocument":
          msg = "DOCUMENT|";
          fileType = "document";
          nomeArquivo = fileSelected.value.name;
          break;
        case "inputVoice":
          msg = "VOICE|";
          fileType = "voice";
          nomeArquivo = "source.ogg";
          break;
        default:
          break;
      }
      let splitNome = nomeArquivo.split(".");
      let extension = splitNome[splitNome.length - 1];
      let texto = "";
      for (let i = 0; i < splitNome.length - 1; i++) {
        texto = texto + splitNome[i];
      }
      let path = chatSelecionado.flowUUID + "/" + fileType + "/";
      let base64 = fileAnexo.value.split(";base64,")[1];
      try {
        const data = await ChatService.AddFileChat({
          path: path,
          base64: base64,
          extension: extension,
        });
        msg = msg + data.data.url + "|" + texto;

        const resMedia = await NumeroEnterpriseService.GetNumberByMediaId(
          chatSelecionado.customVariables["MediaId"]
        );

        const respToken = await HermesApiService.getToken(
          resMedia.data.url,
          resMedia.data.usuario,
          Decipher.decrypt(resMedia.data.senha)
        );

        let hermesClient = {
          url: resMedia.data.url,
          mediaId: resMedia.data.mediaId,
          usuario: resMedia.data.usuario,
          tokenTimestamp: new Date(),
          token: respToken.data.jwt,
        };

        if (
          chatSelecionado.channel === constants.TipoCanal.indexOf("WHATSAPP")
        ) {
          HermesApiService.sendFile(
            hermesClient,
            chatSelecionado.chatId,
            msg,
            chatSelecionado.channel,
            hermesClient.mediaId
          )
            .then((response) => {
              return addToChatHistory(
                msg,
                chatSelecionado,
                undefined,
                response
              );
            })
            .catch((e) => {
              toast.warning(tm("erroEnviarArquivo"));
              message.value = msg;
            });
        } else if (
          chatSelecionado.channel ===
          constants.TipoCanal.indexOf("WHATSAPPBUSINESS")
        ) {
          HermesApiService.sendFile(
            null,
            chatSelecionado.chatId,
            msg,
            chatSelecionado.channel,
            hermesClient.mediaId
          )
            .then((response) => {
              return addToChatHistory(
                msg,
                chatSelecionado,
                undefined,
                response
              );
            })
            .catch((e) => {
              toast.warning(tm("erroEnviarArquivo"));
              message.value = msg;
            });
        } else if (
          chatSelecionado.channel === constants.TipoCanal.indexOf("TELEGRAM")
        ) {
          store
            .dispatch("sendMessageTelegram", {
              msg: msg,
              chatId: chatSelecionado,
            })
            .then((r) => {
              return addToChatHistory(msg, chatSelecionado);
            })
            .catch((e) => {
              toast.warning(tm("erroEnviarArquivo"));
            });
        } else if (
          chatSelecionado.channel === constants.TipoCanal.indexOf("WEBCHAT")
        ) {
          let hub = await store.dispatch("getConectionChat", {
            chatQueue: chatSelecionado,
          });

          const resp = await store.dispatch("sendMessageChatHubChatId", {
            msg: msg,
            chatQueue: chatSelecionado,
            hub: hub,
          });

          // const resp = await sendMessageChatHub(msg);
          if (!resp) {
            toast.warning(tm("erroEnviarArquivo"));
          } else {
            addToChatHistory(msg, chatSelecionado);
          }
        } else {
          addToChatHistory(msg, chatSelecionado);
        }
      } catch (err) {
        toast.warning(tm("erroEnviarArquivo"));
      }
    }

    async function updateChatStatus(chatSelecionado) {
      try {
        let response = await ChatService.StatusDates2(
          new Date().addDays(-2).getTime(),
          new Date().getTime(),
          chatSelecionado.empresaId
        );

        let filtro = response.data.filter(
          (x) => x.chatId == chatSelecionado.chatId
        );

        let timelineId =
          filtro[filtro.length - 1].timeline[
            filtro[filtro.length - 1].timeline.length - 1
          ].id;

        let newTimeLine = {
          Id: timelineId + 1,
          Status: "Transferido p/ Humano",
          DataHora: new Date().toISOString(),
        };

        ChatService.UpdateChatStatus(
          filtro[filtro.length - 1].chatUUID,
          false,
          newTimeLine
        ).then((res) => {
          ////console.log("Reposne update", res);
        });
      } catch (e) {
        //console.log("Error ", e);
      }
    }

    function onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      if (!Validar.tamanhoArquivo(files[0])) {
        toast.warning(tm("erroArquivoTamanho") + files[0].name);
        return;
      }
      if (!Validar.tipoArquivosPermitidos(files[0].name, event.target.name)) {
        toast.warning(tm("erroArquivoFormato") + files[0].name);
        return;
      }
      fileSelected.value = files[0];
      createAnexo(files[0], event.target.name);
      toast.info(
        tm("arquivo") + " " + files[0].name + " " + tm("carregado") + "!"
      );
    }

    function createAnexo(file, tipo) {
      switch (tipo) {
        case "inputImage":
          fileAnexo.value = new Image();
          break;
        case "inputAudio":
          fileAnexo.value = new Audio();
          break;
        case "inputVideo":
          fileAnexo.value = document.createElement("video");
          break;
        default:
          break;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        fileAnexo.value = e.target.result;
      };
      reader.readAsDataURL(file);
    }

    function startRecord() {
      let start = new Date();
      mediaRecorder.value.start();
      timeRecording.value = setInterval(function() {
        let sec = parseInt((new Date() - start) / 1000);
        let min = parseInt(sec / 60);
        timeText.value =
          (min < 10 ? "0" : "") +
          min +
          ":" +
          ((sec < 10 ? "0" : "") + (sec - 60 * min));
      }, 1000);
      isRecording.value = !isRecording.value;
    }

    function stopRecord() {
      mediaRecorder.value.stop();
      clearInterval(timeRecording.value);
      isRecording.value = !isRecording.value;
      timeText.value = "00:00";
    }

    function getPngDimensions(base64) {
      const header = atob(base64.slice(22, 70)).slice(16, 24);
      const uint8 = Uint8Array.from(header, (c) => c.charCodeAt(0));
      const dataView = new DataView(uint8.buffer);
      return {
        width: dataView.getInt32(0),
        height: dataView.getInt32(4),
      };
    }

    const splitImage = (img, callback) => () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const printHeight = (img.height * constants.PAGE_WIDTH) / img.width;
      canvas.width = constants.PAGE_WIDTH;
      for (
        let pages = 0;
        printHeight > pages * constants.PAGE_HEIGHT;
        pages++
      ) {
        canvas.height = Math.min(
          constants.PAGE_HEIGHT,
          printHeight - pages * constants.PAGE_HEIGHT
        );
        ctx.drawImage(
          img,
          0,
          -pages * constants.PAGE_HEIGHT,
          canvas.width,
          printHeight
        );
        content.push({
          image: canvas.toDataURL(),
          margin: [0, 5],
          width: constants.PAGE_WIDTH,
        });
      }
      callback();
    };

    function downloadPdf() {
      var docDefinition = {
        content: content,
      };
      try {
        let nome =
          (stateLocal.dadosAgendamentoCriado.contactName
            ? stateLocal.dadosAgendamentoCriado.contactName +
              " (" +
              stateLocal.dadosAgendamentoCriado.chatId +
              ")"
            : sstateLocal.dadosAgendamentoCriado.chatId) +
          " _ " +
          new Date().toLocaleString("pt-br") +
          ".pdf";
        pdfMake.createPdf(docDefinition).download(nome);
        toast.clear();
        toast.success(tm("msgExportar02"));
      } catch (e) {
        toast.error(tm("erroExportar"));
      }
    }

    const exibirBtnAudio = computed(() => {
        if (store.state.usuario) {

            if (findConfiguracao("audio.chat.interagev2", store.state.usuario.empresaId, null)[0] &&
                findConfiguracao("audio.chat.interagev2", store.state.usuario.empresaId, null)[0].valor == "false")
                return false
            else
                return true
        }
        return true
    });

    function findConfiguracao(confName, empresaId, campanhaId) {
        if (!confName)
            return undefined;
        else {
            let reg = store.state.listConfiguracoes.filter(y => y.chave == confName && y.empresaId == undefined && y.campanhaId == undefined && y.padrao);
            let regT = null
            if (empresaId && campanhaId) {
                regT = store.state.listConfiguracoes.filter(y => y.chave == confName && y.empresaId == empresaId && y.campanhaId == campanhaId)
            }
            if (!regT || regT.length == 0) {
                regT = store.state.listConfiguracoes.filter(y => y.chave == confName && y.empresaId == empresaId && y.campanhaId == null)
            }
            if (regT && regT.length > 0)
                reg = regT;
            return reg;
        }
    }

    // Envio de Arquivos -- Fim

    getListHsm();
    getTopicosMsgRapidas();

    return {
      moment,
      Validar,
      timezoneDefault,
      searchInMessage,
      lastTermo,
      lastIndex,
      itemsFound,
      itemFind,
      tm,
      stateLocal,
      selectedParams,
      hsmSelectedAgendamento,
      selectedWhatsApp,
      listHSM,
      searchrDadosChats,
      gerarAtendimento,
      sendMessage,
      fileSelected,
      sendHsmNovoAtendimento,
      fileAnexo,
      onFileChange,
      sendAnexo,
      stopRecord,
      startRecord,
      splitImage,
      downloadPdf,
      getPngDimensions,
      timeText,
      clearFile,
      clearMsgRapida,
      topicoSelected,
      listTopicos,
      ListService,
      enviarMsgRapida,
      listMsgRapidas,
      msgRapidaSelected,
      clearHsm,
      onChange,
      setResult,
      onArrowDown,
      onArrowUp,
      onEnter,
      options,
      exibirBtnAudio,
      findConfiguracao
    };
  },
  watch: {
    topicoSelected: function(newVal, oldVal) {
      if (newVal) {
        this.ListService.getMensagensRapida(newVal)
          .then((response) => {
            this.stateLocal.listMsgRapidas = response.data;
          })
          .catch((error) => {
            console.debug(error);
          });
      }
    },
    options: function(newVal, oldVal) {
      this.stateLocal.search = "";
      this.stateLocal.message = "";
      this.stateLocal.isOpen = false;
      this.stateLocal.isLoading = false;
      this.stateLocal.chatMessages = [];
      this.stateLocal.diferencaData = 25;

      if (newVal == "nome") {
        this.stateLocal.mascara = "X* X* X* X* X*";
      } else {
        this.stateLocal.mascara = "#############";
      }
    },
  },
};
</script>

<style scoped>
.spinner-border-sm {
  align-content: center;
}

.spinner-border-sm {
  width: 4rem;
  height: 4rem;
  border-width: 0.4em;
}
.p-new {
  padding: 0.75rem 1.75rem 0.75rem 1.75rem !important;
}

.main-new {
  height: 70vh !important;
}

.highlight {
  background-color: yellow;
}

.col-sm {
  margin: 5px;
}

.input-busca {
  margin: 5px;
}

.aling-text {
  text-align: center;
}

/* .chat-body .chat-footer .send-icon {
  right: 80px !important;
} */

.modal-content {
  background: transparent !important;
  border: transparent !important;
}

.hsm-icon {
  position: absolute;
  right: 10px;
  top: 20px;
  height: 3rem;
  /*width: 3rem;*/
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .hsm-icon {
    top: 60px !important;
    right: 18px !important;
  }
}

/* Search */

.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  min-height: 1em;
  max-height: 6em;
  overflow: auto;
  width: 73% !important;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}

.estilo_1 {
  border-radius: 7px !important;
}
</style>
