import Api from "./Api";
import constants from '../util/constants';
import { route } from "./routeDictionary";
import { environment } from "../environments/env";

export default {

    async getToken(url, user, pass) {
        url = process.env.VUE_APP_API_HERMES
            //return Api.post(`${url}/users/sign_in`, { username: user, password: pass });
        var r = null
        try {
            r = await Api.post(`${url}/users/sign_in`, { username: user, password: pass });
        } catch (e) {
            return Api.post(`${url}/users/sign_in`, { username: user, password: pass });
        }
        return r
    },

    isTokenExpired(hermesClient) {
        return (new Date().getTime() - hermesClient.tokenTimestamp.getTime()) >= 43200;
    },

    async sendMessage(hermesClient, chatId, msg, canal, mediaId) {
        let msgTratada = msg.replaceAll('<p>', '').replaceAll('</p>', '\n').replaceAll('<br>', '');
        let messageContent = {
            message: {
                type: "text",
                to: "+" + chatId,
                recipient_type: "individual",
                media_id: mediaId,
                text: msgTratada,
                preview_url: false, ///https?\:\/\//g.test(msg) - COLOQUEI FALSE POIS A VALIDAÇÃO NÃO ESTAVA FUNCIONANDO
            }
        }
        if (canal === constants.TipoCanal.indexOf("WHATSAPP")) {
            hermesClient.url = process.env.VUE_APP_API_HERMES;
            //console.log('WHATSAPP:', messageContent)
            //return Api.post(`${hermesClient.url}/messages`, messageContent, { headers: { HermesToken: hermesClient.token } });
            var r = null
            try {
                r = await Api.post(`${hermesClient.url}/messages`, messageContent, { headers: { HermesToken: hermesClient.token } });
            } catch (e) {
                return Api.post(`${hermesClient.url}/messages`, messageContent, { headers: { HermesToken: hermesClient.token } });
            }
            return r
        } else {
            //console.log('WHATSAPPBUSINESS:', messageContent)
            return Api.post(`${environment.apiUrl}${route.SendWhatsAppBusiness}`, messageContent);
        }
    },

    async sendFile(hermesClient, chatId, file, canal, mediaId) {
        let _tipoFolder = this.fetchTypeAndFolder(file);
        let _mime = this.fetchMime(file);

        if (_mime === "SEND_MESSAGE") {
            return this.sendMessage(hermesClient, chatId, file, canal, mediaId);
        } else {
            const fileSplit = file.split('\|');
            let messageContent = {
                message: {
                    type: _tipoFolder.type,
                    content_type: _mime,
                    url: fileSplit[1],
                    to: "+" + chatId,
                    recipient_type: "individual",
                    media_id: mediaId,
                    caption: (_tipoFolder.type !== 'audio' ? (fileSplit.length > 2 ? fileSplit[2] : "") : ''),
                }
            }
            if (canal === constants.TipoCanal.indexOf("WHATSAPP")) {
                hermesClient.url = process.env.VUE_APP_API_HERMES;
                //console.log('WHATSAPP:', messageContent)
                //return Api.post(`${hermesClient.url}/messages`, messageContent, { headers: { HermesToken: hermesClient.token } });
                var r = null
                try {
                    r = await Api.post(`${hermesClient.url}/messages`, messageContent, { headers: { HermesToken: hermesClient.token } });
                } catch (e) {
                    return Api.post(`${hermesClient.url}/messages`, messageContent, { headers: { HermesToken: hermesClient.token } });
                }
                return r
            } else {
                //console.log('WHATSAPPBUSINESS:', messageContent)
                return Api.post(`${environment.apiUrl}${route.SendWhatsAppBusiness}`, messageContent);
            }
        }
    },

    fetchTypeAndFolder(file) {
        let type;
        let folder;

        if (file.includes("IMAGE|")) {
            type = "image";
            folder = "Image";
        } else if (file.includes("AUDIO|")) {
            type = "audio";
            folder = "Audio";
        } else if (file.includes("VOICE|")) {
            type = "audio";
            folder = "Voice";
        } else if (file.includes("VIDEO|")) {
            type = "video";
            folder = "Video";
        } else if (file.includes("DOCUMENT|")) {
            type = "document";
            folder = "Document";
        }
        return { type: type, folder: folder };
    },

    fetchMime(file) {
        let splits = file.split('\|');
        let ext = splits[1].split('.').pop();
        let mime = '';

        if (ext.toUpperCase() === ("JPG") || ext.toUpperCase() === ("JPEG")) {
            mime = "image/jpeg";
        } else if (ext.toUpperCase() === ("PNG")) {
            mime = "image/png";
        } else if (ext.toUpperCase() === ("BMP")) {
            mime = "image/bmp";
        } else if (ext.toUpperCase() === ("AAC")) {
            mime = "audio/aac";
        } else if (ext.toUpperCase() === ("M4A")) {
            mime = "audio/ogg; codecs=opus";
        } else if (ext.toUpperCase() === ("AMR")) {
            mime = "audio/amr";
        } else if (ext.toUpperCase() === ("MP3")) {
            mime = "audio/mp4";
        } else if (ext.toUpperCase() === ("OGG")) {
            mime = "audio/ogg; codecs=opus";
        } else if (ext.toUpperCase() === ("MP4")) {
            mime = "video/mp4";
        } else if (ext.toUpperCase() === ("PDF")) {
            mime = "application/pdf";
        } else if (ext.toUpperCase() === ("DOC") || ext.toUpperCase() === ("DOCX")) {
            mime = "application/msword";
        } else if (ext.toUpperCase() === ("PST") || ext.toUpperCase() === ("PSTX")) {
            mime = "application/vnd.ms-powerpoint";
        } else if (ext.toUpperCase() === ("XLS") || ext.toUpperCase() === ("XLSX")) {
            mime = "application/vnd.ms-excel";
        } else if (ext.toUpperCase() === ("TXT")) {
            mime = "text/plain";
        } else {
            return "SEND_MESSAGE";
        }
        return mime;

    },
}