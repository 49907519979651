import axios from "axios"

const paneas = {
    sendMessage(url, data)
    {
        return axios.post(url, data)
    }
}

export default paneas;
