import { sendMessage } from "@microsoft/signalr/dist/esm/Utils";
import { environment } from "../environments/env";
import Api from "./Api";
import { route } from "./routeDictionary";

export default {
  Autenticate(arr) {
    return Api.post(`${environment.apiUrl}${route.Autenticate}`, arr);
  },
  FinishUserSession(id, idSessao = 0) {
    return Api.post(`${environment.apiUrl}${route.FinishUserSession}`, null, { params: { idUsuario: id, idSessaoUsuario: idSessao } });
  },
  GetAgentesDisponiveis(empresaId, campanha) {
    return Api.get(`${environment.apiUrl}${route.GetAgentesDisponiveis}`, { params: { empresaId: empresaId, campanha: campanha } });
  },
  LoadConfigurations(args) {
    return Api.get(`${environment.apiUrl}${route.LoadConfigurations}`, { params: args });
  },
  UpdateStatus(args) {
    return Api.get(`${environment.apiUrl}${route.UpdateStatus}`, { params: args });
  },
  GetTokenData(token, criarSessao){
    return Api.get(`${environment.apiUrl}${route.GetTokenData}`, { params: { token: token, criarSessao: criarSessao }});
  },
  VerificaToken(id, token) {
    return Api.post(`${environment.apiUrl}${route.VerificaToken}`, { id: id, token: token } );
  },
  ChangePassword(idUsuario, senhaAtual, novaSenha) {
    return Api.put(`${environment.apiUrl}${route.ChangePassword}`, null, { params: { userId: idUsuario, currentPass: senhaAtual, newPass: novaSenha } } );
  },

  LoadConfigurationsMediaId(chave, campanha) {
    return Api.get(`${environment.apiUrl}${route.LoadConfigurationsMediaId}`, { params: {chave: chave, campanha: campanha} });
  },


  UpdateSessaoUsuarioDataHoraKeepAlive(idSessao) {
    return Api.put(`${environment.apiUrl}${route.UpdateSessaoUsuarioDataHoraKeepAlive}`, null, { params: { IdSessaoUsuario: idSessao } } );
  },
  GetUsuarioById(id){
    return Api.get(`${environment.apiUrl}${route.GetUsuarioById}`, { params: { IdUsuario: id }});
  }

}
