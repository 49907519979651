<template>
  <router-view />
  <!--<loader v-if="loading" />-->
</template>

<script>
  import Home from "@/views/Home";
  import { defineComponent } from "vue";
  import Atendimento  from "@/views/Atendimento";
  import Modal from '@/components/Modal'

  export default defineComponent({
    components: {
      Home,
      Atendimento,
      Modal
    }
  });

</script>
