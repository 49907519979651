import OmsHubService from '../../services/OmsHubService';

const state = {
    clienteProfile: []
}
const mutations = {
    setClienteProfile(state, data) {
        state.clienteProfile = data;
    },
    setClienteProfileMedia(state, { mediaId, dados }) {
        state.clienteProfile[mediaId] = dados
    },


}

const actions = {
    async getDadosOmsHub(context, param) {
        try {
            let objJsonStr = JSON.stringify({ deviceId: context.rootState.usuario.email })
            let userUid = Buffer.from(objJsonStr).toString("base64")
            const response = await OmsHubService.ClientProfile(param.mediaId, userUid)
            let dados = { userData: response.data.userData, channelId: param.channelId }
            context.commit("setClienteProfileMedia", { mediaId: param.mediaId, dados: dados });
        } catch (err) {
            //console.log('huboms', err)
        }
    },






}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}