import { environment } from "../environments/env";
import Api from "./Api";
import { route } from "./routeDictionary";

export default {
    getMensagensSalvas(empresaId) {
      return Api.get(`${environment.apiUrl}${route.GetHsmMensagensSalvas}`,{ params: { companyId: empresaId } });
    },
    getMensagensSalvasFilter(usuarioId) {
      return Api.get(`${environment.apiUrl}${route.GetHsmMensagensSalvasFilter}`,{ params: { usuarioId: usuarioId } });
    },
    getListStatus(empresaId) {
      return Api.get(`${environment.apiUrl}${route.GetListStatus}/${empresaId}`);
    },
    getTopicosMensagemRapida(empresaId) {
      return Api.get(`${environment.apiUrl}${route.GetTopicoMensagemRapida}/${empresaId}`);
    },
    getMensagensRapida(topicoId) {
      return Api.get(`${environment.apiUrl}${route.GetMensagemRapida}/${topicoId}`);
    },
    getMensagensRapidaByEmpresa(empresaId) {
      return Api.get(`${environment.apiUrl}${route.GetMensagensRapidaByEmpresa}/${empresaId}`);
    },
}
