import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      auth: true
    },
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: (to, from, next) => {
      if(to.query.email){
        next({
            name: to.name,
            params:{
            email: to.query.email,
            senha: to.query.senha
          }
        })
      }
      else if (!to.query.token) {
        next();
      } else {
        next({
          name: to.name,
          params: {
            userInfo: to.query.token,
            mediaId: to.query.mediaId,
            tema: to.query.tema,
            lang: to.query.lang,
            dark: to.query.dark,
            channel: to.query.channel
          },
        });
      }
    },
    props: true,
    meta: {
      auth: false
    },
    component: () => import("../views/Login/Login.vue")
  },
  {
    path: "/logout",
    name: "Logout",
    meta: {
      auth: false
    },
    component: {
      beforeRouteEnter(to, from, next) {
        Home.methods.logout();
      }
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    redirect: { name: "Home" }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const usuarioLogado = localStorage.getItem("usuario");
  if (to.meta.auth && (!token || !usuarioLogado)) {
    localStorage.removeItem("token");
    localStorage.removeItem("usuario");
    localStorage.removeItem("sessaoCriada");
    localStorage.removeItem("datahoraKeepalive");
    next({ name: "Login" });
  } else {
    next();
  }
});
export default router;
