<template>
  <div class="chip-container rounded scrolling" style="max-height: 6rem !important; overflow-y: auto; ">
    <div class="chip" v-for="(chip, i) of props.chips" :key="chip.label">
      {{chip}}
      <b class="clear-icon my-auto ml-2" @click="deleteChip(i)">x</b>
    </div>
    <input :placeholder="tm('msgEmail02')" v-model="currentInput" @keypress.enter.space="saveChip" @keydown.tab="saveChip" @keydown.delete="backspaceDelete" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" autofocus required />
  </div>
</template>

<script>
import {  ref } from "vue";
import { useToast } from "vue-toastification";
import { useI18n } from 'vue-i18n';

  export default {
    name: "InputChips",
    props: {
      chips: {
        type: Array,
        default: []
      },
      set: {
        type: Boolean,
        default: true
      }
    },
    setup(props) {
      const { tm } = useI18n();
      const toast = useToast();
      const currentInput = ref("");

     function  saveChip($event) {

        if ($event.key === "Tab" && !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(currentInput.value)) {
          currentInput.value = null;
          return;
        }
        if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(currentInput.value)) {
          toast.warning(tm('msgEmail01'));
          return;
        }
        ((props.set && props.chips.indexOf(currentInput.value) === -1) || !set) && props.chips.push(currentInput.value);
        currentInput.value = null;
      }

      function deleteChip(index) {
        props.chips.splice(index, 1);
      }

      function backspaceDelete({ which }) {
        which == 8 && currentInput.value === '' && props.chips.splice(props.chips.length - 1);
      }

    return {
      tm,
      props,
      currentInput,
      saveChip,
      deleteChip,
      backspaceDelete
    }
  }




  };
</script>

<style scoped>

  .chip-container {
    width: 80%;
    border: 1px solid #ccc;
    min-height: 34px;
    display: flex;
    flex-wrap: wrap;
    float: left;
    align-content: space-between;
  }

    .chip-container .chip {
      margin: 4px;
      width: auto;
      background: #e0e0e0;
      padding: 0px 4px;
      border: 1px solid #ccc;
      border-radius: 3px;
      display: flex;
      align-items: center;
    }

      .chip-container .chip b {
        cursor: pointer;
        opacity: 0.8;
        margin-left: 8px;
        display: inline-block;
        background: #555;
        color: white;
        padding: 3px 5px 3px 10px;
        border-radius: 15px;
        margin-right: 5px;
        margin-bottom: 5px;
        line-height: 1.1rem;
        padding: 2px 5px;
      }

    .chip-container input {
      flex: 1 1 auto;
      width: 30px;
      border: none;
      padding: 4px;
      outline: none;
    }
</style>
